import { useCallback } from 'react';
import CulliganMarker, { MarkerProps } from '../CulliganMarker';
import { MarkerInfo } from '../typings';
import { useTranslation } from 'react-i18next';
import { composeClipboardData, copyToClipboard, dispatchToast, getPath } from '@/shared/utils';
import { DeviceTooltipContentProps } from '@typings';
import { Avatar, Box, Button, Chip, darken } from '@mui/material';
import AreaContainer, { AreaBody } from '../../Card/Area';
import CopyChip from '../../Chips/CopyChip';
import TagIcon from '@mui/icons-material/Tag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Caption } from '../../Card/Area/Text';
import { useNavigate } from 'react-router';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';

function isAlarmCounter(alarms: MarkerInfo['extra']['status']['alarms']): alarms is number {
  return typeof alarms === 'number';
}

export default function DeviceMarker({ data, id, isSelected, onClick, setMarkerRef }: MarkerProps<MarkerInfo>) {
  const decodeMarkerInfo = useCallback(
    (marker: MarkerInfo) => ({
      ...marker.position,
    }),
    []
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <CulliganMarker
      data={decodeMarkerInfo(data)}
      id={id}
      isSelected={isSelected}
      key={id}
      onClick={onClick}
      setMarkerRef={setMarkerRef}
      slots={{
        OnClickContent: () => {
          const dataArray: [
            string,
            string | number | undefined | { lat: number; lng: number } | { [key: string]: number }
          ][] = [
            [t('model'), data.extra.model],
            [t('deviceName'), data.extra.name],
            [t('deviceId'), data.extra.serialNumber],
            [t('deviceQnumber'), data.deviceQnumber],
            [t('deviceManufacturingSerialNumber'), data.manufacturingSerialNumber],
            [t('position'), `${data.position.lat}, ${data.position.lng}`],
            [t('accuracy'), data.position.accuracy ? `${data.position.accuracy} (${'meters'})` : undefined],
          ];

          const filteredData = dataArray.filter(([_, value]) => value !== null && value !== undefined && value !== '');

          const toClipboardData = composeClipboardData<DeviceTooltipContentProps>(filteredData);

          return (
            <Box>
              <AreaContainer sx={{ width: '100%', mt: 2 }}>
                <AreaBody gap={2}>
                  {(data.manufacturingSerialNumber || data.deviceQnumber) && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                      {data.manufacturingSerialNumber && (
                        <CopyChip
                          sx={{ width: 'fit-content' }}
                          valueToCopy={`${t('M-SN')}: ${data.manufacturingSerialNumber}`}
                          icon={<TagIcon />}
                          label={`${data.manufacturingSerialNumber}`}
                        />
                      )}
                      {data.deviceQnumber && (
                        <CopyChip
                          sx={{ width: 'fit-content' }}
                          valueToCopy={`${t('deviceQNumber')}: ${data.deviceQnumber}`}
                          icon={<TagIcon />}
                          label={`${data.deviceQnumber}`}
                        />
                      )}
                    </Box>
                  )}

                  <Box>
                    <Caption sx={{ mb: 1 }}>{t('Position')}</Caption>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                      <CopyChip
                        sx={{ width: 'fit-content' }}
                        valueToCopy={`lat: ${data.position.lat}, lng: ${data.position.lng}`}
                        icon={<LocationOnIcon />}
                        label={`${t('lat')} ${data.position.lat}`}
                      />
                      <CopyChip
                        sx={{ width: 'fit-content' }}
                        valueToCopy={`lat: ${data.position.lat}, lng: ${data.position.lng}`}
                        icon={<LocationOnIcon />}
                        label={`${t('lng')} ${data.position.lng}`}
                      />
                      {data.position.accuracy && (
                        <Chip
                          size="small"
                          icon={<ShareLocationIcon />}
                          label={`${t('accuracy')} ${data.position.accuracy} ${t('meters')}`}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', gap: 0.5 }}>
                    <Chip
                      avatar={
                        <Avatar sx={{ bgcolor: 'transparent' }}>
                          <Box
                            sx={{
                              height: '80%',
                              bgcolor: (theme) => darken(theme.palette.error.dark, 0.2),
                              width: '80%',
                              borderRadius: '50%',
                            }}
                          />
                        </Avatar>
                      }
                      label={`${data.extra.status.errors} ${t('errors')}`}
                      size="small"
                      variant="filled"
                      color={'error'}
                      sx={{ width: 'fit-content' }}
                    />
                    {isAlarmCounter(data.extra.status.alarms) && (
                      <Chip
                        avatar={
                          <Avatar sx={{ bgcolor: 'transparent' }}>
                            <Box
                              sx={{
                                height: '80%',
                                bgcolor: (theme) => theme.palette.warning.dark,
                                width: '80%',
                                borderRadius: '50%',
                              }}
                            />
                          </Avatar>
                        }
                        label={`${data.extra.status.alarms} ${t('alarms')}`}
                        size="small"
                        variant="filled"
                        color={'warning'}
                        sx={{ width: 'fit-content' }}
                      />
                    )}
                    {!isAlarmCounter(data.extra.status.alarms) && (
                      <Chip
                        avatar={
                          <Avatar sx={{ bgcolor: 'transparent' }}>
                            <Box
                              sx={{
                                height: '80%',
                                bgcolor: (theme) => theme.palette.warning.dark,
                                width: '80%',
                                borderRadius: '50%',
                              }}
                            />
                          </Avatar>
                        }
                        label={`${data.extra.status.alarms.critical} ${t('critical')}`}
                        size="small"
                        variant="filled"
                        color={'warning'}
                        sx={{ width: 'fit-content' }}
                      />
                    )}
                    {!isAlarmCounter(data.extra.status.alarms) && (
                      <Chip
                        avatar={
                          <Avatar sx={{ bgcolor: 'transparent' }}>
                            <Box
                              sx={{
                                height: '80%',
                                bgcolor: (theme) => theme.palette.caution.dark,
                                width: '80%',
                                borderRadius: '50%',
                              }}
                            />
                          </Avatar>
                        }
                        label={`${data.extra.status.alarms.nonCritical} ${t('non-critical')}`}
                        size="small"
                        variant="filled"
                        color={'caution'}
                        sx={{ width: 'fit-content' }}
                      />
                    )}
                  </Box>
                </AreaBody>
              </AreaContainer>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2, width: '100%' }}>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  endIcon={<ContentCopyIcon />}
                  onClick={(e) => {
                    copyToClipboard({
                      text: toClipboardData,
                      onSucces: () => {
                        dispatchToast({
                          message: t('copyToClipboardSuccess'),
                          severity: 'success',
                          position: { x: e.clientX, y: e.clientY },
                        });
                      },
                      onFail: () => {
                        dispatchToast({
                          message: t('copyToClipboardError'),
                          severity: 'error',
                          position: { x: e.clientX, y: e.clientY },
                        });
                      },
                    });
                  }}
                >
                  {t('copy')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  endIcon={<OpenInNewIcon />}
                  onClick={() => navigate(getPath('FLEET_DEVICE', { deviceId: data.description }))}
                >
                  {t('details')}
                </Button>
              </Box>
            </Box>
          );
        },
      }}
    />
  );
}
