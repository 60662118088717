import Section from '@/components/Shared/Section';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import DevicesList from './DevicesList';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import LoadingAlarm from './LoadingAlarm';
import CircularWithBasePath from '@/components/Shared/ProgressWithLabel/CircularWithBasePath';
import AreaContainer, { AreaBody } from '@/components/Shared/Card/Area';
import { ROUTES } from '@/shared/constants';
import { CulliganCard } from '@/components/Shared/Card';
import { useGetFleetQuery } from '@/redux/api/fleet/fleetApiSlice';
import { fleetGeneralReducer, buildFleetGeneralState } from '../utils';
import { useMemo } from 'react';
import { FiltersContext } from '../FiltersContext';

export const FLEET_GENERAL_QUERY_WIDTH = 1340;

export default function FleetGeneral() {
  const [openDialog, setOpenDialog] = useState(false);
  const filters = useContext(FiltersContext);
  const { data, isLoading, isFetching, isError } = useGetFleetQuery(filters);
  const devices = useMemo(() => data?.data?.devices || [], [data]);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(`(max-width:${FLEET_GENERAL_QUERY_WIDTH}px`);
  const { byIdentities, withoutTelemetry, alerts } = useMemo(
    () => devices.reduce(fleetGeneralReducer, buildFleetGeneralState()),
    [devices]
  );
  const byIdentitiesList = useMemo(() => Array.from(byIdentities.values()), [byIdentities]);
  const totalDevices = devices.length;

  const SafeBody = useMemo(
    () =>
      withErrorLoadingManagement(() => (
        <>
          {[
            {
              number: alerts.priority || 0,
              label: t('devicesHave') + ' ' + t('errors').toLowerCase(),
              color: theme.palette.error.dark,
            },
            {
              number: alerts.postponable || 0,
              label: t('devicesHave') + ' ' + t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment).toLowerCase(),
              color: theme.palette.warning.dark,
            },
            {
              number: alerts.none || 0,
              label: t('devicesWithoutAlarmsOrErrors'),
              color: theme.palette.success.dark,
            },
            {
              number: withoutTelemetry,
              label: t('noTelemetries'),
              color: theme.palette.grey[700],
            },
          ].map((v: { label: string; number: number; color: string }) => {
            return (
              <AreaContainer key={v.label}>
                <AreaBody>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      gap: 2,
                    }}
                  >
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                      <CircularWithBasePath
                        transition={true}
                        key={v.label}
                        size={90}
                        color="inherit"
                        sx={{
                          '& .MuiCircularProgress-circle': {
                            stroke: v.color,
                          },
                        }}
                        circleContainerStyle={{ padding: 0 }}
                        thickness={4}
                        value={(v.number * 100) / totalDevices}
                        variant="determinate"
                        innerLabel={
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                            <Typography
                              variant="h5"
                              component="div"
                              sx={{
                                fontSize: 28,
                                fontWeight: 'bold',
                                color: v.color,
                                letterSpacing: -1.3,
                                lineHeight: '70%',
                                paddingTop: 0.625,
                              }}
                            >
                              {v.number}
                            </Typography>
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{
                                fontSize: 12,
                                color: v.color,
                              }}
                            >
                              of {totalDevices}
                            </Typography>
                          </Box>
                        }
                      />
                    </Box>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        maxWidth: '120px',
                        wordWrap: 'break-word',
                        textAlign: 'left',
                      }}
                    >
                      {v.label}
                    </Typography>
                  </Box>
                </AreaBody>
              </AreaContainer>
            );
          })}
        </>
      )),
    [t, theme, totalDevices, alerts, withoutTelemetry]
  );

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: () => setOpenDialog(false),
    tabs: [
      {
        id: 'numberAndKindOfDevices',
        label: t('numberAndKindOfDevices'),
        body: <DevicesList devices={byIdentitiesList} isDialog={true} handleDialog={setOpenDialog} />,
      },
    ],
    styles: {
      bodyContainer: {
        p: 0,
      },
    },
  });

  return (
    <Section>
      {dialog}
      <Typography variant="h5">{t('fleetGeneral')}</Typography>
      <Box display="grid" gridTemplateColumns={'1.3fr 0.8fr'} gridTemplateRows={'auto auto'} gap={2}>
        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
          <Typography variant="subtitle1" sx={{ lineHeight: 'inherit' }}>
            {t('numberAndKindOfDevices')}
          </Typography>
          <Button variant="text" onClick={() => navigate('/fleet/devices')}>
            {t('seeAll')}
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems={'center'}>
          <Typography variant="subtitle1" sx={{ lineHeight: 'inherit' }}>
            {t('alarmsAndErrors')}
          </Typography>
          <Button variant="text" onClick={() => navigate('/fleet/alarms-errors')}>
            {t('seeAll')}
          </Button>
        </Box>
        <CulliganCard sx={{ flexGrow: 1 }}>
          <DevicesList devices={byIdentitiesList} isDialog={false} handleDialog={setOpenDialog} />
        </CulliganCard>
        <CulliganCard
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: 'auto auto',
            gap: '0.5rem',
            height: '100%',
          }}
          {...(matches ? { height: 'fit-content' } : {})}
        >
          <SafeBody isError={isError} isLoading={isLoading || isFetching} LoadingComponent={<LoadingAlarm />} />
        </CulliganCard>
      </Box>
    </Section>
  );
}
