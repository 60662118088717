import { Box, Theme, Typography, alpha } from '@mui/material';
import TelemetryHeaderCard from './TelemetryHeaderCard';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { BaseDeviceTelemetry, DeviceTelemetryWithString } from '../typings';

export default function TelemetryStringCard({
  telemetry,
  double = false,
  group = false,
  title = '',
}: {
  telemetry: any;
  double?: boolean;
  group?: boolean;
  title?: string;
}) {
  const { t } = useTranslation();
  const _telemetry = telemetry as BaseDeviceTelemetry & DeviceTelemetryWithString;
  const matchExpectedValue = _telemetry?.expectedValue === _telemetry?.value;
  const errorGradient = (theme: Theme) =>
    `radial-gradient(85.17% 85.17% at 0% 0%, ${alpha(theme.palette.error.light, 0.2)} 0%, ${alpha(
      theme.palette.background.grayShades[0],
      0.2
    )} 100%), ${theme.palette.background.grayShades[0]}`;

  return (
    <Box
      className="telemetry-card"
      sx={(theme) => ({
        gridColumn: double ? 'span 2' : 'span 1',
        aspectRatio: !double ? '1/1' : 'unset',
        padding: 2,
        height: '250px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.shape.cardBorderRadius,
        background: !matchExpectedValue ? errorGradient : theme.palette.background.grayShades[0],
      })}
    >
      <TelemetryHeaderCard name={_telemetry.name} id={_telemetry.id} group={group} tagName={title} />
      <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} flexGrow={1}>
        <Box>
          <Typography
            variant="h5"
            fontWeight={'bold'}
            {...(!matchExpectedValue
              ? {
                  sx: {
                    background: (theme) =>
                      `linear-gradient(140deg, ${theme.palette.warning.light} 0%,` +
                      ` ${theme.palette.error.light} 100%);`,
                    backgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  },
                }
              : {})}
          >
            {_telemetry?.value ?? 'N/A'}
          </Typography>
          <Typography variant="caption" fontStyle={'italic'} display={'block'} margin={'10px 0'}>
            {t('lastUpdated')}: {_telemetry?.lastUpdate ? dayjs(_telemetry?.lastUpdate).fromNow() : t('notAvailable')}
          </Typography>
        </Box>

        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: 'white',
            fontSize: '12px',
            borderRadius: theme.shape.cardBorderRadius,
            padding: 1,
          })}
        >
          <Box display={'flex'} flexDirection={'column'}>
            {t('expectedValue')}{' '}
            <Typography fontWeight={'bold'} fontSize="12px" display={'inline'}>
              {_telemetry?.expectedValue}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
