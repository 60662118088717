import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { LocalOffer } from '@mui/icons-material';
import { Box, Divider, SxProps, Theme, Typography, useTheme } from '@mui/material';

export default function DeviceItem({
  numDevices,
  totDevices,
  name,
  model,
  sx,
  ...rest
}: {
  numDevices: number;
  totDevices: number;
  name: string;
  model?: string;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();

  return (
    <Box
      {...rest}
      display="flex"
      gap={theme.spacing(1)}
      alignItems={'center'}
      width={'100%'}
      sx={{
        backgroundColor: theme.palette.background.grayShades[0],
        borderRadius: 1.5,
        pr: theme.spacing(2),
        pl: theme.spacing(1.5),
        py: theme.spacing(1),
        height: '100%',
        ...(sx ? sx : {}),
      }}
    >
      <RenderIf condition={numDevices > 0 && totDevices > 0}>
        <LocalOffer />
        <Divider flexItem orientation="vertical" />
      </RenderIf>
      <Box flexGrow={2}>
        <Box display="flex" alignItems={'baseline'} justifyContent={'space-between'}>
          <Box display="flex" gap={'0.5rem'} alignItems={'baseline'}>
            <RenderIf condition={name != null && name !== ''}>
              <Typography variant="h6" fontWeight={'600'}>
                {numDevices}
              </Typography>
            </RenderIf>
          </Box>
          <RenderIf condition={numDevices > 0 && totDevices > 0}>
            <Typography variant="caption" fontWeight={'800'}>
              {((numDevices * 100) / totDevices).toFixed(2)}
              <Typography variant="caption" fontWeight={'400'}>
                %
              </Typography>
            </Typography>
          </RenderIf>
        </Box>

        <RenderIf condition={name != null && model != null && name !== '' && model !== ''}>
          <Typography variant="body2">{`${name} (${model})`}</Typography>
        </RenderIf>
      </Box>
    </Box>
  );
}
