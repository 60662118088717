import useLoyaltyProgramsColumns from '@/components/OneTools/Channels/LoyaltyPrograms/useLoyaltyProgramsColumns';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import { LoyaltyProgram } from '@culligan-iot/domain/culligan/one/loyalty';
import { MaterialTableProps } from '@material-table/core';
import { t } from 'i18next';

export default function LoyaltyProgramTable({
  items = [],
  isFleet,
  excludeColumns,
  serialNumber,
  ...props
}: { items?: LoyaltyProgram[]; isFleet: boolean; excludeColumns?: string[]; serialNumber: string } & Omit<
  MaterialTableProps<any>,
  'columns' | 'data'
>) {
  const columns = useLoyaltyProgramsColumns(excludeColumns);

  return (
    <GenericExportTable<LoyaltyProgram>
      {...props}
      title={t('loyalty_programs')}
      data={items}
      columns={columns}
      exportData={false}
      selection={false}
    />
  );
}
