import StoreFilters from '@/components/Shared/Filters/StoreFilters';
import { StoreFilterConfig } from '@/components/Shared/Filters/typings';

import { ROUTES } from '@/shared/constants';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FleetAlarm } from '../typings';

import { Box, Paper, Skeleton } from '@mui/material';
import { Column } from '@material-table/core';

import { AlarmsTableFields, useAlarmFilters } from './useAlarmsFilters';
import { useAlarmData } from './useAlarmsData';
import { useAlarmDialog } from './useAlarmsDialog';
import CulliganTable from '@/components/Shared/Tables/CulliganTable';

export default function AlarmsPanel() {
  const { t } = useTranslation();
  const tableRef = useRef();
  const {
    filterConfig,
    handleFiltersApplied,
    handleFiltersCleared,
    getQueryFilters,
    handleOrderChange,
    handlePageChange,
    isLoadingFilters,
  } = useAlarmFilters(tableRef);
  const { handleOpenDialog, dialog, open } = useAlarmDialog(true);
  const { handleFetchTableData, isLoading, isFetching, columns } = useAlarmData(getQueryFilters, handleOpenDialog);
  const { page, size, orderBy, direction } = getQueryFilters();
  const FiltersSkeleton = useMemo(
    () => (
      <Paper sx={{ padding: 2, marginBottom: '12px', display: 'flex', gap: '12px', height: 150, flex: 1 }}>
        {[...Array(filterConfig.length)].map((_, index) => (
          <Skeleton key={index} variant="rounded" width={'100%'} height={50} />
        ))}
      </Paper>
    ),
    [filterConfig.length]
  );
  return (
    <Box>
      {isLoadingFilters ? (
        FiltersSkeleton
      ) : (
        <StoreFilters
          filterConfigs={filterConfig as StoreFilterConfig[]}
          onFiltersApplied={handleFiltersApplied}
          onFiltersCleared={handleFiltersCleared}
        />
      )}

      <CulliganTable
        tableRef={tableRef}
        title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
        data={handleFetchTableData}
        columns={columns as Column<FleetAlarm>[]}
        isLoading={isLoading || isFetching}
        onPageChange={handlePageChange}
        onOrderCollectionChange={(collection) => {
          handleOrderChange(
            columns[collection?.[0]?.orderBy]?.field as AlarmsTableFields,
            collection?.[0]?.orderDirection as 'asc' | 'desc'
          );
        }}
        options={{
          pageSize: size || 10,
          initialPage: page,
          searchDebounceDelay: 400,
          showTitle: false,
          defaultOrderByCollection: [
            { orderBy: columns.findIndex((el) => el.field === orderBy), orderDirection: direction || '', sortOrder: 1 },
          ],
        }}
      />

      {open && dialog}
    </Box>
  );
}
