import { ApiFailureResult, Either, _ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import {
  CommonIsEntityUniqueBase,
  eitherCommonIsEntityUnique,
  IsEntityUniqueApiResult,
} from '@/components/OneTools/typings';
import { Culligan, Entity } from '@culligan-iot/domain';
import { Schema as S } from '@effect/schema';

const URL_PREFIX = `${API_VERSION.v2}/system/loyalty-programs`;

const StringifiedDates = S.Struct({
  createdAt: S.String,
  updatedAt: S.String,
});

export const LoyaltyProgramWithStringifiedDates = Culligan.One.Loyalty.LoyaltyProgram.pipe(
  S.omit('createdAt', 'updatedAt'),
  S.extend(StringifiedDates)
);

export const LoyaltyProgramWithoutDates = Entity.newEntity(Culligan.One.Loyalty.LoyaltyProgram).annotations({
  identifier: 'LoyaltyProgramWithoutDates',
});

const _LoyaltyProgramApiResponse = () => {
  return S.Union(
    ApiFailureResult,
    S.Struct({
      success: S.Literal(true),
      data: LoyaltyProgramWithStringifiedDates,
    })
  );
};

const GetLoyaltyProgramsApiResultData = S.Struct({
  items: S.Array(LoyaltyProgramWithStringifiedDates),
}).annotations({
  identifier: 'GetLoyaltyProgramsApiResultData',
});

const GetLoyaltyProgramsApiResult = _ApiResult(GetLoyaltyProgramsApiResultData).annotations({
  identifier: 'GetLoyaltyProgramsApiResult',
});

const decodeLoyaltyProgramApiResponse = S.decodeUnknownEither(_LoyaltyProgramApiResponse(), { errors: 'all' });
const decodeLoyaltyProgramsApiResponse = S.decodeUnknownEither(GetLoyaltyProgramsApiResult, { errors: 'all' });

function eitherLoyaltyProgram(decodeType: Function, response: unknown) {
  const loyaltyProgram = decodeType(response);
  if (loyaltyProgram && loyaltyProgram._tag === 'Left') {
    return {
      _tag: loyaltyProgram._tag,
      left: loyaltyProgram.left,
    };
  }
  return {
    _tag: loyaltyProgram._tag,
    right: loyaltyProgram.right,
  };
}

export const LoyaltyProgramsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['LoyaltyPrograms'] }).injectEndpoints({
  endpoints: (builder) => ({
    getLoyaltyPrograms: builder.query<Either<S.Schema.Type<typeof GetLoyaltyProgramsApiResult>>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['LoyaltyPrograms'],
      transformResponse: (response) => {
        return eitherLoyaltyProgram(decodeLoyaltyProgramsApiResponse, response);
      },
    }),
    getLoyaltyProgram: builder.query<Either<S.Schema.Type<ReturnType<typeof _LoyaltyProgramApiResponse>>>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['LoyaltyPrograms'],
      transformResponse: (response) => {
        return eitherLoyaltyProgram(decodeLoyaltyProgramApiResponse, response);
      },
    }),
    isLoyaltyProgramUnique: builder.query<
      Either<S.Schema.Type<typeof IsEntityUniqueApiResult>>,
      CommonIsEntityUniqueBase
    >({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
      transformResponse: (response) => {
        return eitherCommonIsEntityUnique(response);
      },
    }),
  }),
});

export const {
  useLazyGetLoyaltyProgramsQuery,
  useGetLoyaltyProgramsQuery,
  useLazyGetLoyaltyProgramQuery,
  useLazyIsLoyaltyProgramUniqueQuery,
} = LoyaltyProgramsApiSlice;
