import { useGetConsumablesQuery } from '@/redux/api/system/consumablesApiSlice';
import { useTranslation } from 'react-i18next';
import { ConsumableRes } from './typings';
import useConsumablesColumns from './useConsumablesColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ROUTES } from '@/shared/constants';

export const ConsumablesTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ConsumableRes>));

export default function ConsumablesPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetConsumablesQuery();
  const columns = useConsumablesColumns();

  return (
    <ConsumablesTable
      title={t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
      exportData={true}
      selection={true}
    />
  );
}
