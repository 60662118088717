import { Box, BoxProps, CircularProgress, Tab, Tabs, useTheme } from '@mui/material';
import { TabsProps } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import TabPanel from './TabPanel';
import { TabConfig, TabsStructure } from './typings';

function BaseTabsLayout({
  config,
  orientation = 'horizontal',
  defaultIndex,
  handleTabChange,
  slots,
  ...props
}: TabsProps & {
  config: TabConfig[];
  defaultIndex?: number;
  handleTabChange?: (nextIndex: number) => void;
  slots?: {
    action?: () => React.JSX.Element;
  };
} & BoxProps) {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(defaultIndex || 0);
  useEffect(() => setSelectedIndex(defaultIndex || 0), [defaultIndex]);

  const handleOnTabClick = (_: SyntheticEvent, nextIndex: any) => {
    setSelectedIndex(nextIndex);
    if (handleTabChange) {
      handleTabChange(nextIndex);
    }
  };

  const { Tabs: TabsContent, Panels } = config.reduce<TabsStructure>(
    (cfg: TabsStructure, tab: TabConfig, index: number) => {
      cfg.Tabs.push(
        <Tab
          key={`${tab.id}Tab`}
          label={
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
              {tab.isLoading && <CircularProgress size={12} thickness={6} color="inherit" />}
              {tab.label}
            </Box>
          }
          id={`${tab.id}Tab`}
          {...(tab.ariaControls
            ? {
                'aria-controls': tab.id,
                sx: { paddingX: '2rem' },
              }
            : {})}
          sx={{
            display: 'flex',
          }}
        />
      );

      cfg.Panels.push(
        <TabPanel key={`${tab.id}Panel`} value={selectedIndex} index={index} {...props} orientation={orientation}>
          {tab.useHash && typeof tab.ContentComponent === 'function' && tab.ContentComponent({})}
          {tab.useHash && typeof tab.ContentComponent !== 'function' && tab.ContentComponent}
        </TabPanel>
      );

      return cfg;
    },
    { Tabs: [], Panels: [] }
  );

  const theme = useTheme();

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tabs
          value={selectedIndex}
          onChange={handleOnTabClick}
          sx={{
            '& .MuiTabs-flexContainer': { gap: orientation === 'horizontal' ? '2rem' : '0', overflowX: 'auto' },
            '& .MuiButtonBase-root': { alignItems: 'flex-start', outline: 'none' },
            '&.MuiTabs-vertical .MuiButtonBase-root.Mui-selected': {
              backgroundColor: theme.palette.background.grayShades[2],
            },
            '&.MuiTabs-vertical': {
              backgroundColor: theme.palette.background.grayShades[0],
              py: 2,
            },
            '&.MuiTabs-vertical .MuiTabs-scroller': { display: 'flex', alignContent: 'center', flexWrap: 'wrap' },
            '@media print': { display: 'none' },
            justifyContent: 'stretch',
          }}
          orientation={orientation}
        >
          {TabsContent}
        </Tabs>
        {slots && slots?.action && slots.action()}
      </Box>

      {Panels}
    </>
  );
}

export default BaseTabsLayout;
