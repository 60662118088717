import {
  AutoAwesome as AutoAwesomeIcon,
  PhotoFilter as PhotoFilterIcon,
  GasMeter as GasMeterIcon,
  Science as ScienceIcon,
  Flare as FlareIcon,
  Sanitizer as SanitizerIcon,
  Animation as AnimationIcon,
  Co2 as Co2Icon,
  Opacity as OpacityIcon,
} from '@mui/icons-material';
import { ConsumableKind } from '../../DevicesPanel/typings';

export const ACTION_STATUS = {
  Uninitialized: 'uninitialized',
  Loading: 'loading',
  Error: 'error',
  Success: 'success',
} as const;

export const consumableKindIcons = {
  chemicals: <ScienceIcon />,
  CO2: <Co2Icon />,
  enhancements: <AutoAwesomeIcon />,
  filter: <PhotoFilterIcon />,
  membranes: <AnimationIcon />,
  ozone: <GasMeterIcon />,
  sanitizationKit: <SanitizerIcon />,
  uvcLight: <FlareIcon />,
  waterBottles: <OpacityIcon />,
} satisfies { [Key in ConsumableKind]: JSX.Element };
