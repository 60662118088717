import { Config, LiteralConfigFragment } from '@/components/Fleet/Devices/DevicesPanel/typings';
import { ParsedConfig, ParsedFlattenedNodeFragment, ParsedLiteralFragment, ParsedNodeFragment } from './typings';

export function isLiteralConfigFragment(
  fragment: LiteralConfigFragment | Record<string, LiteralConfigFragment>
): fragment is LiteralConfigFragment {
  return typeof fragment === 'string';
}

export function isParsedLiteralFragment(
  fragment: ParsedLiteralFragment | ParsedNodeFragment | ParsedFlattenedNodeFragment
): fragment is ParsedLiteralFragment {
  return !!(fragment as ParsedLiteralFragment).value;
}

export function handleCurrent(
  fragment: ParsedLiteralFragment | ParsedNodeFragment,
  ...current: string[]
): ParsedLiteralFragment | ParsedNodeFragment {
  if (isParsedLiteralFragment(fragment)) {
    return {
      ...fragment,
      current: fragment.id === current[0],
    };
  }

  const { id, items } = fragment;

  return {
    id,
    current: current.some((c) => c === id),
    items: items.map((fragment) => handleCurrent(fragment, ...(current.filter((c) => c === fragment.id) ?? []))),
  };
}

export function parseConfig(data: Config, path?: string): ParsedConfig {
  return Object.keys(data).reduce<ParsedConfig>((parsed, key) => {
    const fragment = data[key];
    const _path = `${path ?? ''}/${key}`;
    if (isLiteralConfigFragment(fragment)) {
      return [
        ...parsed,
        {
          id: _path + `/${fragment}`,
          value: fragment,
          current: false,
        },
      ];
    }

    return [
      ...parsed,
      {
        id: _path,
        items: parseConfig(fragment, _path),
        current: false,
      },
    ];
  }, []);
}
