import { NotEmptyList } from '@typings';
import { AccuracyLatLngLiteral, UnknownData } from './typings';

export function isAccuracyLatLngLiteral(data: unknown): data is AccuracyLatLngLiteral {
  const _data = data as AccuracyLatLngLiteral;
  return (
    typeof _data?.lat === 'number' &&
    typeof _data?.lng === 'number' &&
    (typeof _data?.accuracy === 'number' || _data?.accuracy === undefined)
  );
}

export function isUnknownData(data: unknown): data is UnknownData {
  return typeof data === 'object' && !Array.isArray(data) && data !== null;
}

function getBoundFromLatLngLiteral({ lat, lng }: google.maps.LatLngLiteral) {
  return new google.maps.LatLngBounds().extend(new google.maps.LatLng(lat, lng));
}

function toLiteralBound(bound: google.maps.LatLngBounds) {
  const { lng: east, lat: north } = bound.getNorthEast();
  const { lng: west, lat: south } = bound.getSouthWest();

  return {
    east: east(),
    north: north(),
    south: south(),
    west: west(),
  } satisfies google.maps.LatLngBoundsLiteral;
}

export function getBound(toBound: AccuracyLatLngLiteral | NotEmptyList<AccuracyLatLngLiteral>) {
  if (isAccuracyLatLngLiteral(toBound)) {
    const literalBound = toLiteralBound(getBoundFromLatLngLiteral(toBound));
    return literalBound;
  }

  return toLiteralBound(
    toBound.reduce<google.maps.LatLngBounds>(
      (acc, { lat, lng }) => acc.extend(new google.maps.LatLng(lat, lng)),
      new google.maps.LatLngBounds()
    )
  );
}
