import { createContext } from 'react';
import { TelemetryFiltersPayload, DateChangeAction } from '../useTelemetryFilters/actions';

type TelemetryFiltersHandlers = {
  handleAddTelemetryFilter: (t: TelemetryFiltersPayload) => void;
  handleRemoveTelemetryFilter: (t: TelemetryFiltersPayload) => void;
  handleChangeDateRange: (dt: DateChangeAction['payload']) => void;
};

export const TelemetryFiltersHandlerContext = createContext<TelemetryFiltersHandlers>({} as TelemetryFiltersHandlers);
