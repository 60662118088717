import { AppState } from '@/redux/store';
import { Box, BoxProps, FormControlLabel, styled, Switch, Tab, Tabs, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useState } from 'react';
import { Operation, operationsUnreadSelector, Tag, TAGS } from '@/redux/slices/operationSlice';
import TabPanel from '@/components/Shared/TabsLayout/TabPanel';
import List from './List';
import { ROUTES } from '@/shared/constants';

interface StyledTabProps {
  label: string;
  icon?: JSX.Element;
}

const NotificationTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} icon={props.icon} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontSize: '0.625rem',
    height: 'fit-content',
    minHeight: 'unset',
    padding: 0,
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    '&:focus': { outline: 'none' },
    '&.Mui-selected': {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.primary,
    },
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiTab-iconWrapper': {
      marginBottom: 0,
    },
  })
);
const NotificationCounter = styled((props: BoxProps) => <Box {...props} />)(({ theme }) => ({
  display: 'inline-block',
  width: 'fit-content',
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: '0.3125rem',
  marginBottom: 0,
  padding: '3px',
  backgroundColor: theme.palette.background.grayShades[0],
  borderRadius: '10px',
  lineHeight: '100%',
}));

const unreadPredicate = (operation: Operation) => !operation.read;
const devicesPredicate = (operation: Operation) =>
  (
    [
      TAGS.DEVICE_COMMAND_SEND,
      TAGS.DEVICE_CONSUMABLE_DEPLETE,
      TAGS.DEVICE_CONSUMABLE_REMOVE,
      TAGS.DEVICE_CONSUMABLE_SET,
      TAGS.DEVICE_TELEMETRY_UPDATE,
    ] as Tag[]
  ).includes(operation.tag);
const OTAPredicate = (operation: Operation) => operation.tag === TAGS.OTA_UPDATE;
const OneToolPredicate = (operation: Operation) =>
  ([TAGS.ONE_TOOL_CREATE, TAGS.ONE_TOOL_DELETE, TAGS.ONE_TOOL_UPDATE] as Tag[]).includes(operation.tag);

const countUnreadDevices = (operations: Operation[]) =>
  operations.filter((o) => unreadPredicate(o) && devicesPredicate(o)).length;
const countUnreadOTA = (operations: Operation[]) =>
  operations.filter((o) => unreadPredicate(o) && OTAPredicate(o)).length;
const countUnreadOneTool = (operations: Operation[]) =>
  operations.filter((o) => unreadPredicate(o) && OneToolPredicate(o)).length;

export default function NotificationsManager() {
  const [onlyUnread, setOnlyUnread] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const operations = useSelector((state: AppState) => operationsUnreadSelector(state, onlyUnread));

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplate: 'auto 1fr / auto',
        height: 'fit-content',
        maxHeight: 'calc(100dvh - 148px)',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
        <Typography fontWeight="600" fontSize="0.875rem">
          {t('notifications')}
        </Typography>
        <Box>
          <FormControlLabel
            control={
              <Switch
                id="showOnlyUnread"
                checked={onlyUnread}
                onChange={(e, checked) => {
                  setOnlyUnread(checked);
                  // listRef.current?.resetAfterIndex(0);
                }}
                name={t('showOnlyUnread') as string}
              />
            }
            labelPlacement="start"
            label={<Typography sx={{ fontSize: '0.625rem' }}>{t('showOnlyUnread')}</Typography>}
            sx={{
              gap: 0.5,
              fontSize: '0.5rem',
            }}
          />
        </Box>
      </Box>
      <Box>
        <Tabs
          value={activeTab}
          onChange={(e, value) => setActiveTab(value)}
          sx={{
            position: 'relative',
            minHeight: 'unset',
            mb: '0.25rem',
            '&::after': {
              content: '""',
              position: 'absolute',
              display: 'block',
              left: 0,
              bottom: 0,
              width: '100%',
              height: '2px',
              background: (theme) => theme.palette.background.grayShades[0],
              zIndex: 0,
            },
            '& .MuiTabs-flexContainer': { gap: '0.625rem', alignItems: 'center' },
            '& .MuiTabs-indicator': {
              zIndex: 10,
              backgroundColor: (theme) => theme.palette.background.grayShades[2],
            },
          }}
        >
          <NotificationTab label={t('history')} />
          <NotificationTab
            label={t('devices')}
            {...(countUnreadDevices(operations) > 0
              ? {
                  icon: <NotificationCounter>{countUnreadDevices(operations)}</NotificationCounter>,
                }
              : {})}
          />
          <NotificationTab
            label={t('OTA')}
            {...(countUnreadOTA(operations) > 0
              ? {
                  icon: <NotificationCounter>{countUnreadOTA(operations)}</NotificationCounter>,
                }
              : {})}
          />
          <NotificationTab
            label={t(ROUTES.ONETOOL.fragment)}
            {...(countUnreadOneTool(operations) > 0
              ? {
                  icon: <NotificationCounter>{countUnreadOneTool(operations)}</NotificationCounter>,
                }
              : {})}
          />
        </Tabs>
      </Box>
      <Box>
        <TabPanel index={0} value={activeTab}>
          <List operations={operations.filter((operation) => !onlyUnread || unreadPredicate(operation))} />
        </TabPanel>
        <TabPanel index={1} value={activeTab}>
          <List
            operations={operations.filter(
              (operation) => (!onlyUnread || unreadPredicate(operation)) && devicesPredicate(operation)
            )}
          />
        </TabPanel>
        <TabPanel index={2} value={activeTab}>
          <List
            operations={operations.filter(
              (operation) => (!onlyUnread || unreadPredicate(operation)) && OTAPredicate(operation)
            )}
          />
        </TabPanel>
        <TabPanel index={3} value={activeTab}>
          <List
            operations={operations.filter(
              (operation) => (!onlyUnread || unreadPredicate(operation)) && OneToolPredicate(operation)
            )}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
