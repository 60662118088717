import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Body, Subtitle } from '@/components/Shared/Card/Area/Text';
import { alpha, Box, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SmartStringifiedConfiguration from './SmartStringifiedConfiguration';
import { Config } from '@/components/Fleet/Devices/DevicesPanel/typings';
import { isParsedLiteralFragment, parseConfig } from './utils';
import { Fragment, useMemo, useState } from 'react';
import { ParsedConfig, ParsedFlattenedNodeFragment, ParsedLiteralFragment } from './typings';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import ConfigContext from './ConfigContext';

type FlattenedParsedConfig = Array<ParsedFlattenedNodeFragment | ParsedLiteralFragment>;

const flatConfig = (config: ParsedConfig): FlattenedParsedConfig => {
  return config.reduce<FlattenedParsedConfig>((acc, fragment) => {
    if (isParsedLiteralFragment(fragment)) {
      return [
        ...acc,
        {
          ...fragment,
          label: fragment.id,
        },
      ];
    }

    const { id, current } = fragment;

    return [
      ...acc,
      {
        id,
        current,
        label: fragment.id,
      },
      ...flatConfig(fragment.items),
    ];
  }, []);
};

function Configuration({ sx, data, model }: { sx?: SxProps; data?: Config; model: string }) {
  const { t } = useTranslation();
  const [configs, setConfigs] = useState<ParsedConfig | undefined>(data && parseConfig(data, model));
  const flattenedConfigs = useMemo(() => configs && flatConfig(configs), [configs]);

  return (
    <ConfigContext.Provider value={{ data: configs, setData: setConfigs }}>
      <Area sx={sx ? sx : {}}>
        <AreaHeader>
          <Subtitle>{t('Configuration')}</Subtitle>
          <SmartStringifiedConfiguration />
        </AreaHeader>
        <RenderIf condition={!data}>
          <AreaBody>
            <Body>{t('missingConfig')}</Body>
          </AreaBody>
        </RenderIf>
        <RenderIf condition={!!data}>
          <AreaBody sx={{ mt: 2 }}>
            {flattenedConfigs?.map((fragment, i, arr) => {
              const areElementsEven = arr.length % 2 === 0;

              const fragmentedPath = fragment.id.split('/');
              const key = fragmentedPath.at(-2);
              const groupKey = fragmentedPath.at(-1);

              if (fragmentedPath.length === 0 || !key || !groupKey) {
                return <Fragment key={fragment.id} />;
              }

              if (isParsedLiteralFragment(fragment)) {
                return (
                  <Box
                    key={fragment.id}
                    sx={{
                      bgcolor: (theme) =>
                        (fragment.current && alpha(theme.palette.primary.light, 0.2)) ||
                        ((i + Number(areElementsEven)) % 2 === 0 && alpha(theme.palette.background.default, 0.5)) ||
                        'transparent',
                      display: 'grid',
                      gridTemplate: 'auto / 1fr 1fr 2fr',
                      p: 1,
                      gap: 1,
                    }}
                  >
                    <Typography variant="body1" color="text.secondary">
                      {t(key)}
                    </Typography>
                    <Typography variant="body1" fontWeight={600}>
                      {fragment.value}{' '}
                    </Typography>
                    <Typography variant="body1" fontWeight={400} component="span">
                      {t(fragment.id)}
                    </Typography>
                  </Box>
                );
              }

              return (
                <Box
                  key={fragment.id}
                  sx={{
                    bgcolor: (theme) =>
                      (fragment.current && alpha(theme.palette.primary.light, 0.2)) ||
                      ((i + Number(areElementsEven)) % 2 === 0 && alpha(theme.palette.background.default, 0.5)) ||
                      'transparent',
                    display: 'grid',
                    gridTemplate: 'auto / 1fr 1fr 2fr',
                    p: 1,
                    gap: 1,
                  }}
                >
                  <Typography variant="body1" color="text.secondary">
                    {t(groupKey)}
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    {' '}
                  </Typography>
                  <Typography variant="body1" fontWeight={400} component="span">
                    {' '}
                  </Typography>
                </Box>
              );
            })}
          </AreaBody>
        </RenderIf>
      </Area>
    </ConfigContext.Provider>
  );
}

export default Configuration;
