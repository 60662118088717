import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';

const ScrollTopOutlet = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <Outlet />;
};

export default ScrollTopOutlet;
