import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React from 'react';
import { StoreFilterOption } from '../../Filters/typings';
import { AutocompleteRenderOptionState, Box, Typography } from '@mui/material';

const CustomerListItem = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: StoreFilterOption & { email?: string | undefined; hasHomonym?: boolean | undefined },
  state: AutocompleteRenderOptionState
) => {
  const matches = match(option.label, state.inputValue, { insideWords: true });
  const labelWithQueryHighlight = parse(option.label, matches);

  return (
    <li {...props} key={option?.optionId}>
      <Box alignItems="center">
        <Box sx={{ wordWrap: 'break-word' }}>
          {labelWithQueryHighlight.map((part, index) => (
            <Box
              key={`${index}-${part.text}`}
              component="span"
              sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
            >
              {part.text}
            </Box>
          ))}
          {option?.hasHomonym && (
            <Typography variant="body2" color="text.secondary">
              {option?.['email']}
            </Typography>
          )}
        </Box>
      </Box>
    </li>
  );
};

export default CustomerListItem;
