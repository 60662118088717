import { ApiResult } from '@typings';
import { CommandRes } from '@/components/OneTools/Channels/Commands/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';

const URL_PREFIX = `${API_VERSION.v2}/system/commands`;

export const commandsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Commands'] }).injectEndpoints({
  endpoints: (builder) => ({
    getCommands: builder.query<ApiResult<{ items: CommandRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Commands'],
    }),
    getCommand: builder.query<ApiResult<CommandRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Commands'],
    }),
    isCommandUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetCommandsQuery,
  useGetCommandsQuery,
  useGetCommandQuery,
  useLazyGetCommandQuery,
  useLazyIsCommandUniqueQuery,
} = commandsApiSlice;
