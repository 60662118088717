import Area, { AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { OperatingModeCommandPayload } from '@/redux/api/admin/deviceCommandsApiSlice';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { NotEmptyList } from '@typings';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from '../../../DevicesPanel/typings';
import { OperatingMode as OperatingModeT } from '@culligan-iot/domain/culligan/one/device';
import CommandsButtonGroup from './CommandsButtonGroup';
import * as S from '@effect/schema/Schema';
import { Box, Tooltip } from '@mui/material';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
function OperatingMode({
  device,
  isAdmin,
  isInLoadingState,
  isLoading,
  isDeviceOnline,
  postOperatingMode,
}: {
  device: Device;
  isAdmin: boolean;
  isLoading: boolean;
  isInLoadingState: boolean;
  isDeviceOnline: boolean;
  postOperatingMode: MutationTrigger<OperatingModeCommandPayload & any>;
}) {
  const { t } = useTranslation();
  const supportedOperatingModes = device?.supportedOperatingModes;
  const getHasSupportedOperatingModes = (
    modes: Array<OperatingModeT> | undefined
  ): modes is NotEmptyList<OperatingModeT> => Boolean(supportedOperatingModes?.length) ?? false;
  const hasSupportedOperatingModes = getHasSupportedOperatingModes(supportedOperatingModes);
  const validate = S.decodeUnknownEither(OperatingModeT);
  const isValidOperatingMode = validate(device.operatingMode)._tag === 'Right' ? true : false;
  const operatingModesList = Object.values(OperatingModeT.enums);
  return (
    <Fragment>
      <Area
        sx={{
          width: '100%',
          gridColumnStart: '1',
          gridColumnEnd: hasSupportedOperatingModes ? '3' : '4',
          gridRowStart: '1',
          gridRowEnd: '-1',
          borderRadius: 1,
          overflow: 'hidden',
        }}
      >
        <AreaHeader>
          <TypographyWithTooltip
            slots={{
              AnchorTextTypography: Subtitle,
            }}
            anchorText={t('operatingMode')}
            tooltipText={t('operatingModeTooltip')}
          />
        </AreaHeader>
        <AreaBody>
          {!isValidOperatingMode ? (
            <Tooltip
              title={`${device.operatingMode} is not a valid operating mode. Expected values: ${operatingModesList.join(
                ', '
              )}`}
            >
              <Box>
                <Title>INVALID</Title>
              </Box>
            </Tooltip>
          ) : (
            <Title>{device?.operatingMode}</Title>
          )}
        </AreaBody>
      </Area>
      <RenderIf condition={isAdmin && hasSupportedOperatingModes}>
        <CommandsButtonGroup
          serialNumber={device.serialNumber}
          operatingMode={device.operatingMode}
          isDeviceOnline={isDeviceOnline}
          postOperatingMode={postOperatingMode}
          isInLoadingState={isInLoadingState}
          supportedOperatingModes={supportedOperatingModes as NotEmptyList<OperatingModeT>}
        />
      </RenderIf>
    </Fragment>
  );
}
export default React.memo(OperatingMode);
