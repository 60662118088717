import AlarmsTable from '@/components/Fleet/AlarmsAndErrors/Alarms/AlarmsTable';
import { Box } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { DeviceContext } from './DeviceContext';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import IssueDetails from '@/components/Shared/AlarmDialog/IssueDetails';
import { useTranslation } from 'react-i18next';
import { DeviceAlarm } from '@/components/Fleet/Devices/DevicesPanel/typings';

export default function Alarms() {
  const { t } = useTranslation();
  const context = useContext(DeviceContext);
  const [selectedAlarm, setSelectedAlarm] = useState<DeviceAlarm | null>(null);
  const [open, setOpen] = useState(false);
  const handleDetailClick = useCallback(
    (id: DeviceAlarm['id']) => {
      setSelectedAlarm(context?.data?.alarms.find((a) => a.id === id) || null);
      setOpen(true);
    },
    [context]
  );
  const { dialog } = useCulliganDialog({
    open: open,
    handleClose: () => setOpen(false),
    tabs: [
      {
        id: 'issue',
        label: t('alarm'),
        body: open && selectedAlarm && <IssueDetails data={selectedAlarm} isFleet={false} />,
      },
    ],
    styles: {
      bodyContainer: { p: 0, width: '25rem' },
    },
  });

  if (!context) {
    return <></>;
  }

  return (
    <Box>
      {dialog}
      <AlarmsTable onDetailClick={handleDetailClick} items={context.data?.alarms || []} kind="device" />
    </Box>
  );
}
