import { ApiResult } from '@typings';
import { TelemetryRes } from '@/components/OneTools/Channels/Telemetry/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';

const URL_PREFIX = `${API_VERSION.v2}/system/telemetry`;

export const TelemetryApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Telemetry'] }).injectEndpoints({
  endpoints: (builder) => ({
    getTelemetryEntries: builder.query<ApiResult<{ items: TelemetryRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Telemetry'],
    }),
    getTelemetryEntry: builder.query<ApiResult<TelemetryRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      transformResponse: (response: ApiResult<TelemetryRes>) => {
        if (!response.data) {
          return response;
        }
        if (response.data?.primitive === 'boolean') {
          let newTriageValueKey = response.data.triageExpectedValue;
          delete response.data.triageExpectedValue;
          response.data.triageExpectedBooleanValue = newTriageValueKey;
        }
        if (response.data?.primitive === 'string') {
          let newTriageValueKey = response.data.triageExpectedValue;
          delete response.data.triageExpectedValue;
          response.data.triageExpectedStringValue = newTriageValueKey;
        }
        return response;
      },
      providesTags: ['Telemetry'],
    }),
    isTelemetryUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetTelemetryEntriesQuery,
  useGetTelemetryEntriesQuery,
  useLazyGetTelemetryEntryQuery,
  useLazyIsTelemetryUniqueQuery,
} = TelemetryApiSlice;
