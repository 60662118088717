import { Column } from '@material-table/core';
import { ReactionRes } from './typings';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export default function useReactionColumns(): Array<Column<ReactionRes>> {
  const { t } = useTranslation();
  const columns_array: Array<Column<ReactionRes>> = [];

  return columns_array.concat([
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('kind'),
      field: 'kind',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('destination'),
      field: 'destination',
      render: (reaction) => (reaction.destination ? (reaction.destination as string[]).join(', ') : <></>),
      exportTransformer: (reaction) => reaction.destination?.join(' - '),
      sorting: false,
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (reaction) => dayjs(reaction.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (reaction) => dayjs(reaction.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
  ]);
}
