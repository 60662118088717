import { Box, Typography } from '@mui/material';
import { DeviceConsumableActions } from '../typings';
import { useTranslation } from 'react-i18next';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import DeviceConsumableCard from './DeviceConsumableCard';
import { DeviceConsumable } from '../../DevicesPanel/typings';

const DeviceConsumablesList = ({
  data,
  actions,
  isDeviceConnected,
}: {
  data: DeviceConsumable[];
  actions?: DeviceConsumableActions;
  isDeviceConnected: boolean;
}) => {
  const { t } = useTranslation();

  return data && data.length ? (
    <Box
      display="flex"
      gap="2rem"
      sx={{
        flexWrap: 'wrap',
      }}
    >
      {[...data]
        .sort((c_curr, c_next) => c_curr.index - c_next.index)
        .map((consumable, index) => {
          return (
            <DeviceConsumableCard
              isDeviceConnected={isDeviceConnected}
              slot={consumable}
              slotCount={index + 1}
              key={consumable.index}
            />
          );
        })}
    </Box>
  ) : (
    <Typography variant="h6" textAlign={'center'}>
      {t('noConsumablesForDevice')}
    </Typography>
  );
};

export default withErrorLoadingManagement(DeviceConsumablesList);
