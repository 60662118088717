import ErrorsTable from '@/components/Fleet/AlarmsAndErrors/Errors/ErrorsTable';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';

export default function Errors() {
  const context = useContext(DeviceContext);

  if (!context) {
    return <></>;
  }

  return (
    <Box>
      <ErrorsTable items={context.data?.errors || []} kind="device" />
    </Box>
  );
}
