import EventsPanel from '@/components/Fleet/Devices/DevicePanel/EventsPanel';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';

export default function Events() {
  const context = useContext(DeviceContext);

  if (!context || !context.data) {
    return <></>;
  }

  return <EventsPanel deviceId={context.data.id} />;
}
