import { Command } from '@/components/Fleet/Devices/DevicesPanel/typings';
import i18next from 'i18next';
import { OperatingMode, PowerProfile } from '@culligan-iot/domain/culligan/one/device';
import { NotEmptyList } from '@typings';

export const validateVersion = (string: string) => {
  // Allows for alphanumeric characters, periods, and spaces
  const pattern = /^[a-z0-9. ]+$/i;
  return (
    (pattern.test(string) && string.length && string.trim() !== '') || (i18next.t('invalidVersionFormat') as string)
  );
};

export const parseVersion = (version: string | Array<string>): string | Array<string> => {
  // Matches numbers
  const pattern = /[^0-9.]/g;
  if (Array.isArray(version)) {
    return version.map((v) => v.replace(pattern, ''));
  }
  return version.replace(pattern, '');
};

export const compareVersions = (versionA: string, versionB: string): number => {
  const partsA = (parseVersion(versionA) as string).split('.').map(Number);
  const partsB = (parseVersion(versionB) as string).split('.').map(Number);

  for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
    const a = partsA[i];
    const b = partsB[i];
    if (a > b) return 1;
    if (a < b) return -1;
  }
  return 0;
};

export const isGreaterVersion = (toBeNewer: string, toCompareWith: string): boolean => {
  return compareVersions(toBeNewer, toCompareWith) > 0;
};

export const isGreaterOrEqualVersion = (toBeNewer: string, toCompareWith: string): boolean => {
  return compareVersions(toBeNewer, toCompareWith) >= 0;
};

export const isNotEmptyList = <T>(xs: Array<T> | T | null | undefined): xs is NotEmptyList<T> =>
  !!xs && ((Array.isArray(xs) && xs.length > 0) || (ArrayBuffer.isView(xs) && xs.byteLength > 0));

export const isValidOperatingMode = (mode: OperatingMode) => {
  const modes: OperatingMode[] = ['Standard', 'Disabled', 'Fault', 'Ota', 'Startup', 'Factory'];
  return modes.includes(mode);
};

export const isValidPowerProfile = (profile: PowerProfile) => {
  const profiles: PowerProfile[] = ['Standard', 'Eco', 'Sleep'];
  return profiles.includes(profile);
};

export const getSupportedOperatingModeActions = (mode: OperatingMode) => {
  switch (mode) {
    case 'Standard':
      return ['DISABLE', 'OTA', 'REBOOT'];
    case 'Disabled':
      return ['ENABLE'];
    case 'Fault':
      return ['REBOOT'];
    case 'Ota':
      return ['OTA'];
    case 'Installation':
      return ['OTA'];
    case 'Factory':
      return [''];
    default:
      return [];
  }
};

export const getNextOperatingMode = (mode: OperatingMode): Array<OperatingMode> => {
  switch (mode) {
    case 'Standard':
      return ['Fault', 'Disabled', 'Ota'];
    case 'Disabled':
      return ['Standard'];
    case 'Fault':
      return ['Startup'];
    case 'Startup':
      return ['Fault', 'Factory'];
    case 'Factory':
      return ['Standard', 'Startup'];
    default:
      return [];
  }
};

export const getSupportedPowerProfileActions = (profile?: PowerProfile): Array<OperatingMode | PowerProfile> => {
  switch (profile) {
    case 'Standard':
      return ['Sleep'];
    case 'Eco':
      return [];
    case 'Sleep':
      return ['Standard'];
    default:
      return [];
  }
};

export const getOperatingModeFromCommand = (command: Command): OperatingMode => {
  switch (command) {
    case 'enable':
      return 'Standard';
    case 'disable':
      return 'Disabled';
    case 'reboot':
      return 'Fault';
    case 'ota.update':
      return 'Ota';
    default:
      return 'Standard';
  }
};

export const getPowerProfileFromCommand = (command: Command): PowerProfile => {
  switch (command) {
    case 'power.profile.standard':
      return 'Standard';
    case 'power.profile.sleep':
      return 'Sleep';
    default:
      return 'Standard';
  }
};
