import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import { removeFieldsFromJson } from '@/shared/utils';
import PrintItem from './PrintItem';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import { ROUTES } from '@/shared/constants';
import { Outlet, useNavigate } from 'react-router';
import { useContext } from 'react';
import { DeviceIdentityContext } from './DeviceIdentityContext';
import useCurrentTab from '@/hooks/useCurrentTab';

export default function DeviceIdentityChannels() {
  const { t } = useTranslation();

  const deviceIdentity = useContext(DeviceIdentityContext);
  const navigate = useNavigate();

  const deviceIdentitiesRelated = {
    alarms: deviceIdentity?.alarms || [],
    commands: deviceIdentity?.commands,
    configs: deviceIdentity?.configs || [],
    consumables: deviceIdentity?.consumables,
    events: deviceIdentity?.events || [],
  };

  const deviceIdentitiesRelatedWithoutSomeFields = removeFieldsFromJson(deviceIdentitiesRelated);

  const tabsConfig = [
    {
      id: ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment, { preventScrollReset: true }),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment, { preventScrollReset: true }),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_ALARMS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment, { preventScrollReset: true }),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment, { preventScrollReset: true }),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment, { preventScrollReset: true }),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment, { preventScrollReset: true }),
    },
  ] satisfies TabConfig[];

  const { index } = useCurrentTab(tabsConfig);

  return (
    <Box>
      <Box sx={{ '@media print': { display: 'none' } }}>
        <Typography variant="h5" fontWeight={800} sx={{ marginBottom: 1 }}>
          {t('channels')}
        </Typography>
        {index !== 0 && <NavigationTabsLayout config={tabsConfig} navigateOnChange={false} defaultIndex={index - 1} />}
        <Outlet />
      </Box>
      <Box sx={{ display: 'none', '@media print': { display: 'block' } }}>
        {Object.keys(deviceIdentitiesRelatedWithoutSomeFields)?.map((related) => (
          <Box sx={{ marginTop: '12mm', marginBottom: '3mm' }} key={related}>
            <Typography variant="h5" fontWeight={400} sx={{ marginBottom: '3mm' }}>
              {t(related)}
            </Typography>
            <>
              {deviceIdentitiesRelatedWithoutSomeFields[related] &&
              deviceIdentitiesRelatedWithoutSomeFields[related].length > 0
                ? deviceIdentitiesRelatedWithoutSomeFields[
                    related as keyof typeof deviceIdentitiesRelatedWithoutSomeFields
                  ]?.map((item: any, index: number) => {
                    return <PrintItem key={related + index} item={item} index={index} />;
                  })
                : t('noRecordsToDisplay')}
            </>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
