import MaterialTable, { Column, MaterialTableProps } from '@material-table/core';
import { Box, useTheme } from '@mui/material';
import { useState, createContext, useMemo, useCallback } from 'react';
import HiddenColumnChip from './HiddenColumnChip';
import React from 'react';

const DEFAULT_PADDING = '0.5rem';
export const HiddenColumnsContext = createContext(0);

const Actions = React.memo(() => (
  <Box px={1} height="100%" display="flex" alignItems="center">
    <HiddenColumnChip sx={{}} />
  </Box>
));

const CulliganTable = <T extends object>(props: MaterialTableProps<T>) => {
  const theme = useTheme();
  const [hiddenColumns, setHiddenColumns] = useState(0);

  const options = useMemo(
    () =>
      ({
        headerStyle: {
          ...props.options?.headerStyle,
          backgroundColor: theme.palette.background.grayShades[0],
          paddingTop: DEFAULT_PADDING,
          paddingBottom: DEFAULT_PADDING,
          fontWeight: 500,
          fontSize: '1rem',
        },
        tableLayout: 'auto',
        pageSize: props.options?.pageSize || 10,
        ...props.options,
      } as const),
    [props.options, theme.palette.background.grayShades]
  );

  const handleChangeColumnHidden = useCallback(
    (column: Column<T>, hidden: boolean) => {
      setHiddenColumns((prev) => (hidden ? prev + 1 : prev - 1));
      if (typeof props.onChangeColumnHidden === 'function') {
        props.onChangeColumnHidden(column, hidden);
      }
    },
    [props]
  );

  const components = useMemo(
    () => ({
      ...(props.options?.paging && props.options?.pageSize ? { Pagination: () => null } : {}),
      Actions,
      ...props.components,
    }),
    [props.options?.paging, props.options?.pageSize, props.components]
  );

  return (
    <HiddenColumnsContext.Provider value={hiddenColumns}>
      <MaterialTable<T>
        {...props}
        components={components}
        options={options}
        onChangeColumnHidden={handleChangeColumnHidden}
      />
    </HiddenColumnsContext.Provider>
  );
};

export default CulliganTable;
