import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { FleetAlarm } from '../typings';
import { useTranslation } from 'react-i18next';
import { useAlarmColumns } from './useAlarmsColumn';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { DeviceAlarm } from '../../Devices/DevicesPanel/typings';
import { Column, MaterialTableProps } from '@material-table/core';
import { ROUTES } from '@/shared/constants';
import { FleetOverview } from '../../Overview/typings';
import { useMemo } from 'react';

export const SafeTable = withErrorLoadingManagement(
  withResetNavigationState(GenericExportTable<FleetAlarm | DeviceAlarm>)
);

export type AlarmKind = 'fleet' | 'device' | 'overview';

export type AlarmKindMap = {
  fleet: FleetAlarm;
  device: DeviceAlarm;
  overview: FleetOverview.Alarm;
};

type PropVariants = {
  [K in AlarmKind]: {
    kind: K;
    items: AlarmKindMap[K][];
    onDetailClick?: (deviceId: string) => void;
    slots?: {
      dialog: () => ReturnType<typeof useCulliganDialog>['dialog'];
    };
    excludeColumns?: string[];
  } & Omit<MaterialTableProps<any>, 'data' | 'columns'>;
}[AlarmKind];

const areFleetColumns = (kind: AlarmKind, cols: unknown[]): cols is Column<FleetAlarm>[] =>
  cols.filter((_) => kind === 'fleet').length > 0;
const areFleetItems = (kind: AlarmKind, items: unknown[]): items is FleetAlarm[] =>
  items.filter((_) => kind === 'fleet').length > 0;
const areDeviceColumns = (kind: AlarmKind, cols: unknown[]): cols is Column<DeviceAlarm>[] =>
  cols.filter((_) => kind === 'device').length > 0;
const areDeviceItems = (kind: AlarmKind, items: unknown[]): items is DeviceAlarm[] =>
  items.filter((_) => kind === 'device').length > 0;
const areOverviewColumns = (kind: AlarmKind, cols: unknown[]): cols is Column<FleetOverview.Alarm>[] =>
  cols.filter((_) => kind === 'overview').length > 0;
const areOverviewItems = (kind: AlarmKind, items: unknown[]): items is FleetOverview.Alarm[] =>
  items.filter((_) => kind === 'overview').length > 0;

export default function AlarmsTable(props: PropVariants) {
  const { t } = useTranslation();
  const columns = useAlarmColumns({
    kind: props.kind,
    onDetailClick: props.onDetailClick,
    excludeColumns: props?.excludeColumns,
  });

  const table = useMemo(() => {
    switch (true) {
      case areFleetColumns(props.kind, columns) && areFleetItems(props.kind, props.items):
        return (
          <GenericExportTable<FleetAlarm>
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            data={props.items}
            columns={columns}
            exportData={true}
            selection={true}
          />
        );
      case areDeviceColumns(props.kind, columns) && areDeviceItems(props.kind, props.items):
        return (
          <GenericExportTable<DeviceAlarm>
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            data={props.items}
            columns={columns}
            exportData={true}
            selection={true}
          />
        );
      case areOverviewColumns(props.kind, columns) && areOverviewItems(props.kind, props.items): {
        return (
          <GenericExportTable<FleetOverview.Alarm>
            {...props}
            title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
            data={props.items}
            columns={columns}
            exportData={true}
            selection={true}
          />
        );
      }
    }
  }, [props, columns, t]);

  return (
    <>
      {table}
      {props.slots && props.slots.dialog()}
    </>
  );
}
