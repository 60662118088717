import { AlarmsTable } from '@/components/OneTools/Channels/Alarms';
import { ROUTES } from '@/shared/constants';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityContext } from '../DeviceIdentityContext';
import useAlarmsColumns from '@/components/OneTools/Channels/Alarms/useAlarmsColumns';

export default function Alarms() {
  const { t } = useTranslation();
  const deviceIdentity = useContext(DeviceIdentityContext);
  const alarmsColumns = useAlarmsColumns(deviceIdentity?.alarms);

  return (
    <AlarmsTable
      title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
      data={deviceIdentity?.alarms ?? []}
      columns={alarmsColumns}
      isLoading={false}
      isError={false}
      resetStateButtonVisible={false}
      exportData={false}
      selection={false}
    />
  );
}
