import dayjs from 'dayjs';
import { HistoryTelemetry, MergedTelemetry } from '../../typings';
import { ApiResult } from '@typings';

const filterOutBooleansAndStrings = (item: { [key in string]: boolean | string | number | undefined }) => {
  return {
    ...Object.keys(item).reduce<{ [key in string]: number }>((acc, key) => {
      return { ...acc, ...(typeof item[key] === 'number' ? { [key]: item[key] as number } : {}) };
    }, {}),
  } as HistoryTelemetry;
};

export const removeBooleansAndStringsTelemetry = (
  data:
    | ApiResult<{
        items: HistoryTelemetry[];
      }>
    | undefined
) =>
  data?.data?.items.flatMap<HistoryTelemetry>((v) => {
    const filteredValue = filterOutBooleansAndStrings(v);

    if (Object.keys(filteredValue).some((k) => k !== 'lastUpdate')) {
      return filteredValue;
    }

    return [];
  });

export const groupItemsByDay = (
  deviceTelemetries: MergedTelemetry[] | undefined,
  data:
    | ApiResult<{
        items: HistoryTelemetry[];
      }>
    | undefined
) => {
  const keyNameMap: any = deviceTelemetries
    ?.flatMap((telemetry) => {
      if (telemetry.type === 'group' && telemetry.items) {
        // Mappatura per gli items di tipo 'group'
        return telemetry.items.map((item) => ({ [item.id]: item.name }));
      } else if (telemetry.type === 'single') {
        // Mappatura per gli items di tipo 'single'
        return { [telemetry.id]: telemetry.name };
      }
      return [];
    })
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  const itemsWithNamesAndValues = data?.data?.items.map((item) => {
    return Object.keys(item).reduce<HistoryTelemetry>((acc, key) => {
      const name = keyNameMap[key] || key;
      acc[name] = item[key];
      return acc;
    }, {} as HistoryTelemetry);
  });

  const res = itemsWithNamesAndValues?.reduce((acc, historyTelemetry) => {
    const day = dayjs(historyTelemetry.lastUpdate).startOf('d').valueOf();
    acc.set(day, [...(acc.get(day) || []), historyTelemetry]);

    return acc;
  }, new Map<number, HistoryTelemetry[]>());

  if (res) {
    return [...res.entries()];
  }
  return [];
};
