import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../store';
import { Role } from '@typings';

const initialState: AuthState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  roles: [],
};

export type AuthState = {
  user: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  roles: Role[];
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthState>) => {
      const { user, accessToken, refreshToken, roles } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.roles = roles;
    },
    logout: () => initialState,
  },
});

export const selectUser = (state: AppState) => state.auth.user;
export const selectAccessToken = (state: AppState) => state.auth.accessToken;
export const selectRoles = (state: AppState) => state.auth.roles;

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
