import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

type HexColorCode = `#${string}`;

type ThemeMode = 'light' | 'dark';

type BackgroundMode = '#fff' | '#121212';

type ThemeState = {
  primary: HexColorCode;
  secondary: HexColorCode;
  background: BackgroundMode;
  mode: ThemeMode;
};

type ThemeModifiers = {
  primary?: HexColorCode;
  secondary?: HexColorCode;
  mode?: ThemeMode;
};

// Define the initial state
export const initialThemeState: ThemeState = {
  primary: '#2968FE',
  secondary: '#FEBE29',
  background: '#fff',
  mode: 'light',
};

const setColors = ({
  state,
  themeModifiers: { mode, primary, secondary },
}: {
  state: ThemeState;
  themeModifiers: ThemeModifiers;
}) => {
  if (mode && mode !== state.mode) {
    state.mode = state.mode === 'light' ? 'dark' : 'light';
    state.background = state.background === '#fff' ? '#121212' : '#fff';
  }

  if (primary && primary !== state.primary) {
    state.primary = primary;
  }
  if (secondary && secondary !== state.secondary) {
    state.secondary = secondary;
  }

  return state;
};

// Create the slice
const themeSlice = createSlice({
  name: 'themeSlice',
  initialState: initialThemeState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeState>) => {
      return setColors({ state, themeModifiers: action.payload });
    },
    resetTheme: (_) => ({ ...initialThemeState }),
  },
});

// Selector that will return the current state of the ThemeSlice
export const themeSelector = createSelector(
  (state: { themeSlice: ThemeState }) => state.themeSlice,
  (themeSlice) => themeSlice
);

// Export actions and reducer
export const { setTheme, resetTheme } = themeSlice.actions;
export default themeSlice.reducer;
