import ConsumablesCard from '@/components/Fleet/Overview/ConsumablesCard';
import FleetLocationsCard from '@/components/Fleet/Overview/FleetLocationsCard';
import FleetGeneral from '@/components/Fleet/Overview/FleetGeneral';
import AlarmsAndErrorCard from '@/components/Fleet/Overview/AlertsAndErrorCard';
import StoreFilters from '@/components/Shared/Filters/StoreFilters';
import useFleetOverviewFilters from '@/components/Fleet/Overview/FleetGeneral/useFleetOverviewFilters';
import { StoreFilterConfig } from '@/components/Shared/Filters/typings';
import { FiltersContext } from '@/components/Fleet/Overview/FiltersContext';

export const Overview = () => {
  const { filterConfig, handleFiltersApplied, handleFiltersCleared, filters, isLoadingFilters } =
    useFleetOverviewFilters();

  return (
    <>
      <FiltersContext.Provider value={filters}>
        {!isLoadingFilters && (
          <StoreFilters
            filterConfigs={filterConfig as StoreFilterConfig[]}
            onFiltersApplied={handleFiltersApplied}
            onFiltersCleared={handleFiltersCleared}
          />
        )}
        <FleetGeneral />
        <FleetLocationsCard />
        <ConsumablesCard />
        <AlarmsAndErrorCard />
      </FiltersContext.Provider>
    </>
  );
};

export default Overview;
