import { AutocompleteRenderOptionState, Box, Paper, Skeleton, Typography } from '@mui/material';
import type {} from 'redux-thunk/extend-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useRef, lazy, Suspense, MutableRefObject } from 'react';
import { getPath, toZeroBasedIndex } from '@/shared/utils';
import { StoreFilterConfig, StoreFilterOption } from '@/components/Shared/Filters/typings';
import { ToDelete, ToUpsert, useQueryStringFilters } from '@/hooks/useQueryStringFilters';
import {
  useLazyGetUsersQuery,
  UsersFilterRequest,
  UsersFilterResponse,
} from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FleetDevices } from '@/components/Fleet/Devices/DevicesPanel/typings';
import { useDispatch } from 'react-redux';
import { fleetApiSlice, useGetFleetFiltersQuery } from '@/redux/api/fleet/fleetApiSlice';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import useCurrentTab from '@/hooks/useCurrentTab';
import { FRAGMENTS } from '@/shared/constants';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';

const StoreFilters = lazy(() => import('@/components/Shared/Filters/StoreFilters'));

export const INITIAL_PAGE = 1 as const;
export const INITIAL_PAGE_SIZE = 10 as const;

export type DevicesContext = {
  filters: FleetDevices.DecodedFilters;
  upsertQueryFilters: (toUpsert: ToUpsert<FleetDevices.DecodedFilters>) => void;
  deleteQueryFilters: (toDelete: ToDelete<FleetDevices.DecodedFilters>) => void;
  tableRef: MutableRefObject<FleetDevices.Table>;
};

const Devices = () => {
  const { t } = useTranslation();
  const tableRef = useRef<FleetDevices.Table>();
  const { getQueryFilters, upsertQueryFilters, setQueryFilters, deleteQueryFilters } = useQueryStringFilters<
    FleetDevices.DecodedFilters,
    FleetDevices.EncodedFilters
  >({
    encode: FleetDevices.encode,
    decode: FleetDevices.decode,
  });
  const queryFilters = getQueryFilters();
  const {
    data: filters,
    isLoading: isLoadingFilters,
    isFetching: isPendingFilters,
  } = useGetFleetFiltersQuery({
    keys: ['brands', 'models'],
  });
  const brands = useMemo(() => filters?.data?.find((f) => f._tag === 'brands')?.brands || [], [filters]);
  const models = useMemo(() => filters?.data?.find((f) => f._tag === 'models')?.models || [], [filters]);
  const location = useLocation();
  const isMap = location.pathname.includes(FRAGMENTS.MAP);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const maybeModel = useMemo(
    () =>
      queryFilters?.model
        ? models
            ?.filter((identity) => identity.id === queryFilters?.model)
            .map((identity) => ({ label: `${identity.name} (${identity.id})`, optionId: identity.id }))
            .at(0)
        : undefined,
    [models, queryFilters?.model]
  );

  const maybeBrand = useMemo(
    () =>
      queryFilters?.brand
        ? brands
            ?.filter((b) => b.id === queryFilters?.brand)
            .map((b) => ({ label: b.name, optionId: b.id }))
            .at(0)
        : undefined,
    [brands, queryFilters?.brand]
  );

  const handleApplyFilters = useCallback(
    (filters: Map<FleetDevices.FilterKey, string>) => {
      const additionalTableFilters = !isMap
        ? [
            { key: FleetDevices.FilterKeys.Page, value: INITIAL_PAGE },
            { key: FleetDevices.FilterKeys.Size, value: INITIAL_PAGE_SIZE },
            queryFilters.search ? { key: FleetDevices.FilterKeys.Search, value: queryFilters.search } : null,
          ].filter((filter) => filter != null)
        : [];
      setQueryFilters([
        ...Array.from(filters, ([key, value]) => ({ key: key, value: value })),
        ...additionalTableFilters,
      ]);

      tableRef?.current?.onQueryChange({
        page: toZeroBasedIndex(INITIAL_PAGE),
        pageSize: INITIAL_PAGE_SIZE,
        search: queryFilters.search,
      });
    },
    [isMap, queryFilters.search, setQueryFilters]
  );

  const handleClearQueryFilters = useCallback(() => {
    const toClear = new Set(Object.values(FleetDevices.FilterKeys));
    const toKeep = new Set(
      !isMap
        ? [
            FleetDevices.FilterKeys.Page,
            FleetDevices.FilterKeys.Size,
            FleetDevices.FilterKeys.OrderBy,
            FleetDevices.FilterKeys.Direction,
          ]
        : []
    );
    const asList = Array.from(toClear.difference(toKeep));
    deleteQueryFilters(asList);
    tableRef.current?.onQueryChange({
      page: toZeroBasedIndex(INITIAL_PAGE),
      pageSize: INITIAL_PAGE_SIZE,
    });

    /**
     * At the moment there's no way to programmatically clear the searchText in the MToolbar
     * due to inconsistencies between the fork and the original implementation.
     * To be specific, the old method working here by triggering `onSearchChange` on the tableRef
     * https://github.com/mbrn/material-table/issues/2302#issuecomment-697043151 is not working anymore.
     * After a lot of trials, I've given up on dispatching the click event to the clear icon
     * to clear the TextField without re-implementing from scratch all the MTable Toolbar
     * features. If you find a better way to do it, please modify this behaviour.
     * Refs: https://github.com/mbrn/material-table/issues/2302
     */
    const searchTextClearIcon = document.querySelector('[aria-label="Clear Search"]');
    if (!searchTextClearIcon) {
      console.error('Could not find search text clear icon.');
    }
    searchTextClearIcon?.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
  }, [deleteQueryFilters, isMap]);

  const extractAutocompleteQueryParams = (query: string) => ({ query } as UsersFilterRequest);
  const fetchInitialCustomer = useCallback(async () => {
    if (!queryFilters.customer) {
      return;
    }
    const result = await dispatch(
      fleetApiSlice.endpoints.getFleetCustomer.initiate({ customerId: queryFilters.customer })
    );
    const _customer = result?.data?.data?.customer;

    return { label: `${_customer?.firstName} ${_customer?.lastName}`, optionId: _customer?.id || '' };
  }, [dispatch, queryFilters.customer]);

  const shouldStartCustomerQuery = useCallback((query: string) => query.length >= 3 && /[a-zA-Z]/g.test(query), []);
  const handleParseCustomerReponse = useCallback((data: { items: UsersFilterResponse[] }) => {
    return data?.items?.map((item) => {
      const toSearchForMatches = data?.items.filter((user) => user.id !== item.id);
      const hasHomonym =
        toSearchForMatches?.length > 0 &&
        toSearchForMatches.findIndex(
          (user) =>
            user?.firstName?.toLowerCase() === item?.firstName?.toLowerCase() &&
            user?.lastName?.toLowerCase() === item?.lastName?.toLowerCase()
        ) !== -1;
      return { ...item, optionId: item?.id, label: `${item?.firstName} ${item?.lastName}`, hasHomonym };
    });
  }, []);

  const renderAutocompleteCustomerOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: StoreFilterOption & { email?: string | undefined; hasHomonym?: boolean | undefined },
      state: AutocompleteRenderOptionState
    ) => {
      const matches = match(option.label, state.inputValue, { insideWords: true });
      const labelWithQueryHighlight = parse(option.label, matches);

      return (
        <li {...props} key={option?.optionId}>
          <Box alignItems="center">
            <Box sx={{ wordWrap: 'break-word' }}>
              {labelWithQueryHighlight.map((part, index) => (
                <Box
                  key={`${index}-${part.text}`}
                  component="span"
                  sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                >
                  {part.text}
                </Box>
              ))}
              {option?.hasHomonym && (
                <Typography variant="body2" color="text.secondary">
                  {option?.['email']}
                </Typography>
              )}
            </Box>
          </Box>
        </li>
      );
    },
    []
  );

  const filterConfigs: StoreFilterConfig[] = useMemo(
    () => [
      {
        kind: 'autocomplete',
        id: FleetDevices.FilterKeys.Model,
        label: t('deviceName'),
        defaultValue: maybeModel,
        options:
          models?.map((identity) => ({
            optionId: identity.id,
            value: identity.id,
            label: `${identity.name} (${identity.id})`,
          })) || [],
      },
      {
        kind: 'autocomplete',
        id: FleetDevices.FilterKeys.Status,
        label: t('connectionStatus'),
        defaultValue: queryFilters?.status
          ? { label: t(queryFilters?.status), optionId: queryFilters?.status }
          : undefined,
        options: [
          {
            label: t('connected'),
            optionId: 'connected',
          },
          {
            label: t('disconnected'),
            optionId: 'disconnected',
          },
        ],
      },
      {
        kind: 'autocomplete',
        id: FleetDevices.FilterKeys.Brand,
        label: t('businessUnit'),
        defaultValue: maybeBrand,
        options:
          brands?.map((brand) => ({
            label: brand.name,
            value: brand.id,
            optionId: brand.id,
          })) || [],
      },
      {
        id: 'customer',
        label: t('customer'),
        kind: 'asyncAutocomplete',
        getInitialValue: fetchInitialCustomer,
        shouldFetch: shouldStartCustomerQuery,
        transformFn: handleParseCustomerReponse,
        lazyQueryHook: useLazyGetUsersQuery,
        getQueryParam: extractAutocompleteQueryParams,
        debounceTime: 400,
        renderOption: renderAutocompleteCustomerOption,
      },
    ],
    [
      brands,
      fetchInitialCustomer,
      handleParseCustomerReponse,
      maybeBrand,
      maybeModel,
      models,
      queryFilters?.status,
      renderAutocompleteCustomerOption,
      shouldStartCustomerQuery,
      t,
    ]
  );

  const FiltersSkeleton = useMemo(
    () => (
      <Paper sx={{ padding: 2, marginBottom: '12px', display: 'flex', gap: '12px', height: 150 }}>
        {[...Array(filterConfigs.length)].map((_, index) => (
          <Skeleton key={index} variant="rounded" width={'25%'} height={50} />
        ))}
      </Paper>
    ),
    [filterConfigs.length]
  );

  const tabsConfig = useMemo(
    () =>
      [
        {
          id: FRAGMENTS.LIST,
          label: t('devicesList'),
          ariaControls: true,
          useHash: false,
          onNavigate: () => {
            const params = Object.entries(queryFilters)
              .filter(([key]) => key !== 'page' && key !== 'size')
              .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
              .join('&');
            navigate(`${getPath('FLEET_DEVICES_LIST')}?${params}&size=10&page=1`);
          },
        },
        {
          id: FRAGMENTS.MAP,
          label: t('devicesMap'),
          useHash: false,
          onNavigate: () => {
            const params = Object.entries(queryFilters)
              .filter(([key]) => key !== 'page' && key !== 'size' && key !== 'orderBy' && key !== 'direction')
              .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
              .join('&');
            navigate(`${getPath('FLEET_DEVICES_MAP')}?${params}`);
          },
        },
      ] satisfies TabConfig[],
    [navigate, queryFilters, t]
  );
  const { index } = useCurrentTab(tabsConfig);

  return (
    <Box>
      {index !== 0 && (
        <>
          <Suspense fallback={FiltersSkeleton}>
            {isLoadingFilters || isPendingFilters ? (
              FiltersSkeleton
            ) : (
              <StoreFilters
                filterConfigs={filterConfigs}
                onFiltersApplied={handleApplyFilters}
                onFiltersCleared={handleClearQueryFilters}
                externalFilters={[{ label: t('Search'), value: queryFilters.search || '' }]}
              />
            )}
          </Suspense>
          <NavigationTabsLayout config={tabsConfig} defaultIndex={index - 1} />
        </>
      )}

      <Outlet context={{ upsertQueryFilters, deleteQueryFilters, tableRef, filters: queryFilters }} />
    </Box>
  );
};

export default React.memo(Devices);
