import { Tuple } from 'victory';
import { Datum, GroupDatum, Multiline } from '../typings';
import { isDatum } from './isDatum';

export const findDomain = (data: Multiline, padding?: number): Tuple<number> => {
  let max = Number.MIN_SAFE_INTEGER;
  let min = Number.MAX_SAFE_INTEGER;

  Object.keys(data)?.forEach((key) => {
    if (isDatum(data[key])) {
      const numbers = (data[key] as Datum[])?.map((v) => v.y);
      max = Math.max(max, ...numbers);
      min = Math.min(min, ...numbers);
      return;
    }

    const _data = data[key] as GroupDatum;
    if (!_data) {
      return;
    }
    const keys = Object.keys(_data);
    let numbers: number[] = [];
    keys.forEach((k) => numbers.push(...(_data[k]?.map((v) => v.y) || [])));
    max = Math.max(max, ...numbers);
    min = Math.min(min, ...numbers);
  });

  if (padding) {
    const delta = max - min;
    max += (delta * padding) / 100;
    min -= (delta * padding) / 100;
  }

  return [max, min];
};
