import apiSlice, { API_VERSION } from '../apiSlice';
import { FilterErrorRequest, FleetError } from '@/components/Fleet/AlarmsAndErrors/typings';
import { ApiResult } from '@typings';

export const ErrorsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['FleetErrors', 'Fleet'] }).injectEndpoints({
  endpoints: (builder) => ({
    getFilteredErrors: builder.query<
      ApiResult<{ itemsCount: number; pagesCount: number; page: number; items: FleetError[] }>,
      FilterErrorRequest
    >({
      query: (params) => ({
        url: `${API_VERSION.v2}/fleet/errors/filteredList`,
        params,
      }),
      providesTags: ['FleetErrors', 'Fleet'],
    }),
    getErrorsFilters: builder.query<
      ApiResult<{ brands: { id: string; name: string }[]; identities: { id: string; name: string }[] }>,
      void
    >({
      query: () => {
        return {
          url: `${API_VERSION.v2}/fleet/errors/filters`,
        };
      },
      providesTags: ['FleetErrors', 'Fleet'],
    }),
  }),
});

export const { useLazyGetFilteredErrorsQuery, useGetFilteredErrorsQuery, useGetErrorsFiltersQuery } = ErrorsApiSlice;
