import { ApiResult } from '@typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FleetConsumables } from '@/components/Fleet/Consumables/ConsumablesPanel/typings';

type GetDeviceFiltersResult = {
  brands: { id: string; name: string }[];
  identities: { id: string; name: string }[];
  customer: { id: string; firstName: string; lastName?: string };
  kind: { id: string; name: string }[];
};

export type PaginatedResponse<T> = {
  itemsCount: number;
  pagesCount: number;
  page: number;
  items: T[];
};

const devicesWithConsumablesApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['FleetDevicesWithConsumables', 'Fleet', 'DevicesWithConsumablesFilters'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getDevicesWithConsumables: builder.query<
        ApiResult<PaginatedResponse<FleetConsumables.Device>>,
        FleetConsumables.DecodedFilters
      >({
        query: ({ level, ...params }) => {
          return {
            url: `${API_VERSION.v2}/fleet/devices/consumables`,
            params: {
              ...params,
              minLevel: level && FleetConsumables.LevelRanges[level][0],
              maxLevel: level && FleetConsumables.LevelRanges[level][1],
            },
          };
        },
        providesTags: ['FleetDevicesWithConsumables', 'Fleet'],
      }),
      getConsumablesFilters: builder.query<ApiResult<GetDeviceFiltersResult>, FleetConsumables.FilterParams>({
        query: (params) => ({
          url: `${API_VERSION.v2}/fleet/devices/filters`,
          params: FleetConsumables.encodeFilterParams(params),
        }),
        providesTags: ['DevicesWithConsumablesFilters'],
      }),
    }),
  });

export const {
  useGetDevicesWithConsumablesQuery,
  useLazyGetDevicesWithConsumablesQuery,
  useGetConsumablesFiltersQuery,
} = devicesWithConsumablesApiSlice;
