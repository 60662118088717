import dayjs from 'dayjs';
import { useTelemetryFiltersCtx } from '../../../TelemetryProvider';
import { useTranslation } from 'react-i18next';

import { Box, Chip, Typography } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { getFiltersArray } from '../../helpers/getFiltersArray';

export const TelemetryFiltersBar = () => {
  const { t } = useTranslation();
  const { start, end, telemetries: telemetryFilters } = useTelemetryFiltersCtx();

  const areTelemetryFiltersApplied = Boolean(telemetryFilters?.key?.length) || Boolean(telemetryFilters?.tag?.length);

  const chips = areTelemetryFiltersApplied ? (
    getFiltersArray(telemetryFilters).map((telemetry) => {
      if (typeof telemetry === 'string') {
        return <Chip key={telemetry} label={telemetry} size="small" icon={<KeyIcon sx={{ rotate: '-45deg' }} />} />;
      }

      if (telemetry.tagName) {
        return telemetry.key.map((t) => (
          <Chip
            key={telemetry.tagName + t}
            label={`${telemetry.tagName} - ${t}`}
            size="small"
            icon={<KeyIcon sx={{ rotate: '-45deg' }} />}
          />
        ));
      }
      return <></>;
    })
  ) : (
    <Chip
      variant="outlined"
      size="small"
      sx={{
        color: (theme) => theme.palette.grey[500],
      }}
      label={t('noItemsSelected')}
    />
  );

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1 }}>
      <Typography>{t('filters')}</Typography>
      {start && end && (
        <Chip label={`${dayjs(start).format('L')} - ${dayjs(end).format('L')}`} size="small" icon={<DateRangeIcon />} />
      )}
      {chips}
    </Box>
  );
};
