import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { Column, Options, OrderByCollection, Query, QueryResult } from '@material-table/core';
import { toOneBasedIndex, toZeroBasedIndex } from '@/shared/utils';
import { Direction } from '@/components/Shared/Tables/typings';
import { FleetDevices } from './typings';
import { useLazyGetPaginatedFilteredDevicesQuery } from '@/redux/api/fleet/devicesApiSlice';
import { useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ToDelete, ToUpsert } from '../../../../hooks/useQueryStringFilters';
import CulliganTable from '@/components/Shared/Tables/CulliganTable';

function DevicesPanel({ columns }: { columns: Array<Column<FleetDevices.Device>> }) {
  const { t } = useTranslation();
  const {
    filters,
    upsertQueryFilters,
    deleteQueryFilters,
    tableRef: table,
  } = useOutletContext<{
    filters: FleetDevices.DecodedFilters;
    upsertQueryFilters: (toUpsert: ToUpsert<FleetDevices.DecodedFilters>) => void;
    deleteQueryFilters: (toDelete: ToDelete<FleetDevices.DecodedFilters>) => void;
    tableRef: MutableRefObject<FleetDevices.Table>;
  }>();
  const { page, size, orderBy, direction, status, model, brand, customer, search } = filters;
  const [getDevices] = useLazyGetPaginatedFilteredDevicesQuery();
  const title = t('devices');
  const getPayload = useCallback(
    (query: Query<FleetDevices.Device>) => {
      const sortCollection = query.orderByCollection.length ? query.orderByCollection[0] : null;
      const field = sortCollection ? columns[sortCollection?.orderBy].field : null;
      const direction = (sortCollection?.orderDirection as Direction) || null;
      const hasSorting = field != null && direction != null;
      const payload: FleetDevices.DecodedFilters = {
        status: status,
        model: model,
        brand: brand,
        customer: customer,
        page: toOneBasedIndex(query.page),
        size: query.pageSize,
        orderBy: hasSorting ? field : (undefined as any),
        direction: hasSorting ? direction : undefined,
        search: search,
      };

      return payload;
    },
    [brand, columns, customer, model, search, status]
  );

  const handleFetchData = useCallback(
    (query: Query<FleetDevices.Device>) =>
      new Promise<QueryResult<FleetDevices.Device>>((resolve, reject) => {
        getDevices(getPayload(query), true)
          .unwrap()
          .then((result) => {
            const withSafeUniqueId = result?.data?.items.map((d) => ({ idSynonim: d.id + d.model, ...d }));

            return resolve({
              data: withSafeUniqueId || [],
              page: toZeroBasedIndex(result?.data?.page || 1),
              totalCount: result.data?.itemsCount || 0,
            });
          })
          .catch((err) => reject(err));
      }),
    [getDevices, getPayload]
  );

  const handlePageChange = useCallback(
    (pageFromQuery: number, pageSize: number) => {
      upsertQueryFilters([
        { key: 'page', value: typeof page === 'number' ? toOneBasedIndex(pageFromQuery) : toOneBasedIndex(0) },
        { key: 'size', value: pageSize },
      ]);
    },
    [page, upsertQueryFilters]
  );

  const handleSearch = useCallback(
    (searchText: string) => {
      searchText ? upsertQueryFilters([{ key: 'search', value: searchText }]) : deleteQueryFilters(['search']);
      table?.current?.onQueryChange({
        page: toZeroBasedIndex(page || 1),
        pageSize: size || 10,
        search: searchText,
      });
    },
    [deleteQueryFilters, page, size, table, upsertQueryFilters]
  );

  const handleOrderChange = useCallback(
    (collection: OrderByCollection[]) => {
      const orderBy = columns[collection?.[0]?.orderBy]?.field as FleetDevices.Columns;
      const direction = collection?.[0]?.orderDirection as Direction;

      !orderBy || !direction
        ? deleteQueryFilters(['orderBy', 'direction'])
        : upsertQueryFilters([
            { key: 'orderBy', value: orderBy },
            { key: 'direction', value: direction },
          ]);
    },
    [columns, deleteQueryFilters, upsertQueryFilters]
  );

  const options: Options<FleetDevices.Device> = useMemo(
    () => ({
      idSynonym: 'idSynonim',
      pageSize: size || 10,
      showTitle: false,
      initialPage: page || 0,
      draggable: false,
      searchDebounceDelay: 400,
      searchText: search || '',
      defaultOrderByCollection: [
        { orderBy: columns.findIndex((el) => el.field === orderBy), orderDirection: direction || '', sortOrder: 1 },
      ],
    }),
    [columns, direction, orderBy, page, search, size]
  );

  useEffect(() => {
    if (!page && !size) {
      upsertQueryFilters([
        { key: 'page', value: 1 },
        { key: 'size', value: 10 },
      ]);
    }
  }, [page, size, upsertQueryFilters]);

  return (
    <CulliganTable<FleetDevices.Device>
      tableRef={table}
      title={title}
      isLoading={false}
      data={handleFetchData}
      onSearchChange={handleSearch}
      columns={columns}
      onPageChange={handlePageChange}
      onOrderCollectionChange={handleOrderChange}
      options={options}
    />
  );
}

export default DevicesPanel;
