import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { clamp, wrapInRange } from '@/shared/utils';
import dayjs, { Dayjs } from 'dayjs';
import { DeviceConsumable } from '../../DevicesPanel/typings';
import Area, { AreaBody, AreaHeader, AreaRow } from '@/components/Shared/Card/Area';
import { Body, Caption, Subtitle } from '@/components/Shared/Card/Area/Text';
import CopyChip from '@/components/Shared/Chips/CopyChip';
import TagIcon from '@mui/icons-material/Tag';
import { yellow } from '@mui/material/colors';
import withRenderFragment from '@/components/Shared/withRenderFragment';

const calcExpirationPercentage = (expiration?: Dayjs, lifespan?: number) => {
  if (!expiration || !lifespan) {
    return null;
  }

  const startDate = expiration.subtract(+lifespan, 'D');
  const diffInDaysFromStartDateToToday = lifespan - Math.abs(startDate.diff(dayjs(), 'd'));

  return (diffInDaysFromStartDateToToday * 100) / lifespan;
};

type ConsumableBodyProps = {
  subset: DeviceConsumable['subset'];
  systemConsumable: DeviceConsumable['consumable'];
  expiresAt: DeviceConsumable['expiresAt'];
  fillPercentage: number;
  expirationPercentage: number;
};

function ConsumableBody({
  subset,
  systemConsumable,
  expiresAt,
  fillPercentage,
  expirationPercentage,
}: ConsumableBodyProps) {
  const { t } = useTranslation();
  return (
    <AreaRow sx={{ mb: '0.5rem' }}>
      {(subset?.name || subset?.id) && (
        <Area sx={{ flexGrow: 1 }}>
          <AreaHeader>
            <Subtitle>{t('name')}</Subtitle>
          </AreaHeader>
          <AreaBody>
            <Body>{subset?.name || subset?.id}</Body>
            <Box display="flex">
              <CopyChip icon={<TagIcon />} label={systemConsumable.name || '--'} />
            </Box>
          </AreaBody>
        </Area>
      )}

      {expiresAt && (
        <Area
          {...(expirationPercentage
            ? {
                sx: {
                  position: 'relative',
                  overflow: 'hidden',
                  flexGrow: 1,
                  '&::before': {
                    content: '""',
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    width: `${clamp(0, expirationPercentage || 0, 100)}%`,
                    height: '100%',
                    backgroundColor: (theme) => theme.palette.grey[300],
                    zIndex: 0,
                  },
                },
              }
            : {})}
        >
          <AreaHeader sx={{ zIndex: 1, position: 'relative' }}>
            <Subtitle>{t('expiration')}</Subtitle>
          </AreaHeader>
          <AreaBody sx={{ zIndex: 1, position: 'relative' }}>
            <Body>{expiresAt && typeof expiresAt === 'number' ? dayjs(expiresAt).format('YYYY/MM/DD') : '--'}</Body>
            {expiresAt && typeof expiresAt === 'number' && <Caption>{dayjs(expiresAt).toNow()}</Caption>}
            {expirationPercentage && (
              <Box
                sx={{
                  height: '5px',
                  borderRadius: 2,
                  backgroundImage: (theme) =>
                    `linear-gradient(90deg, ${theme.palette.success.light} 0%, ${yellow[500]} 51.5%,
                 ${theme.palette.warning.light} 75.5%, ${theme.palette.error.light} 100%)`,
                }}
              />
            )}
          </AreaBody>
        </Area>
      )}

      {fillPercentage && !Number.isNaN(fillPercentage) ? (
        <Area
          {...(fillPercentage && !Number.isNaN(fillPercentage)
            ? {
                sx: {
                  position: 'relative',
                  overflow: 'hidden',
                  '&::before': {
                    content: '""',
                    top: 0,
                    left: 0,
                    position: 'absolute',
                    width: `${clamp(0, fillPercentage || 0, 100)}%`,
                    height: '100%',
                    backgroundColor: (theme) => theme.palette.grey[300],
                    zIndex: 0,
                  },
                },
              }
            : {})}
        >
          <AreaHeader sx={{ zIndex: 1, position: 'relative' }}>
            <Subtitle>{t('fill')}</Subtitle>
          </AreaHeader>
          <AreaBody sx={{ zIndex: 1, position: 'relative' }}>
            <Body>{fillPercentage && !Number.isNaN(fillPercentage) ? `${fillPercentage}%` : '--'}</Body>
            {!Number.isNaN(fillPercentage) && (
              <Box
                sx={{
                  height: '5px',
                  borderRadius: 2,
                  backgroundImage: (theme) =>
                    `linear-gradient(90deg, ${theme.palette.error.light} 0%, ${theme.palette.warning.light} 51.5%,
                ${yellow[500]} 75.5%, ${theme.palette.success.light} 100%)`,
                }}
              />
            )}
          </AreaBody>
        </Area>
      ) : (
        <></>
      )}
    </AreaRow>
  );
}

const SafeConsumableBody = withRenderFragment<ConsumableBodyProps>(ConsumableBody);

export default function DeviceConsumableCardOverview({ consumable }: { consumable: DeviceConsumable }) {
  const { consumable: systemConsumable, subset, current, expiresAt } = consumable;
  const expirationPercentage = calcExpirationPercentage(dayjs(expiresAt), subset?.lifespan);

  const fillPercentage =
    typeof current === 'number' && typeof subset?.rangeFullCapacity === 'number'
      ? wrapInRange(current, 0, subset?.rangeFullCapacity)
      : NaN;

  const shouldRenderFragment = !expiresAt && !fillPercentage && !subset?.name && !subset?.id;

  return (
    <SafeConsumableBody
      renderFragment={shouldRenderFragment}
      expirationPercentage={expirationPercentage}
      expiresAt={expiresAt}
      fillPercentage={fillPercentage}
      subset={subset}
      systemConsumable={systemConsumable}
    />
  );
}
