import { Box, styled } from '@mui/material';

const Slot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.grayShades[0],
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export default Slot;
