import { STATUS } from '@/redux/slices/operationSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import BaseOperationAvatar from '../../Operation/BaseOperationAvatar';
import BaseOperationIcon from '../../Operation/BaseOperationIcon';
import BaseOperationIconButton from '../../Operation/BaseOperationIconButton';
import useDefaultErrorCallback from '../../Notification/hooks/useDefaultErrorCallback';
import { StatusNotification } from '../../Notification/BaseNotification';
import BaseSnackbar from '../BaseSnackbar';
import { getDefaultStatusIcon } from '../../Notification/ByTag/utils';
import DeviceTelemetryUpdateMessage from '../../Notification/ByTag/TelemetryUpdate/Message';
import StatusSnackbarActions from '../StatusSnackbarActions';
import { CommonSnackbarProps } from '../typings';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getPath } from '@/shared/utils';

export default function DeviceTelemetryUpdateVariant({ operation, dismissAction, ...rest }: CommonSnackbarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const defaultErrorCallback = useDefaultErrorCallback(operation);
  const path = getPath('FLEET_DEVICE_TELEMETRY', { deviceId: operation.entity });

  return (
    <StatusNotification
      {...operation}
      baseComponent={{
        Component: BaseSnackbar,
        extra: { snackbarActions: <StatusSnackbarActions operation={operation} dismissAction={dismissAction} /> },
      }}
      avatar={
        <BaseOperationAvatar
          status={operation.status}
          tagIcon={<BaseOperationIcon Icon={QueryStatsIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(operation.status)} />}
        />
      }
      hoverIconButtons={undefined}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          message: (
            <DeviceTelemetryUpdateMessage
              read={operation.read}
              status={operation.status}
              entity={operation.entity}
              opening={t('telemetryUpdateRequestError')}
            />
          ),
          onClick: defaultErrorCallback,
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={operation.read}
                status={operation.status}
                icon={<BaseOperationIcon Icon={VisibilityIcon} color="inherit" />}
              />
            ),
          },
        },
        [STATUS.INFO]: {
          message: (
            <DeviceTelemetryUpdateMessage
              read={operation.read}
              status={operation.status}
              entity={operation.entity}
              opening={t('telemetryUpdateRequestPending')}
            />
          ),
          onClick: () => navigate(path),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={operation.read}
                status={operation.status}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
          },
        },
        [STATUS.SUCCESS]: {
          message: (
            <DeviceTelemetryUpdateMessage
              read={operation.read}
              status={operation.status}
              entity={operation.entity}
              opening={t('telemetryUpdateRequestSuccess')}
            />
          ),
          onClick: () => navigate(path),
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                read={operation.read}
                status={operation.status}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
          },
        },
      }}
    />
  );
}
