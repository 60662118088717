import { ConsumablesTable } from '@/components/OneTools/Channels/Consumables';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityContext } from '../DeviceIdentityContext';
import useConsumablesColumns from '@/components/OneTools/Channels/Consumables/useConsumablesColumns';
import { ROUTES } from '@/shared/constants';

export default function Consumables() {
  const { t } = useTranslation();
  const deviceIdentity = useContext(DeviceIdentityContext);
  const consumablesColumns = useConsumablesColumns(deviceIdentity?.consumables);

  return (
    <ConsumablesTable
      title={t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment)}
      data={deviceIdentity?.consumables}
      columns={consumablesColumns}
      isLoading={false}
      isError={false}
      resetStateButtonVisible={false}
      exportData={false}
      selection={false}
    />
  );
}
