import { Box, Typography } from '@mui/material';
import { Fragment, useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CurrentDataSection from './CurrentDataSection';
import HistoryDataSection from './HistoryDataSection';
import { DeviceTelemetries } from './typings';
import Header from '@/components/Shared/Print/Header';
import { parseDeviceTelemetry } from './parseDeviceTelemetry';
import TelemetryProvider from './TelemetryProvider';

export const HISTORYDATASECTION_ID = 'historyTelemetryData';

export default function DeviceTelemetryPanel({ data }: { data?: DeviceTelemetries }) {
  const { t } = useTranslation();
  const { telemetries, telemetriesGroups, telemetriesWithoutGroupTag, mergedTelemetries } = useMemo(
    () => parseDeviceTelemetry(data),
    [data]
  );

  useLayoutEffect(() => {
    const beforePrintListener = () => {
      document.body.id = 'print';
    };
    const afterPrintListener = () => {
      document.body.id = '';
    };

    if (window) {
      window.addEventListener('beforeprint', beforePrintListener);
      window.addEventListener('afterprint', afterPrintListener);
    }

    return () => {
      window.removeEventListener('beforeprint', beforePrintListener);
      window.removeEventListener('afterprint', afterPrintListener);
    };
  }, []);

  return telemetries.length ? (
    <Fragment>
      <Header />
      <TelemetryProvider mergedTelemetries={mergedTelemetries}>
        <CurrentDataSection
          telemetriesWithoutGroupTag={telemetriesWithoutGroupTag}
          telemetriesGroups={telemetriesGroups}
        />
        <HistoryDataSection />
      </TelemetryProvider>
    </Fragment>
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Typography variant="h6">{t('noTelemetryAvailable')}</Typography>
    </Box>
  );
}
