import BaseItemPicker from './BaseItemPicker';
import { Box } from '@mui/material';
import { MergedTelemetry } from '../../../../typings';
import { useTelemetryFiltersCtx, useTelemetryFiltersHandlerCtx } from '../../../../TelemetryProvider';
import { GroupTelemetry } from '../../../../useTelemetryFilters/actions';

const isGroupTelemetryNotEmpty = (groupTelemetry: GroupTelemetry | {}): groupTelemetry is GroupTelemetry =>
  Boolean(Object.entries(groupTelemetry).length);
const isSelectedAtLeastOneTelemetryOfGroup = (groupTelemetry: GroupTelemetry | {}): groupTelemetry is GroupTelemetry =>
  isGroupTelemetryNotEmpty(groupTelemetry) && groupTelemetry.key.length > 0;

export default function GroupItemPicker({ item }: { item: MergedTelemetry }) {
  const GROUP_NAME = item.name!;
  const { telemetries } = useTelemetryFiltersCtx();
  const { handleAddTelemetryFilter, handleRemoveTelemetryFilter } = useTelemetryFiltersHandlerCtx();

  const telemetryGroup: GroupTelemetry | {} = telemetries?.tag?.find(({ tagName }) => tagName === GROUP_NAME) || {};

  const itemsOfTelemetryGroup =
    item.items?.reduce<Array<string>>((acc, { id }) => {
      acc.push(id);
      return acc;
    }, []) || [];

  const isGroupChecked = itemsOfTelemetryGroup.length
    ? itemsOfTelemetryGroup.every((singleTelemetry) =>
        isGroupTelemetryNotEmpty(telemetryGroup) ? telemetryGroup.key.includes(singleTelemetry) : false
      )
    : false;
  const isGroupSelected = isGroupChecked || isSelectedAtLeastOneTelemetryOfGroup(telemetryGroup);

  const handleChangeSingleElement = (isChecked: boolean, item: MergedTelemetry) => {
    if (isChecked) {
      item.id && handleRemoveTelemetryFilter({ tagName: GROUP_NAME, key: item.id });
    } else {
      item.id && handleAddTelemetryFilter({ tagName: GROUP_NAME, key: item.id });
    }
  };

  const handleChangeGroupElement = (isChecked: boolean, item: MergedTelemetry) => {
    if (isChecked) {
      item.id && handleRemoveTelemetryFilter({ tagName: GROUP_NAME, key: [...itemsOfTelemetryGroup] });
    } else {
      item.id && handleAddTelemetryFilter({ tagName: GROUP_NAME, key: [...itemsOfTelemetryGroup] });
    }
  };

  return (
    <BaseItemPicker
      item={item}
      isChecked={isGroupChecked}
      isSelected={isGroupSelected}
      onChangeHandler={handleChangeGroupElement}
    >
      <Box>
        {item.items?.map((telemetry) => {
          const isChecked =
            isGroupChecked ||
            (isSelectedAtLeastOneTelemetryOfGroup(telemetryGroup) && telemetryGroup.key.includes(telemetry.id));
          return (
            <BaseItemPicker
              key={telemetry.id}
              item={telemetry}
              isChecked={isChecked}
              isSelected={isGroupSelected}
              onChangeHandler={handleChangeSingleElement}
            />
          );
        })}
      </Box>
    </BaseItemPicker>
  );
}
