import { Box, Button, Card, Typography } from '@mui/material';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useLazyGetDeviceQuery, useUpdateDeviceLoyaltyProgramMutation } from '@/redux/api/fleet/devicesApiSlice';
import FixedToolbar, { scrolledAmountIsGreaterThan } from '@/components/Shared/Toolbar';
import { CommandResponse, CommandType, useRequestTelemetryUpdateMutation } from '@/redux/api/fleet/telemetryApiSlice';
import AnimatedIcon from '@/components/Shared/AnimatedIcon';
import { useEffect, useLayoutEffect } from 'react';
import { selectPollingById, startPolling } from '@/redux/slices/pollingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
import { OPERATION_STATUS, ROUTES } from '@/shared/constants';
import { STATUS, TAGS, upsertOperation } from '@/redux/slices/operationSlice';
import { ApiState } from '@typings';
import dayjs from 'dayjs';
import { AddCircleOutline } from '@mui/icons-material';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { useLazyGetLoyaltyProgramsQuery } from '@/redux/api/system/loyaltyProgramsApiSlice';
import TagIcon from '@mui/icons-material/Tag';
import { AreaBody } from '@/components/Shared/Card/Area';
import CopyChip from '@/components/Shared/Chips/CopyChip';
import { Chip } from '@mui/material';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import useIsTablet from '@/hooks/useIsTablet';
import { DeviceContext } from './DeviceContext';
import { getPath } from '@/shared/utils';
import useCurrentTab from '@/hooks/useCurrentTab';

const DevicePage = () => {
  const { t } = useTranslation();
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const isTablet = useIsTablet();
  const dispatch = useDispatch();
  const [triggerQuery] = useLazyGetLoyaltyProgramsQuery();
  const _requestTelemetryUpdate = () => {
    requestTelemetryUpdate(deviceId!);
  };

  const [triggerGetDevice, deviceResult] = useLazyGetDeviceQuery();
  const [updateLoyaltyProgram] = useUpdateDeviceLoyaltyProgramMutation();

  const [requestTelemetryUpdate, resultTelemetryUpdate] = useRequestTelemetryUpdateMutation();
  const requestId = useSelector((state) => {
    const mutations = (state as ApiState<CommandResponse<CommandType>>).api.mutations;

    return Object.values({ ...mutations })
      .filter((mutation) => mutation.endpointName === 'requestTelemetryUpdate')
      .sort((a, b) => {
        const timeStampA = (a as any).startedTimeStamp;
        const timeStampB = (b as any).startedTimeStamp;

        return timeStampB - timeStampA;
      })[0]?.requestId;
  });
  const polling = useSelector((state: AppState) => selectPollingById(state, requestId, 'getLatestTelemetryUpdate'));
  const brandName = deviceResult.data?.data?.brandName;
  const metaData = deviceResult.data?.data?.metaData;

  useEffect(() => {
    if (
      !resultTelemetryUpdate.isError &&
      !resultTelemetryUpdate.isLoading &&
      resultTelemetryUpdate.isSuccess &&
      resultTelemetryUpdate.data?.data?.body &&
      !polling?.isPolling
    ) {
      dispatch(
        upsertOperation({
          entity: deviceId!,
          location: ' ',
          operationId: requestId,
          read: false,
          showed: false,
          state: OPERATION_STATUS.PENDING,
          status: STATUS.INFO,
          subject: t('telemetryUpdate'),
          timestamp: dayjs().valueOf(),
          uniqueId: `${requestId}-${STATUS.INFO}`,
          tag: TAGS.DEVICE_TELEMETRY_UPDATE,
        })
      );

      dispatch(
        startPolling({
          endpoint: 'getLatestTelemetryUpdate',
          args: { id: deviceId!, latestUpdate: deviceResult.data?.data?.telemetry.lastUpdate || 0 },
          id: requestId,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, dispatch, resultTelemetryUpdate]);

  useLayoutEffect(() => {
    triggerGetDevice(deviceId!);
  }, [deviceId, triggerGetDevice]);

  useLayoutEffect(() => {
    if (polling?.isPolling === false && polling?.result?.isSuccess) {
      triggerGetDevice(deviceId!, false);
    }
  }, [deviceId, polling?.isPolling, polling?.result?.isSuccess, triggerGetDevice]);

  const tabsConfig = [
    {
      id: ROUTES.FLEET_DEVICE_OVERVIEW.fragment,
      label: t(ROUTES.FLEET_DEVICE_OVERVIEW.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_OVERVIEW', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_ALARMS.fragment,
      label: t(ROUTES.FLEET_DEVICE_ALARMS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_ALARMS', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_ERRORS.fragment,
      label: t(ROUTES.FLEET_DEVICE_ERRORS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_ERRORS', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_EVENTS.fragment,
      label: t(ROUTES.FLEET_DEVICE_EVENTS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_EVENTS', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_CONSUMABLES.fragment,
      label: t(ROUTES.FLEET_DEVICE_CONSUMABLES.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_CONSUMABLES', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_TELEMETRY.fragment,
      label: t(ROUTES.FLEET_DEVICE_TELEMETRY.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_TELEMETRY', { deviceId: deviceId ?? null })),
      isLoading: polling?.isPolling,
    },
    {
      id: ROUTES.FLEET_DEVICE_OTA_JOBS.fragment,
      label: t(ROUTES.FLEET_DEVICE_OTA_JOBS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_OTA_JOBS', { deviceId: deviceId ?? null })),
    },
    {
      id: ROUTES.FLEET_DEVICE_LOYALTY_PROGRAMS.fragment,
      label: t(ROUTES.FLEET_DEVICE_LOYALTY_PROGRAMS.fragment),
      useHash: false,
      onNavigate: () => navigate(getPath('FLEET_DEVICE_LOYALTY_PROGRAMS', { deviceId: deviceId ?? null })),
    },
  ] satisfies TabConfig[];

  const { AddEditDialog, openDialog } = useAddEditDialog({
    title: 'Loyalty Program',
    baseConfig: [
      {
        name: 'loyaltyPrograms',
        type: 'autocomplete',
        selectConfig: {
          multiple: true,
          options: async () => {
            const data = await triggerQuery();
            if (data && data.data?._tag === 'Right' && data.data.right.success) {
              return data.data.right.data.items.map((option) => ({
                label: option.name,
                value: option.id,
              }));
            }
            return [];
          },
        },
      },
    ],
    getEditData: async (deviceSerial) => {
      if (typeof deviceSerial === 'boolean') {
        return;
      }
      const { data } = await triggerGetDevice(deviceSerial);
      if (!data || !data.data) {
        console.error('Error while trying to fetch device data');
        return;
      }
      const item = data.data.loyaltyPrograms?.map((lp) => lp.id);
      return { loyaltyPrograms: item };
    },
    onSubmit: async (_, data: { loyaltyPrograms: string[] }) => {
      if (!deviceId) {
        console.error('Device ID is not available');
        return;
      }

      if (!data) {
        console.error('Invalid data submitted:', data);
        return;
      }

      try {
        const existingPrograms = deviceResult.data?.data?.loyaltyPrograms || [];
        const existingProgramIds = new Set(existingPrograms.map((program) => program.id));

        const programsToAdd = data.loyaltyPrograms.filter((programId) => !existingProgramIds.has(programId));
        const programsToKeep = existingPrograms.filter((program) => data.loyaltyPrograms.includes(program.id));

        const updatedLoyaltyPrograms = [...programsToKeep.map((program) => program.id), ...programsToAdd];

        const payload = { loyaltyPrograms: updatedLoyaltyPrograms };
        updateLoyaltyProgram({ serialNumber: deviceId, ...payload });
      } catch (error) {
        console.error('Unexpected error during loyalty program update:', error);
      }
    },
  });

  const { fragment, index } = useCurrentTab(tabsConfig);

  const toolbarBody = (
    <Box
      display="flex"
      flexDirection={isTablet ? 'column' : 'row'}
      justifyContent={'space-between'}
      alignItems={isTablet ? 'flex-start' : 'center'}
    >
      <Button
        id="backToList"
        variant="text"
        onClick={() => navigate('/fleet/devices')}
        sx={{ mb: isTablet ? '1rem' : 0, '@media print': { display: 'none' } }}
        startIcon={<ArrowCircleLeftIcon />}
      >
        {t('backToList')}
      </Button>
      {fragment === ROUTES.FLEET_DEVICE_TELEMETRY.fragment && (
        <Box display="flex" ml={isTablet ? 0 : '4rem'} gap={1}>
          <Button
            variant="outlined"
            color={polling?.result?.isError ? 'error' : 'primary'}
            disabled={!deviceResult.data?.data?.status.connection.online || polling?.isPolling}
            startIcon={
              <AnimatedIcon icon={<RefreshIcon sx={{ mr: '0.6rem' }} />} trigger={Boolean(polling?.isPolling)} />
            }
            onClick={() => _requestTelemetryUpdate()}
          >
            {t('requestUpdate')}
          </Button>
          <Button
            variant="outlined"
            disabled={false}
            startIcon={<SystemUpdateAltIcon sx={{ mr: '0.6rem' }} />}
            onClick={() => window.print()}
          >
            {t('export')}
          </Button>
        </Box>
      )}
      {fragment === ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment && (
        <Box display="flex" ml={isTablet ? 0 : '4rem'} gap={1}>
          <Button
            variant="outlined"
            color={polling?.result?.isError ? 'error' : 'primary'}
            startIcon={
              <AnimatedIcon icon={<AddCircleOutline sx={{ mr: '0.6rem' }} />} trigger={Boolean(polling?.isPolling)} />
            }
            onClick={() => openDialog(deviceId)}
          >
            {t('manage') + ' ' + t(ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment)}
          </Button>
          <AddEditDialog />
        </Box>
      )}
    </Box>
  );

  return (
    <DeviceContext.Provider
      value={{ data: deviceResult.data?.data, isError: deviceResult.isError, isLoading: deviceResult.isLoading }}
    >
      <FixedToolbar
        event={'scroll'}
        slideInPredicate={scrolledAmountIsGreaterThan(100)}
        body={toolbarBody}
        sx={{ '@media print': { display: 'none' } }}
      />
      <Box>
        <Card
          sx={{
            flex: 1,
            overflowY: 'auto',
            padding: 1,
            '@media print': { display: 'none' },
          }}
          elevation={1}
        >
          {toolbarBody}
        </Card>
        <Box mt={'4rem'} my={'2rem'} sx={{ '@media print': { marginTop: '30mm' }, fontWeight: 'bold' }}>
          <AreaBody
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'start',
              paddingBottom: '0.5rem',
            }}
          >
            {deviceResult.data?.data?.status.connection && (
              <Box
                width={15}
                height={15}
                sx={{
                  backgroundColor: `${deviceResult.data?.data.status.connection.online ? 'success' : 'error'}.light`,
                  borderRadius: '50%',
                  marginRight: '0.5rem',
                }}
              />
            )}
            <Typography
              variant="h4"
              fontWeight="bold"
              id="deviceName"
              sx={{ '@media print': { fontSize: '2rem', fontWeight: '700', marginBottom: 0 } }}
            >
              {deviceResult.data?.data?.name}
            </Typography>
          </AreaBody>
          <AreaBody
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '0.5rem', flexWrap: 'wrap' }}
          >
            <RenderIf condition={deviceResult.isSuccess}>
              <CopyChip
                sx={{
                  color: '#2968fe',
                  backgroundColor: '#e9eff6',
                  border: 'solid 1px',
                  borderColor: '#2968fe',
                }}
                icon={<TagIcon color="primary" />}
                label={deviceResult.data?.data?.serialNumber}
              />
              {brandName === 'quench' &&
                (metaData?.deviceQnumber ? (
                  <CopyChip
                    sx={{ fontWeight: 'bold' }}
                    label={metaData?.deviceQnumber}
                    icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                  />
                ) : (
                  <Chip
                    icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                    label={t('qUnassigned')}
                    size="small"
                  />
                ))}
              {metaData?.manufacturingSerialNumber ? (
                <CopyChip
                  sx={{ fontWeight: 'bold' }}
                  label={metaData?.manufacturingSerialNumber}
                  icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                />
              ) : (
                <Chip
                  icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
                  label={t('manufacturingSerialNumberUnassigned')}
                  size="small"
                />
              )}
            </RenderIf>
          </AreaBody>
        </Box>
        {index !== 0 && <NavigationTabsLayout config={tabsConfig} defaultIndex={index - 1} />}
        <Outlet />
      </Box>
    </DeviceContext.Provider>
  );
};

export default DevicePage;
