import { useCallback } from 'react';
import { useLazyGetFilteredAlarmsQuery } from '@/redux/api/fleet/alarmsApiSlice';
import { useAlarmColumns } from './useAlarmsColumn';
import { Query } from '@material-table/core';
import { FilterAlarmRequest, FleetAlarm } from '../typings';
import { AlarmsFiltersDecoded, AlarmsTableFields } from './useAlarmsFilters';
import { DateRangeDefaultValue, toOneBasedIndex, toZeroBasedIndex } from '@/shared/utils';
import dayjs from 'dayjs';

export const useAlarmData = (
  getQueryFilters: () => AlarmsFiltersDecoded,
  handleOpenDialog: (id: string, alarms: FleetAlarm[]) => void
) => {
  const [getFilteredAlarms, { isLoading, isFetching, data }] = useLazyGetFilteredAlarmsQuery();
  const { start, end, status, severity, customer, brand, model } = getQueryFilters();
  const open = useCallback(
    (id: string) => {
      handleOpenDialog(id, data?.data?.items || []);
    },
    [data?.data?.items, handleOpenDialog]
  );
  const columns = useAlarmColumns({ kind: 'fleet', onDetailClick: open });

  const getPayload = useCallback(
    (query: Query<FleetAlarm>) => {
      const fallbackDateRange = new DateRangeDefaultValue(
        dayjs().subtract(1, 'month').startOf('day'),
        dayjs().endOf('day')
      );
      const sortCollection = query.orderByCollection.length ? query.orderByCollection[0] : null;
      const field = sortCollection ? columns[sortCollection?.orderBy].field : null;
      const direction = (sortCollection?.orderDirection as 'desc' | 'asc') || null;
      const hasSorting = field != null && direction != null;
      const payload: FilterAlarmRequest = {
        start: start || +fallbackDateRange.start,
        end: end || +fallbackDateRange.end,
        model: model,
        status: status,
        severity: severity,
        customer: customer,
        brand: brand,
        page: toOneBasedIndex(query.page),
        size: query.pageSize,
      };

      if (hasSorting && field && direction) {
        payload.orderBy = field as AlarmsTableFields;
        payload.direction = direction as 'asc' | 'desc';
      }

      return payload;
    },
    [brand, columns, customer, end, model, severity, start, status]
  );

  const handleFetchTableData = useCallback(
    (query: Query<FleetAlarm>) =>
      getFilteredAlarms(getPayload(query), true)
        .unwrap()
        .then((result) => {
          const filteredItems = query.search
            ? result?.data?.items.filter((item) =>
                Object.values(item).some((value) => String(value).toLowerCase().includes(query.search.toLowerCase()))
              )
            : result?.data?.items;

          return {
            data: filteredItems || [],
            page: toZeroBasedIndex(result?.data?.page || 0),
            totalCount: result.data?.itemsCount || 0,
          };
        })
        .catch((error) => {
          return error;
        }),
    [getFilteredAlarms, getPayload]
  );

  return {
    handleFetchTableData,
    isLoading,
    isFetching,
    columns,
  };
};
