import { Box, Typography, Divider, ListItem, List, Button, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetFleetQuery } from '@/redux/api/fleet/fleetApiSlice';
import DeviceItem from './DeviceItem';
import { TFunction } from 'i18next';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useContext, useMemo, useState } from 'react';
import { pipe } from 'effect';
import { FLEET_GENERAL_QUERY_WIDTH } from '.';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import LoadingDevicesList from './LoadingDevicesList';
import AreaContainer, { AreaBody } from '@/components/Shared/Card/Area';
import TypographyWithTooltip from '@/components/Shared/Tooltip';
import { FiltersContext } from '../FiltersContext';
import { FleetOverview } from '../typings';
import { sortDevices } from '../utils';
import { useNavigate } from 'react-router';
import { getPath } from '@/shared/utils';
import { ROUTES } from '@/shared/constants';

const renderDevicesList = (
  devices: FleetOverview.DeviceSummary[],
  limitDevices: boolean = true,
  t: TFunction,
  totalDevices: number,
  navigate: ReturnType<typeof useNavigate>,
  handleDialog?: (openDialog: boolean) => void
) => {
  const maxDevices = 8;
  const displayDevices = devices.length < maxDevices ? devices : devices.slice(0, maxDevices - 1);
  const placeholdersCount = limitDevices
    ? Math.max(0, maxDevices - (devices.length < maxDevices ? 0 : 1) - displayDevices.length)
    : 0;
  const showSeeMoreButton = limitDevices && devices.length > maxDevices;

  const parsedDevices = (limitDevices ? displayDevices : devices).map((device, i) => (
    <ListItem
      sx={{ p: 0, height: '70px', transition: 'filter 0.09s ease-in-out', '&:hover': { filter: 'brightness(0.9)' } }}
      key={`device-${device.name}-${i}`}
      onClick={() => navigate(getPath(ROUTES.FLEET_DEVICES_LIST.parent) + `/list?model=${device.identityId}`)}
    >
      <DeviceItem numDevices={device.count} totDevices={totalDevices} name={device.name} model={device.model} />
    </ListItem>
  ));

  const seeMoreButton = showSeeMoreButton ? (
    <ListItem sx={{ p: 0 }} key={'deviceListButton'}>
      <Button
        onClick={() => handleDialog && handleDialog(true)}
        variant="outlined"
        color="primary"
        endIcon={<OpenInFullIcon />}
        fullWidth
        sx={{ height: '100%' }}
      >
        {t('seeXMore', { number: devices.length - maxDevices })}
      </Button>
    </ListItem>
  ) : null;

  const placeholders = Array.from({ length: placeholdersCount }, (_, index) => (
    <ListItem sx={{ p: 0, height: '70px', width: '100%' }} key={`placeholder-${index}`}>
      <DeviceItem numDevices={0} totDevices={totalDevices} name="" model="" sx={{ opacity: 0.5 }} />
    </ListItem>
  ));

  const result = [...parsedDevices, seeMoreButton, ...placeholders].filter(Boolean);

  return result;
};

export default function DevicesList({
  devices = [],
  isDialog = false,
  handleDialog,
}: {
  devices: FleetOverview.DeviceSummary[];
  isDialog: boolean;
  handleDialog: (openDialog: boolean) => void;
}) {
  const { t } = useTranslation();
  const filters = useContext(FiltersContext);
  const { isLoading, isFetching, isError } = useGetFleetQuery(filters);
  const [sortBy, setSortBy] = useState<'name' | 'amount'>('amount');
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const matches = useMediaQuery(`(max-width:${FLEET_GENERAL_QUERY_WIDTH}px`);
  const sortedDevices = useMemo(
    () => pipe(devices, (ds) => sortDevices(ds, sortBy, sortAsc)),
    [devices, sortBy, sortAsc]
  );
  const navigate = useNavigate();
  const totalDevices = useMemo(() => devices.reduce((prev: number, next: any) => prev + next.count, 0), [devices]);
  const theme = useTheme();

  const SafeDevicesList = withErrorLoadingManagement(() => (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        gap: 1,
        alignItems: 'stretch',
        flexWrap: 'wrap',
        padding: isDialog ? 2 : 0,
      }}
    >
      <Box
        display="flex"
        gap={theme.spacing(1)}
        flexDirection={isDialog || matches || !(sortedDevices?.length > 0) ? 'row' : 'column'}
        {...(isDialog || matches || !(sortedDevices?.length > 0) ? { sx: { width: '100%' } } : {})}
      >
        <AreaContainer>
          <AreaBody>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              sx={{
                borderRadius: 1.5,
                backgroundColor: theme.palette.background.grayShades[0],
              }}
            >
              <TypographyWithTooltip
                anchorText={t('totalNumberOfDevices')}
                slots={{
                  AnchorTextTypography: ({ children }) => (
                    <Typography variant="body1" color="text.secondary">
                      {children}
                    </Typography>
                  ),
                }}
                tooltipText={t('totalNumberOfDevicesTooltip')}
              />

              <Typography variant="h3" fontWeight={800} sx={{ alignSelf: 'flex-end' }}>
                {totalDevices}
              </Typography>
            </Box>
          </AreaBody>
        </AreaContainer>

        <AreaContainer>
          <AreaBody>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              sx={{
                backgroundColor: theme.palette.background.grayShades[0],
                borderRadius: 1.5,
                justifyContent: 'space-between',
                color: theme.palette.text.primary,
              }}
            >
              <Typography variant="body1" color="text.secondary">
                {t('sortBy')}
              </Typography>
              <Box display="flex" gap={theme.spacing(1)} alignSelf={'flex-end'}>
                <Button
                  color={sortBy === 'name' ? 'primary' : 'inherit'}
                  variant="outlined"
                  endIcon={sortAsc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                  size="small"
                  disabled={devices.length <= 1}
                  onClick={() => {
                    setSortBy('name');
                    setSortAsc((p) => !p);
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{ mr: theme.spacing(1.5) }}
                    fontSize={theme.typography.button.fontSize}
                  >
                    {t('name')}
                  </Typography>
                  <Divider orientation="vertical" />
                </Button>
                <Button
                  color={sortBy === 'amount' ? 'primary' : 'inherit'}
                  variant="outlined"
                  endIcon={sortAsc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                  size="small"
                  disabled={devices.length <= 1}
                  onClick={() => {
                    setSortBy('amount');
                    setSortAsc((p) => !p);
                  }}
                >
                  <Typography
                    variant="button"
                    sx={{ mr: theme.spacing(1.5) }}
                    fontSize={theme.typography.button.fontSize}
                  >
                    {t('amount')}
                  </Typography>
                  <Divider orientation="vertical" />
                </Button>
              </Box>
            </Box>
          </AreaBody>
        </AreaContainer>
      </Box>

      <List
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: `repeat(${isDialog ? Math.ceil(sortedDevices.length / 2) : 4}, 1fr)`,
          gridAutoFlow: 'column',
          gap: theme.spacing(1),
          alignItems: 'stretch',
          flexGrow: 2,
          py: 0,
          color: theme.palette.text.primary,
        }}
      >
        {renderDevicesList(sortedDevices, !isDialog, t, totalDevices, navigate, handleDialog)}
      </List>
    </Box>
  ));

  return (
    <SafeDevicesList isError={isError} isLoading={isLoading || isFetching} LoadingComponent={<LoadingDevicesList />} />
  );
}
