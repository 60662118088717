import OneToolCUDVariant from './OneToolCUD';
import { ROUTES } from '@/shared/constants';
import { CommonSnackbarProps } from '../typings';
import { getPath } from '@/shared/utils';

export default function OneToolVariant({ operation, dismissAction }: CommonSnackbarProps) {
  switch (operation.entity) {
    case ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_ECOSYSTEM_BRANDS')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_ECOSYSTEM_OTA.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_ECOSYSTEM_OTA')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_TELEMETRY')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_EVENTS.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_EVENTS')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_ALARMS.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_ALARMS')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_COMMANDS')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_CONSUMABLES')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_CONSUMABLE_SUBSETS')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_REACTIONS')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_CONFIGS')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    case ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment:
      return (
        <OneToolCUDVariant
          path={getPath('ONETOOL_CHANNELS_LOYALTY_PROGRAMS')}
          operation={operation}
          dismissAction={dismissAction}
        />
      );

    default:
      return <></>;
  }
}
