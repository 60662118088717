import { ApiResult } from '@typings';
import { BrandReq, BrandRes } from '@/components/OneTools/Ecosystem/Brands/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';
import { dispatchPendingToOperationsSlice } from '../dispatchPendingToOperations';
import { ROUTES } from '@/shared/constants';

const URL_PREFIX = `${API_VERSION.v2}/system/brands`;

export const BrandsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Brands'] }).injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query<ApiResult<{ items: BrandRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Brands'],
    }),
    getBrand: builder.query<ApiResult<BrandRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Brands'],
    }),
    isBrandUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, Required<Pick<CommonIsEntityUniqueBase, 'name'>>>({
      query: (params: Required<Pick<CommonIsEntityUniqueBase, 'name'>>) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
    postBrand: builder.mutation<ApiResult<BrandRes>, BrandReq>({
      query: (body: BrandReq) => ({
        url: URL_PREFIX,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Brands'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'POST',
          subject: arg.name,
          entity: ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment,
        });
      },
    }),
    putBrand: builder.mutation<ApiResult<BrandRes>, { id: string; body: BrandReq }>({
      query: ({ id, body }: { id: string; body: BrandReq }) => ({
        url: URL_PREFIX,
        method: 'PUT',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Brands'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PUT',
          subject: arg.body.name,
          entity: ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment,
        });
      },
    }),
    patchBrand: builder.mutation<ApiResult<BrandRes>, { id: string; body: Partial<BrandReq>; name: string }>({
      query: ({ id, body }) => ({
        url: URL_PREFIX,
        method: 'PATCH',
        body: body,
        params: { id },
      }),
      invalidatesTags: ['Brands'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'PATCH',
          subject: arg.name,
          entity: ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment,
        });
      },
    }),
    deleteBrand: builder.mutation<ApiResult<null>, { id: string; name: string }>({
      query: (arg: { id: string; name: string }) => {
        return {
          url: URL_PREFIX,
          method: 'DELETE',
          params: { id: arg.id },
        };
      },
      invalidatesTags: ['Brands'],
      onQueryStarted: (arg, api) => {
        dispatchPendingToOperationsSlice(api, {
          url_prefix: URL_PREFIX,
          method: 'DELETE',
          subject: arg.name,
          entity: ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment,
        });
      },
    }),
    uploadLogo: builder.mutation<{ success: true; error: null }, FormData>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const mime = _arg.get('mime') as string;
        const ext = mime.split('/')[1];
        const signedURLResponse = await fetchWithBQ({
          url: URL_PREFIX + '/logos/uploadURL',
          params: {
            id: _arg.get('id'),
            ext,
          },
        });
        if (signedURLResponse.error) return { error: signedURLResponse.error as FetchBaseQueryError };
        let signedURL;
        if (signedURLResponse.data) {
          signedURL = (signedURLResponse.data as ApiResult<{ url: string }>).data?.url;
        }
        const result = await fetchWithBQ({
          url: signedURL || '',
          method: 'PUT',
          headers: {
            'Content-Type': mime,
            'x-ms-blob-type': 'BlockBlob', // mandatory for file upload in Azure
          },
          body: _arg.get('logo'),
        });
        return result.meta?.response?.status !== 200
          ? { data: { success: true, error: null } }
          : { error: result.error as FetchBaseQueryError };
      },
      invalidatesTags: ['Brands'],
    }),
  }),
});

export const {
  useGetBrandQuery,
  useLazyGetBrandsQuery,
  useGetBrandsQuery,
  useLazyGetBrandQuery,
  useLazyIsBrandUniqueQuery,
  usePostBrandMutation,
  usePutBrandMutation,
  usePatchBrandMutation,
  useDeleteBrandMutation,
  useUploadLogoMutation,
} = BrandsApiSlice;
