import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyGetFilteredTelemetryQuery } from '@/redux/api/fleet/telemetryApiSlice';
import { HISTORYDATASECTION_ID } from '..';
import { useParams } from 'react-router';
import { useTelemetryFiltersCtx, useDeviceTelemetryCtx } from '../TelemetryProvider';
import { extractTelemetryFilters } from './helpers/extractTelemetryFilters';
import { groupItemsByDay, removeBooleansAndStringsTelemetry } from './helpers/parseApiResponse';

import Section from '@/components/Shared/Section';
import { Box, Typography } from '@mui/material';
import { TelemetryDataTable } from './Table';
import PrintItem from './PrintItem';
import Filters from './Filters';
import TelemetryChart from './Chart';
import withRenderFragment from '@/components/Shared/withRenderFragment';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';

const SafeTelemetryChart = withErrorLoadingManagement(withRenderFragment(TelemetryChart));

export default function HistoryDataSection() {
  const { t } = useTranslation();
  const { deviceId } = useParams();
  const { telemetries: deviceTelemetries } = useDeviceTelemetryCtx();
  const { end, start, telemetries: telemetryFilters } = useTelemetryFiltersCtx();

  const [trigger, { isLoading, isFetching, isError, data }] = useLazyGetFilteredTelemetryQuery();

  useEffect(() => {
    if (deviceId && telemetryFilters && end && start) {
      trigger(
        {
          dateRangeEnd: end,
          dateRangeStart: start,
          deviceId,
          keys: extractTelemetryFilters(telemetryFilters.key, telemetryFilters.tag),
        },
        true
      );
    }
  }, [deviceId, telemetryFilters, end, start, trigger]);

  const filteredData = useMemo(() => removeBooleansAndStringsTelemetry(data), [data]);
  const itemsGroupedByDay = useMemo(() => groupItemsByDay(deviceTelemetries, data), [deviceTelemetries, data]);

  return (
    <Section
      variant="color"
      sx={{
        position: 'relative',
        '@media print': { outline: '#fff', backgroundColor: (theme) => theme.palette.background.paper, margin: 0 },
      }}
    >
      <Typography
        variant="h5"
        mb={4}
        sx={{ '@media print': { fontSize: '1.2rem', fontWeight: '700', marginBottom: '5px' } }}
      >
        {t('historicalData')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Filters />
        <Box id={HISTORYDATASECTION_ID} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {filteredData && filteredData?.length > 0 && (
            <SafeTelemetryChart
              isError={isError}
              isLoading={isLoading || isFetching}
              data={filteredData}
              renderFragment={(filteredData?.length || 0) < 2}
            />
          )}
          {!isError ? (
            <>
              <TelemetryDataTable
                telemetryFilters={telemetryFilters}
                telemetries={deviceTelemetries}
                data={data}
                status={{ isLoading, isFetching }}
              />
              <Box sx={{ display: 'none', '@media print': { display: 'block', marginTop: '-10mm' } }}>
                {itemsGroupedByDay.map((item, index) => {
                  return <PrintItem key={item[0]} item={item} index={index} />;
                })}
              </Box>
            </>
          ) : (
            <Typography>{t('error')}</Typography>
          )}
        </Box>
      </Box>
    </Section>
  );
}
