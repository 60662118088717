import { Box, IconButton, IconButtonProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { WithSlots } from '@typings';
import { useMemo } from 'react';
import { InfoPanelHeaderProps } from '../InfoPanelHeader';
import { Caption, Subtitle } from '../../Card/Area/Text';

export default function InfoPanelHeader<TData>({
  title,
  subtitle,
  onClose,
  slots,
}: WithSlots<
  InfoPanelHeaderProps<TData> & { title: string; subtitle: string },
  {
    CloseButton: (props: IconButtonProps) => ReturnType<typeof IconButton>;
  }
>) {
  const CLOSE_BUTTON = useMemo(() => slots?.CloseButton ?? IconButton, [slots?.CloseButton]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, alignItems: 'center' }}>
      <Box>
        <Subtitle>{title}</Subtitle>
        <Caption>{subtitle}</Caption>
      </Box>
      <CLOSE_BUTTON onClick={onClose} size="small">
        <CloseIcon />
      </CLOSE_BUTTON>
    </Box>
  );
}
