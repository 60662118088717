import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import {
  AutocompleteFilterProps,
  LocalFilterOption,
  StoreAutocompleteFilterConfig,
  StoreFilterOption,
  StoreOnFilterChange,
} from './typings';
import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';

const AutocompleteFilter = <T,>({ config, kind, onFilterChange, ...props }: AutocompleteFilterProps<T>) => {
  const getOptionLabel = useCallback((o: object & { label: string }) => o.label, []);
  const isOptionEqualToValue = useCallback(
    (o: object & { optionId: string }, v: object & { optionId: string }) => o.optionId === v.optionId,
    []
  );
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => <TextField {...params} label={config.label} />,
    [config.label]
  );
  const handleFilterChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: LocalFilterOption<T> | StoreFilterOption | null) => {
      if (kind === 'local') {
        onFilterChange(config.id, value as LocalFilterOption<T> | null);
      } else {
        (onFilterChange as StoreOnFilterChange)(config.id, value as StoreFilterOption | null);
      }
    },
    [config.id, kind, onFilterChange]
  );
  const sx = useMemo(() => ({ props }), [props]);

  useEffect(() => {
    if (kind === 'local') {
      return;
    }

    if (!config.defaultValue) {
      return;
    }
    (onFilterChange as StoreOnFilterChange)(config.id, config.defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(config as StoreAutocompleteFilterConfig).defaultValue]);

  return kind === 'local' ? (
    <Autocomplete<LocalFilterOption<T>>
      disablePortal
      key={config.id}
      id={config.id}
      options={config.options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={renderInput}
      onChange={handleFilterChange}
      disabled={config?.disabled}
      readOnly={config?.readOnly}
      style={config?.style}
      sx={sx}
    />
  ) : (
    <Autocomplete<StoreFilterOption>
      disablePortal
      id={config.id}
      {...(config.defaultValue
        ? { defaultValue: config.defaultValue, key: config.id + config.defaultValue?.optionId }
        : { key: config.id })}
      key={config.id}
      options={config.options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={renderInput}
      onChange={handleFilterChange}
      disabled={config?.disabled}
      readOnly={config?.readOnly}
      style={config?.style}
      sx={sx}
    />
  );
};

export const autocompleteFilterDefaultAssert = <T,>(param: T, value: T) => param === value;

export default AutocompleteFilter;
