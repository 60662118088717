import { mixStringDeterministic, repeatCharacters, stringToColor } from '@/shared/utils';
import { pipe } from 'effect';
import { TelemetryFilters } from '../../../../typings';
import { Colors } from '../typings';
import { getFiltersArray } from '../../helpers/getFiltersArray';

const toColor = (id: string, times?: number) =>
  pipe(repeatCharacters(id, times ? times : 4), mixStringDeterministic, stringToColor);

export const getColor = (telemetryFilters?: TelemetryFilters) => {
  if (!telemetryFilters) {
    return {};
  }
  const filters = getFiltersArray(telemetryFilters);

  return filters.reduce((acc, tf) => {
    if (typeof tf === 'string') {
      acc[tf] = toColor(tf);
      return acc;
    }

    if (tf?.tagName && tf?.key?.length) {
      acc[tf.tagName] = tf.key.reduce((acc, tf) => {
        acc[tf] = toColor(tf);
        return acc;
      }, {} as { [k: string]: string });
      return acc;
    }

    return acc;
  }, {} as Colors);
};
