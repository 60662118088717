import { Box } from '@mui/material';
import StaticDateRangeWithFixedRanges from './StaticDateRangeWithFixedRanges';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useTelemetryFiltersCtx, useTelemetryFiltersHandlerCtx } from '../../../../TelemetryProvider';

export default function CalendarPicker() {
  const { start, end } = useTelemetryFiltersCtx();
  const { handleChangeDateRange } = useTelemetryFiltersHandlerCtx();

  const handleDateRangeChange = useCallback(
    (_: any, range: { start: number | null; end: number | null }) => {
      if (range.start === null || range.end === null) {
        console.warn('Invalid date range received:', range);
        return;
      }

      const today = dayjs().endOf('day').valueOf();
      const validEndDate = Math.min(range.end, today);

      const validStartDate = Math.min(range.start, validEndDate);

      handleChangeDateRange({ end: validEndDate, start: validStartDate });
    },
    [handleChangeDateRange]
  );

  return (
    <Box>
      <StaticDateRangeWithFixedRanges
        defaultValue={{
          start: start,
          end: end,
        }}
        onChange={handleDateRangeChange}
      />
    </Box>
  );
}
