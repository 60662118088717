import { LoaderFunctionArgs, redirect } from 'react-router';
import ScrollTopOutlet from '../core/ScrollTopOutlet';
import Ecosystem from '../pages/OneTools/Ecosystem';
import { ROLES, ROUTES } from '@/shared/constants';
import ErrorBoundary from '../core/ErrorBoundary';
import { getPath, getPermissionCheckLoader } from '@/shared/utils';
import Channels from '../pages/OneTools/Channels';
import DeviceIdentity from '../pages/OneTools/DeviceIdentity';
import AdminPanelSettingsTwoTone from '@mui/icons-material/AdminPanelSettingsTwoTone';
import DeviceIdentities from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentitiesPanel';
import Brands from '@/components/OneTools/Ecosystem/Brands';
import OTA from '../../components/OneTools/Ecosystem/OTA/index';
import Telemetry from '../pages/OneTools/Telemetry';
import Events from '../pages/OneTools/Events';
import Alarms from '../pages/OneTools/Alarms';
import Commands from '../pages/OneTools/Commands';
import ConsumableSubsets from '../pages/OneTools/ConsumableSubsets';
import Reactions from '../pages/OneTools/Reactions';
import Configs from '../pages/OneTools/Configs';
import LoyaltyPrograms from '../pages/OneTools/LoyaltyPrograms';
import Consumables from '../pages/OneTools/Consumables';
// eslint-disable-next-line max-len
import DeviceIdentityTelemetry from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentityPanel/Channels/Telemetry';
import DeviceIdentityEvents from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentityPanel/Channels/Events';
import DeviceIdentityAlarms from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentityPanel/Channels/Alarms';
// eslint-disable-next-line max-len
import DeviceIdentityCommands from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentityPanel/Channels/Commands';
// eslint-disable-next-line max-len
import DeviceIdentityConfigs from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentityPanel/Channels/Configs';
// eslint-disable-next-line max-len
import DeviceIdentityConsumables from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentityPanel/Channels/Consumables';

const rolesAllowed = [ROLES.SYS_ADMIN];

const OneToolsRoutes = {
  path: ROUTES.ONETOOL.fragment,
  label: 'One Tool',
  element: <ScrollTopOutlet />,
  icon: <AdminPanelSettingsTwoTone />,
  errorElement: <ErrorBoundary />,
  rolesAllowed,
  loader: getPermissionCheckLoader(rolesAllowed),
  children: [
    {
      index: true,
      loader: () => redirect(getPath('ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES')),
    },
    {
      path: ROUTES.ONETOOL_ECOSYSTEM.fragment,
      label: 'Ecosystem',
      element: <Ecosystem />,
      children: [
        {
          index: true,
          loader: () => redirect(getPath('ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES')),
        },
        {
          path: ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES.fragment,
          children: [
            {
              index: true,
              element: <DeviceIdentities />,
            },
            {
              path: ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITY.fragment,
              element: <DeviceIdentity />,
              children: [
                {
                  index: true,
                  loader: ({ params }: LoaderFunctionArgs) =>
                    redirect(
                      getPath('ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_TELEMETRY', {
                        deviceIdentityId: params.deviceIdentityId ?? null,
                      })
                    ),
                },
                {
                  path: `${ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_TELEMETRY.fragment}`,
                  element: <DeviceIdentityTelemetry />,
                },
                {
                  path: `${ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_EVENTS.fragment}`,
                  element: <DeviceIdentityEvents />,
                },
                {
                  path: `${ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ALARMS.fragment}`,
                  element: <DeviceIdentityAlarms />,
                },
                {
                  path: `${ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_COMMANDS.fragment}`,
                  element: <DeviceIdentityCommands />,
                },
                {
                  path: `${ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONFIGS.fragment}`,
                  element: <DeviceIdentityConfigs />,
                },
                {
                  path: `${ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONSUMABLES.fragment}`,
                  element: <DeviceIdentityConsumables />,
                },
              ],
            },
          ],
        },

        {
          path: ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment,
          element: <Brands />,
        },
        {
          path: ROUTES.ONETOOL_ECOSYSTEM_OTA.fragment,
          element: <OTA />,
        },
      ],
    },
    {
      path: ROUTES.ONETOOL_CHANNELS.fragment,
      element: <Channels />,
      label: 'Channels',
      children: [
        {
          index: true,
          loader: () => redirect(getPath('ONETOOL_CHANNELS_TELEMETRY')),
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment,
          element: <Telemetry />,
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
          element: <Events />,
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_ALARMS.fragment,
          element: <Alarms />,
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment,
          element: <Commands />,
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment,
          element: <Consumables />,
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment,
          element: <ConsumableSubsets />,
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment,
          element: <Reactions />,
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment,
          element: <Configs />,
        },
        {
          path: ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment,
          element: <LoyaltyPrograms />,
        },
      ],
    },
  ],
};

export default OneToolsRoutes;
