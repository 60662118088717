import { Chip, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RelatedEntityLabelProps } from './typings';
import { OpenInNew } from '@mui/icons-material';

function RelatedEntityLabel({
  text,
  onEntityClick,
  notFoundLabel,
  notFoundExplanation,
  severity = 'error',
  size,
}: RelatedEntityLabelProps) {
  const { t } = useTranslation();

  const [defaultLabel, defaultExplanation] =
    severity === 'error'
      ? [t('subEntityNotFoundErrorLabel'), t('subEntityNotFoundErrorExplanation')]
      : [t('subEntityNotFoundWarningLabel'), t('subEntityNotFoundWarningExplanation')];

  const theme = useTheme();

  return text ? (
    <Chip
      label={text}
      color="default"
      sx={{
        border: '1px solid',
        borderRadius: 1,
        cursor: 'pointer',
        mb: 1,
        mr: 1,
        borderColor: theme.palette.background.grayShades[2],
        backgroundColor: theme.palette.background.grayShades[0],
      }}
      icon={<OpenInNew sx={{ width: 20 }} />}
      onClick={onEntityClick}
      {...(size ? { size } : {})}
    />
  ) : (
    <Tooltip title={notFoundExplanation || defaultExplanation} placement="top" arrow>
      <Chip
        color={severity}
        sx={{
          borderRadius: 1,
          mb: 1,
          mr: 1,
        }}
        label={notFoundLabel || defaultLabel}
      />
    </Tooltip>
  );
}

export default RelatedEntityLabel;
