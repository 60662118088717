import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { ConfigContextData, ParsedConfig, ParsedLiteralFragment, ParsedNodeFragment } from './typings';
import { handleCurrent, isParsedLiteralFragment } from './utils';
import { JSXElementConstructor, useCallback, useContext, useMemo, useRef } from 'react';
import ConfigContext from './ConfigContext';

function isMouseInNodeOnly(fragment: ParsedConfig): boolean {
  return fragment.reduce<boolean>((acc, node) => {
    if (isParsedLiteralFragment(node)) {
      return Boolean(Number(acc) * Number(node.current));
    }

    return Boolean(Number(acc) * Number(node.current) * Number(isMouseInNodeOnly(node.items)));
  }, true);
}

const LiteralFragment = ({
  slots,
  fragment,
  literalMouseOverCallback,
  literalMouseOutCallback,
  sx,
}: {
  sx: SxProps<Theme>;
  slots: {
    Container: JSXElementConstructor<BoxProps>;
  };
  fragment: ParsedLiteralFragment;
  literalMouseOverCallback: (fragment: ParsedLiteralFragment) => void;
  literalMouseOutCallback: () => void;
}) => {
  const onMouseOver = useCallback(() => literalMouseOverCallback(fragment), [fragment, literalMouseOverCallback]);
  return (
    <slots.Container key={fragment.id} onMouseOver={onMouseOver} onMouseOut={literalMouseOutCallback}>
      {fragment.value}
    </slots.Container>
  );
};

function withConfigContext({ data, setData }: ConfigContextData) {
  return function ReduceParsedNodeFragment({ id, items }: ParsedNodeFragment) {
    const childOvered = useRef<boolean>(false);
    const currentNode = data?.filter((fragment) => fragment.id === id) ?? [];
    const _isMouseInNodeOnly = data && isMouseInNodeOnly(currentNode);

    const nodeMouseOverCallback = useCallback(
      () => !childOvered.current && setData(data?.map((f) => handleCurrent(f, id, ...items.map(({ id }) => id)))),
      [id, items]
    );
    const nodeMouseOutCallback = useCallback(() => () => setData(data?.map((f) => handleCurrent(f))), []);
    const literalMouseOverCallback = useCallback((fragment: ParsedLiteralFragment) => {
      childOvered.current = true;
      setData(
        data?.map((f) => {
          return handleCurrent(f, fragment.id);
        })
      );
    }, []);
    const literalMouseOutCallback = useCallback(() => () => setData(data?.map((f) => handleCurrent(f))), []);

    return (
      <Box
        key={id}
        sx={{
          display: 'flex',
          backgroundColor: (theme) => theme.palette.background.grayShades[2],
          p: 1,
          borderRadius: 0.5,
          cursor: 'pointer',
          transition: 'all .1s ease-in',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.primary.light,
            color: (theme) => theme.palette.primary.contrastText,
          },
          '&:hover > *:not(:hover)': {
            opacity: Number(_isMouseInNodeOnly) || 0.5,
          },
        }}
        onMouseOver={nodeMouseOverCallback}
        onMouseOut={nodeMouseOutCallback}
      >
        {items.map((fragment) => {
          if (isParsedLiteralFragment(fragment)) {
            return (
              <LiteralFragment
                slots={{ Container: Box }}
                sx={{
                  transition: 'all .1s ease-in',
                }}
                key={fragment.id}
                fragment={fragment}
                literalMouseOverCallback={literalMouseOverCallback}
                literalMouseOutCallback={literalMouseOutCallback}
              />
            );
          }
          return ReduceParsedNodeFragment(fragment);
        })}
      </Box>
    );
  };
}

const DIVIDER = '-';

export default function SmartStringifiedConfiguration() {
  const { data, setData } = useContext(ConfigContext);
  const reduceParsedNodeFragment = useCallback(() => withConfigContext({ data, setData }), [data, setData]);
  const dataWithDividers = useMemo(
    () =>
      data?.reduce<Array<ParsedLiteralFragment | ParsedNodeFragment | typeof DIVIDER>>((acc, fragment, i) => {
        if (i === 0) {
          return [...acc, fragment];
        }

        return [...acc, DIVIDER, fragment];
      }, []),
    [data]
  );

  const literalMouseOverCallback = useCallback(
    (fragment: ParsedLiteralFragment) => {
      setData(
        data?.map((f) => {
          return handleCurrent(f, fragment.id);
        })
      );
    },
    [data, setData]
  );
  const literalMouseOutCallback = useCallback(() => () => setData(data?.map((f) => handleCurrent(f))), [data, setData]);

  if (!dataWithDividers) {
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          borderRadius: 2,
          overflow: 'hidden',
          gap: 0,
          alignItems: 'center',
          width: 'fit-content',
          backgroundColor: (theme) => theme.palette.background.grayShades[1],
        }}
      >
        {dataWithDividers.map((fragment, index) => {
          if (fragment === DIVIDER) {
            return (
              <Box key={`divider-${index}`} sx={{ p: 1, borderRadius: 0.5 }}>
                {DIVIDER}
              </Box>
            );
          }

          if (isParsedLiteralFragment(fragment)) {
            return (
              <LiteralFragment
                slots={{ Container: Box }}
                sx={{
                  backgroundColor: (theme) => theme.palette.background.grayShades[2],
                  p: 1,
                  borderRadius: 0.5,
                  cursor: 'pointer',
                  transition: 'all .1s ease-in',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.primary.light,
                    color: (theme) => theme.palette.primary.contrastText,
                  },
                }}
                key={fragment.id}
                fragment={fragment}
                literalMouseOverCallback={literalMouseOverCallback}
                literalMouseOutCallback={literalMouseOutCallback}
              />
            );
          }

          return reduceParsedNodeFragment()(fragment);
        })}
      </Box>
    </Box>
  );
}
