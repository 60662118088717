import { MergedTelemetry } from '../../../../typings';
import BaseItemPicker from './BaseItemPicker';
import { useTelemetryFiltersCtx, useTelemetryFiltersHandlerCtx } from '../../../../TelemetryProvider';

export default function SingleItemPicker({ item }: { item: MergedTelemetry }) {
  const { telemetries } = useTelemetryFiltersCtx();
  const { handleAddTelemetryFilter, handleRemoveTelemetryFilter } = useTelemetryFiltersHandlerCtx();

  const isChecked = telemetries?.key?.some((telemetry_id) => telemetry_id === item.id) || false;

  const handleChange = (isChecked: boolean, item: MergedTelemetry) => {
    if (isChecked) {
      item.id && handleRemoveTelemetryFilter(item.id);
    } else {
      item.id && handleAddTelemetryFilter(item.id);
    }
  };

  return <BaseItemPicker item={item} isChecked={isChecked} isSelected={isChecked} onChangeHandler={handleChange} />;
}
