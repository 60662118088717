import { useGetCommandsQuery } from '@/redux/api/system/commandsApiSlice';
import { useTranslation } from 'react-i18next';
import { CommandRes } from './typings';
import { ROUTES } from '@/shared/constants';
import { useCommandsColumns } from './useCommandsColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';

export const CommandsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<CommandRes>));

export default function CommandsPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetCommandsQuery();
  const columns = useCommandsColumns();

  return (
    <CommandsTable
      title={t(ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
      exportData={true}
      selection={true}
    />
  );
}
