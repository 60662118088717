import { STATUS, Tag, TAGS } from '@/redux/slices/operationSlice';
import BaseNotification, { StatusNotification } from '../../BaseNotification';
import BaseOperationAvatar from '../../../Operation/BaseOperationAvatar';
import OpacityIcon from '@mui/icons-material/Opacity';
import { getDefaultStatusIcon } from '../utils';
import BaseOperationIconButton from '../../../Operation/BaseOperationIconButton';
import DefaultNotificationRightAction from '../../DefaultNotificationRightAction';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BaseOperationIcon from '../../../Operation/BaseOperationIcon';
import { useTranslation } from 'react-i18next';
import useDefaultErrorCallback from '../../hooks/useDefaultErrorCallback';
import { useNavigate } from 'react-router';
import DeviceCommandMessage from './Message';
import { useNotificationHeight } from '../../hooks/useNotificationHeight';
import { Operations } from '../../../Operation/types';
import { NotificationVirtualizationProps } from '../../types';
import React, { useCallback, useMemo } from 'react';
import { getPath } from '@/shared/utils';

type DeviceCommandTag = Extract<Tag, typeof TAGS.DEVICE_COMMAND_SEND>;
type DeviceCommandNotificationProps = Operations.Props & { tag: DeviceCommandTag } & NotificationVirtualizationProps;

function DeviceCommand(props: DeviceCommandNotificationProps) {
  const { setNotificationHeight, status, read, uniqueId, entity, subject } = props;
  const notificationRef = useNotificationHeight({ id: uniqueId, setNotificationHeight });
  const { t } = useTranslation();
  const defaultErrorCallback = useDefaultErrorCallback({ uniqueId });
  const navigate = useNavigate();
  const path = getPath('FLEET_DEVICE', { deviceId: entity });

  const handleClick = useCallback(() => {
    navigate(path, {
      state: {
        searchText: subject,
      },
    });
  }, [navigate, path, subject]);
  const message = useMemo(
    () => (
      <DeviceCommandMessage
        read={read}
        status={status}
        opening={t('deviceCommandSuccess')}
        entity={t(entity)}
        subject={subject}
      />
    ),
    [read, status, t, entity, subject]
  );
  const mainIconButton = useMemo(
    () => (
      <BaseOperationIconButton
        status={status}
        read={read}
        icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
      />
    ),
    [status, read]
  );
  const rightAction = useMemo(
    () => <DefaultNotificationRightAction status={status} read={read} uniqueId={uniqueId} />,
    [status, read, uniqueId]
  );

  return (
    <StatusNotification
      {...props}
      ref={notificationRef}
      baseComponent={{ Component: BaseNotification }}
      avatar={
        <BaseOperationAvatar
          status={status}
          tagIcon={<BaseOperationIcon Icon={OpacityIcon} />}
          statusIcon={<BaseOperationIcon Icon={getDefaultStatusIcon(status)} />}
        />
      }
      hoverIconButtons={undefined}
      states={{
        [STATUS.ERROR]: {
          label: t('whatHappened?'),
          message: message,
          onClick: defaultErrorCallback,
          hoverIconButtons: {
            main: mainIconButton,
            rightAction: rightAction,
          },
        },
        [STATUS.INFO]: {
          message: (
            <DeviceCommandMessage
              read={read}
              status={status}
              opening={t('deviceCommandPending')}
              subject={subject}
              entity={t(entity)}
            />
          ),
          onClick: () => navigate(path),
          hoverIconButtons: {
            main: mainIconButton,
            rightAction: rightAction,
          },
        },
        [STATUS.SUCCESS]: {
          message: (
            <DeviceCommandMessage
              read={read}
              status={status}
              opening={t('deviceCommandSuccess')}
              entity={t(entity)}
              subject={subject}
            />
          ),
          onClick: handleClick,
          hoverIconButtons: {
            main: (
              <BaseOperationIconButton
                status={status}
                read={read}
                icon={<BaseOperationIcon Icon={OpenInNewIcon} color="inherit" />}
              />
            ),
            rightAction: <DefaultNotificationRightAction status={status} read={read} uniqueId={uniqueId} />,
          },
        },
      }}
    />
  );
}
export default React.memo(DeviceCommand);
