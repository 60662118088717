import { Box, Card, Collapse, Fab, Zoom } from '@mui/material';
import { useState } from 'react';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useTranslation } from 'react-i18next';
import { TelemetryFiltersBar } from './TelemetryFiltersBar';
import { TelemetryFiltersForm } from './TelemetryFiltersPicker';

export default function Filters() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [showLabel, setShowLabel] = useState(false);

  return (
    <Card
      sx={{
        p: 2,
        position: 'relative',
        overflow: 'visible',
        '@media print': { display: 'none' },
      }}
      onMouseOver={() => setShowLabel(true)}
      onMouseOut={() => setShowLabel(false)}
    >
      <Collapse in={!open}>
        <TelemetryFiltersBar />
      </Collapse>
      <Collapse in={open}>
        <TelemetryFiltersForm />
      </Collapse>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {open && (
          <Fab
            color="primary"
            aria-label="add"
            variant={'extended'}
            sx={{ position: 'absolute', bottom: 0, transform: 'translateY(50%)', zIndex: 2 }}
            onClick={() => setOpen((prev) => !prev)}
          >
            {open ? (
              <>
                <UnfoldLessIcon />
                <Collapse orientation="horizontal" in={showLabel}>
                  {t('collapse')}
                </Collapse>
              </>
            ) : (
              <>
                <UnfoldMoreIcon sx={{ rotate: '45deg' }} />
                <Collapse orientation="horizontal" in={showLabel}>
                  {t('expand')}
                </Collapse>
              </>
            )}
          </Fab>
        )}
        {!open && (
          <Box sx={{ position: 'absolute', bottom: 0, transform: 'translateY(50%)' }}>
            <Zoom in={showLabel}>
              <Fab color="primary" aria-label="add" variant={'extended'} onClick={() => setOpen((prev) => !prev)}>
                {open ? (
                  <>
                    <UnfoldLessIcon />
                    <Collapse orientation="horizontal" in={showLabel}>
                      {t('collapse')}
                    </Collapse>
                  </>
                ) : (
                  <>
                    <UnfoldMoreIcon sx={{ rotate: '45deg' }} />
                    <Collapse orientation="horizontal" in={showLabel}>
                      {t('expand')}
                    </Collapse>
                  </>
                )}
              </Fab>
            </Zoom>
          </Box>
        )}
      </Box>
    </Card>
  );
}
