import { Box } from '@mui/material';

import AlarmsAndErrorsPanel from './Panel';

export default function AlarmsAndErrorCard() {
  return (
    <Box sx={{ mt: 2 }}>
      <AlarmsAndErrorsPanel pageSize={5} />
    </Box>
  );
}
