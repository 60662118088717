import { MutableRefObject, useCallback, useRef } from 'react';
import { GenericExportTableProps } from './typings';
import ExportTable from './ExportTable';
import React from 'react';

const GenericExportTable = <T extends object & { id: string }>({
  data,
  title,
  columns,
  exportData,
  selection,
  onSelectionChange,
  ...props
}: GenericExportTableProps<T>) => {
  const selectedRowsID = useRef<Array<string>>([]);

  const handleSelection = useCallback(
    function (data: T[]) {
      selectedRowsID.current = [...data.map(({ id }) => id)];
      onSelectionChange?.(data);
    },
    [onSelectionChange]
  );

  const filterRowsBySelected = useCallback(
    (data: T[], selectedRowsID: MutableRefObject<string[]>): T[] =>
      data.filter(({ id }) => !selectedRowsID.current.length || selectedRowsID.current.includes(id)),
    []
  );

  return (
    <ExportTable
      title={title}
      data={data}
      columns={columns}
      handleSelection={handleSelection}
      filterRowsBySelected={filterRowsBySelected}
      selectedRowsID={selectedRowsID}
      exportData={exportData}
      selection={selection}
      {...props}
    />
  );
};

export default GenericExportTable;
