import useAlarmColumns from './useAlarmsColumns';
import { AlarmRes } from '@/components/OneTools/Channels/Alarms/typings';
import { useGetAlarmsQuery } from '@/redux/api/system/alarmsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ROUTES } from '@/shared/constants';

export const AlarmsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<AlarmRes>));

export default function AlarmPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetAlarmsQuery();
  const columns = useAlarmColumns();

  return (
    <AlarmsTable
      title={t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
      exportData={true}
      selection={true}
    />
  );
}
