import { ApiResult } from '@typings';
import { ConfigRes } from '@/components/OneTools/Channels/Configs/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';

const URL_PREFIX = `${API_VERSION.v2}/system/configs`;

export const ConfigsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Configs'] }).injectEndpoints({
  endpoints: (builder) => ({
    getConfigs: builder.query<ApiResult<{ items: ConfigRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Configs'],
    }),
    getConfig: builder.query<ApiResult<ConfigRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Configs'],
    }),
    isConfigUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
  }),
});

export const { useLazyGetConfigsQuery, useGetConfigsQuery, useLazyGetConfigQuery, useLazyIsConfigUniqueQuery } =
  ConfigsApiSlice;
