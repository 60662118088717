import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { Alert, Box, Typography } from '@mui/material';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DevicesWithoutLocationPanel from '@/components/Fleet/Devices/DevicesPanel/DevicesWithoutLocationPanel';
import { MARKER_TYPE } from '@/shared/constants';
import { MarkerInfo } from '@/components/Shared/Map/typings';
import { isValidNumber } from '@/shared/utils';
import { FleetDevices } from '@/components/Fleet/Devices/DevicesPanel/typings';
import { useGetFilteredDevicesQuery } from '@/redux/api/fleet/devicesApiSlice';
import { useOutletContext } from 'react-router';
import { DevicesContext } from '.';
import CulliganMap from '@/components/Shared/Map';
import DeviceMarker from '@/components/Shared/Map/Device/DeviceMarker';
import InfoPanel from '@/components/Shared/Map/InfoPanel';
import DeviceInfoPanelHeader from '@/components/Shared/Map/Device/InfoPanelHeader';

const DevicesMap = React.memo(() => {
  const { t } = useTranslation();
  const { filters } = useOutletContext<DevicesContext>();
  const { data } = useGetFilteredDevicesQuery(filters);
  const devices = data?.data;
  const { devicesWithCoordinates, devicesWithoutCoordinates } = useMemo(
    () =>
      devices?.length
        ? devices?.reduce(
            (
              acc: { devicesWithCoordinates: FleetDevices.Device[]; devicesWithoutCoordinates: FleetDevices.Device[] },
              device: FleetDevices.Device
            ) => {
              isValidNumber(device.lat) && isValidNumber(device.lon)
                ? acc.devicesWithCoordinates.push(device)
                : acc.devicesWithoutCoordinates.push(device);
              return acc;
            },
            {
              devicesWithCoordinates: [],
              devicesWithoutCoordinates: [],
            }
          )
        : {
            devicesWithCoordinates: [],
            devicesWithoutCoordinates: [],
          },
    [devices]
  );

  const markers: MarkerInfo[] = useMemo(() => {
    return devicesWithCoordinates?.map((fd: FleetDevices.Device) => ({
      position: { lat: fd.lat, lng: fd.lon },
      title: fd.name || '',
      description: fd.id,
      type: MARKER_TYPE.DEVICE,
      extra: fd,
      deviceQnumber: fd.brandName === 'quench' && fd.metaData?.deviceQnumber ? fd.metaData.deviceQnumber : '',
      manufacturingSerialNumber: fd.metaData?.manufacturingSerialNumber ?? '',
    }));
  }, [devicesWithCoordinates]);

  const decodeMarkerInfo = useCallback(
    (marker: MarkerInfo) => ({
      ...marker.position,
    }),
    []
  );

  return (
    <Fragment>
      <Typography variant="h4" pb={2}>
        {t('devicesMap')}
      </Typography>
      <Alert
        severity="info"
        sx={{
          margin: '12px 0',
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: '#f6f6f6',
          position: 'relative',
          height: 'min-content',
          '.MuiAlert-icon': { fontSize: '18px' },
        }}
      >
        {t('markerInfo')}
      </Alert>
      <Box border={0} position="relative" height="800px">
        <CulliganMap
          data={markers}
          decode={decodeMarkerInfo}
          createMarker={(props) => <DeviceMarker {...props} />}
          slots={{
            InfoPanel: (props) => (
              <InfoPanel
                {...props}
                slots={{
                  Header: (props) => (
                    <DeviceInfoPanelHeader {...props} title={props.marker.title} subtitle={props.marker.description} />
                  ),
                }}
              />
            ),
          }}
          noDataMessage={t('noDevicesFound') as string}
          id="devicesLocationMap"
        />
      </Box>

      <RenderIf condition={devicesWithoutCoordinates.length > 0}>
        <DevicesWithoutLocationPanel devices={devicesWithoutCoordinates || []} />
      </RenderIf>
    </Fragment>
  );
});
export default DevicesMap;
