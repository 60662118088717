import { pipe } from 'effect';
import { FleetOverview } from './typings';

export type FGInitialState = {
  withoutTelemetry: number;
  byIdentities: Map<FleetOverview.Device['model'], FleetOverview.DeviceSummary>;
  alerts: { none: number; priority: number; postponable: number };
};

export type AEInitialState = {
  alarms: FleetOverview.Alarm[];
  errors: FleetOverview.Error[];
  alarmsDefinitions: Map<FleetOverview.AlarmDefinition['id'], FleetOverview.AlarmDefinition>;
};

export const buildFleetGeneralState = (): FGInitialState => ({
  withoutTelemetry: 0,
  byIdentities: new Map<FleetOverview.Device['model'], FleetOverview.DeviceSummary>(),
  alerts: { none: 0, priority: 0, postponable: 0 },
});

export const buildAlarmsAndErrorsState = (alarmsDefinitions: FleetOverview.AlarmDefinition[]): AEInitialState => ({
  alarms: [],
  errors: [],
  alarmsDefinitions: new Map<FleetOverview.AlarmDefinition['id'], FleetOverview.AlarmDefinition>(
    alarmsDefinitions.map((alarm) => [alarm.id, alarm])
  ),
});

export const fleetGeneralReducer = (state: FGInitialState, device: FleetOverview.Device): FGInitialState =>
  pipe(
    { state, device },
    ({ state, device }) => withAlerts(state, device),
    ({ state, device }) => withIdentity(state, device),
    ({ state, device }) => withMissingTelemetry(state, device),
    ({ state }) => state
  );

export const fleetAlarmsErrorsReducer = (state: AEInitialState, device: FleetOverview.Device): AEInitialState =>
  pipe(
    { state, device },
    ({ state, device }) => withEnrichedAlarms(state, device),
    ({ state, device }) => withErrors(state, device),
    ({ state }) => state
  );

const withErrors = (state: AEInitialState, device: FleetOverview.Device) => {
  if (device.errors?.length && device.errors.length > 0) {
    state.errors.push(...device.errors.map((e) => ({ ...e, deviceId: device.id, deviceName: device.name })));
  }
  return { state, device };
};
const withEnrichedAlarms = (state: AEInitialState, device: FleetOverview.Device) => {
  if (device.alarms?.length && device.alarms.length > 0) {
    state.alarms.push(
      ...device.alarms.map((alarm) => {
        const alarmDefinition = state.alarmsDefinitions.has(alarm.id)
          ? state.alarmsDefinitions.get(alarm.id)
          : state.alarmsDefinitions.has(alarm.alarmName)
          ? state.alarmsDefinitions.get(alarm.alarmName)
          : null;

        return alarmDefinition
          ? {
              ...alarm,
              alarmId: alarmDefinition.id,
              description: alarmDefinition.description,
              deviceId: device.id,
              deviceName: device.name,
              name: alarmDefinition.name,
              severity: alarmDefinition.severity,
            }
          : { ...alarm, deviceId: device.id, deviceName: device.name };
      })
    );
  }
  return { state, device };
};

const withAlerts = (state: FGInitialState, device: FleetOverview.Device) => {
  if (device.errors?.length && device.errors.length > 0) state.alerts.priority++;
  if (device.alarms?.length && device.alarms.length > 0) state.alerts.postponable++;
  if (device.errors?.length === 0 && device.alarms?.length === 0) state.alerts.none++;
  return { state, device };
};

const withMissingTelemetry = (state: FGInitialState, device: FleetOverview.Device) => {
  if (!device.hasTelemetry) state.withoutTelemetry++;
  return { state, device };
};

const withIdentity = (state: FGInitialState, device: FleetOverview.Device) => {
  const _model = state.byIdentities.get(device.model);
  if (_model) state.byIdentities.set(device.model, { ..._model, count: _model.count + 1 });
  else
    state.byIdentities.set(device.model, {
      name: device.name,
      model: device.model,
      count: 1,
      identityId: device.identityId,
    });
  return { state, device };
};

export const sortDevices = (devices: FleetOverview.DeviceSummary[], sortBy: 'amount' | 'name', sortAsc: boolean) => {
  const compareNumbers = (a: number, b: number) => (sortAsc ? a - b : b - a);
  const compareStrings = (a: string, b: string) => (sortAsc ? a.localeCompare(b) : b.localeCompare(a));

  const sortFunctions = {
    amount: (a: FleetOverview.DeviceSummary, b: FleetOverview.DeviceSummary) => compareNumbers(a.count, b.count),
    name: (a: FleetOverview.DeviceSummary, b: FleetOverview.DeviceSummary) => compareStrings(a.name, b.name),
  };

  return [...devices].sort(sortFunctions[sortBy]);
};
