import { Dayjs } from 'dayjs';
import { Schema as S } from '@effect/schema';
import { BaseTableFilterState } from './typings';
import { Column } from '@material-table/core';
import React from 'react';
import i18next from 'i18next';

function defaultSearchByDate(filter: string, date: Dayjs) {
  if (!filter.includes('/') && Number.isNaN(Number(filter))) {
    return false;
  }

  const day = date.date();
  const month = date.get('month') + 1;
  const year = date.get('year');

  const [filterYear, filterMonth, filterDay] = filter.split('/').reverse();
  return Boolean(
    ((filterDay && Number(filterDay) === day) || !filterDay) &&
      ((filterMonth && Number(filterMonth) === month) || !filterMonth) &&
      filterYear &&
      Number(filterYear) === year
  );
}

export const getTableFilters = <T extends String>(entity: S.Schema<T>) =>
  S.Struct({
    ...BaseTableFilterState.fields,
    orderBy: S.optional(entity),
  });

/**
 * Makes provided columns non hidable, with fallback in case of no columns provided.
 * @param columns
 * @param nonHideableColumns
 * @returns
 */
const withNonHideableColumns = <T extends object>(
  columns: Array<Column<T>>,
  nonHideableColumns?: String | Array<String>
): Array<Column<T>> => {
  const fallbackNonHideableColumn = i18next.t('actions');
  const hasMultipleNonHidableColumns = Array.isArray(nonHideableColumns);
  const makeNonHideableColumn = (col: Column<T>) => ({ ...col, removable: false });
  const makeFirstColumnNonHideable = (columns: Array<Column<T>>) => {
    const [head, ...rest] = columns;
    return [makeNonHideableColumn(head), ...rest];
  };

  if (typeof nonHideableColumns === 'undefined') {
    /*
     * If we do not have the fallback `Actions` column, we make non hideable only the first column.
     */
    const hasNonHideableColumn = columns.some((col) => col.title === fallbackNonHideableColumn);
    return hasNonHideableColumn
      ? columns.map((col) => (col.title === fallbackNonHideableColumn ? makeNonHideableColumn(col) : col))
      : makeFirstColumnNonHideable(columns);
  }

  return hasMultipleNonHidableColumns
    ? columns.map((col) => (nonHideableColumns.includes(col.title as String) ? makeNonHideableColumn(col) : col))
    : columns.map((col) => (col.title === nonHideableColumns ? makeNonHideableColumn(col) : col));
};

/**
 * Parses the columns titles to make them viewable in pure text.
 * If a column has a title in the form of a React Element it will take its text.
 * @param columns
 * @returns columns
 */
const withFlattenedColumns = <T extends object>(columns: Array<Column<T>>) => {
  return columns.map((column) => {
    if (React.isValidElement(column.title)) {
      const columnTitle = column.title;
      return { ...column, title: columnTitle.props.children || columnTitle.props?.anchorText };
    }

    return column;
  });
};

const withExcludedColumns = <T extends object>(columns: Array<Column<T>>, excludeColumns?: Array<string>) => {
  return excludeColumns ? columns.filter((column) => !excludeColumns.includes(column.field as string)) : columns;
};

/**
 * A utility function to build an array of columns for a table.
 *
 * @template T - The type of the objects in the columns.
 * @returns {Object} An object with methods to add columns and retrieve the array of columns.
 *
 * @example
 * const builder = columnsBuilder<MyType>();
 * builder.addColumn({ title: 'Name', field: 'name' });
 * const columns = builder.columns;
 */
const columnsBuilder = <T extends object>() => {
  const _columns: Column<T>[] = [];
  const make = () => ({
    addColumn: (col: Column<T>) => {
      _columns.push(col);
      return make();
    },
    columns: _columns,
  });
  return make();
};

export { defaultSearchByDate, withExcludedColumns, withFlattenedColumns, withNonHideableColumns, columnsBuilder };
