import DeviceConsumablesList from '@/components/Fleet/Devices/DevicePanel/ConsumablesPanel/DeviceConsumablesList';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export default function Consumables() {
  const { t } = useTranslation();
  const context = useContext(DeviceContext);
  const navigate = useNavigate();

  if (!context || !context.data) {
    return <></>;
  }

  return (
    <DeviceConsumablesList
      isDeviceConnected={context.data.status.connection.online}
      data={context.data.consumables}
      actions={{
        flavors: {
          actionLabel: t('changeLabels'),
          onActionClick: () => navigate('/admin-tools'),
        },
      }}
      isLoading={context.isLoading}
      isError={context.isError}
    />
  );
}
