import { DeviceTelemetry, DeviceTelemetryWithGroup } from '../typings';
import { PRIMITIVES } from '@/shared/constants';
import { Box, Typography } from '@mui/material';
import TelemetryNumberCard from './TelemetryNumberCard';
import TelemetryBooleanCard from './TelemetryBooleanCard';
import TelemetryStringCard from './TelemetryStringCard';
import Section from '@/components/Shared/Section';
import { useTranslation } from 'react-i18next';
import TelemetryGroupCard from './TelemetryGroupCard';
import { CulliganCard } from '@/components/Shared/Card';

const getTelemetryCard = (telemetry: DeviceTelemetry) => {
  switch (telemetry?.primitive) {
    case PRIMITIVES.NUMBER:
      return (
        <CulliganCard key={telemetry.id} sx={{ aspectRatio: '1/1', maxHeight: '300px' }}>
          <TelemetryNumberCard telemetry={telemetry} />
        </CulliganCard>
      );
    case PRIMITIVES.BOOLEAN:
      return (
        <CulliganCard key={telemetry?.id} sx={{ aspectRatio: '1/1', maxHeight: '300px' }}>
          <TelemetryBooleanCard telemetry={telemetry} />
        </CulliganCard>
      );
    case PRIMITIVES.STRING:
      return (
        <CulliganCard key={telemetry?.id} sx={{ aspectRatio: '1/1', maxHeight: '300px' }}>
          <TelemetryStringCard telemetry={telemetry} />
        </CulliganCard>
      );
    default:
      return <></>;
  }
};

export default function CurrentDataSection({
  telemetriesWithoutGroupTag,
  telemetriesGroups,
}: {
  telemetriesWithoutGroupTag: DeviceTelemetry[];
  telemetriesGroups: DeviceTelemetryWithGroup[];
}) {
  const { t } = useTranslation();

  return (
    <Section sx={{ '@media print': { display: 'none' } }}>
      <Typography variant="h5" mb={4}>
        {t('mostRecentData')}
      </Typography>
      <Box display="flex" flexDirection={'column'} gap={2}>
        <Box display="flex" gap={2} flexWrap={'wrap'}>
          {telemetriesWithoutGroupTag?.map((telemetry) => getTelemetryCard(telemetry))}
        </Box>
        <Box display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' }, flexWrap: 'wrap' }} gap={2}>
          {telemetriesGroups?.length
            ? telemetriesGroups?.map((group, index) => (
                <TelemetryGroupCard key={group?.tag} title={group?.tag || ''} telemetriesGroup={group?.items || []} />
              ))
            : null}
        </Box>
      </Box>
    </Section>
  );
}
