import { Marker } from '@googlemaps/markerclusterer/dist/marker-utils';
import { ReactNode, useCallback, useMemo } from 'react';
import { AccuracyLatLngLiteral } from './typings';
import { WithSlots } from '@typings';
import { AdvancedMarker, AdvancedMarkerProps, Pin, PinProps } from '@vis.gl/react-google-maps';
import { Circle, CircleProps } from '@/components/Fleet/Devices/DevicePanel/OverviewPanel/MapCircle';
import { alpha, Box, useTheme } from '@mui/material';

export type MarkerProps<TData> = {
  id: string;
  data: TData;
  setMarkerRef?: (marker: Marker | null, key: string) => void;
  onClick?: (content: ReactNode) => void;
  isSelected: boolean;
};

export default function CulliganMarker<TData extends AccuracyLatLngLiteral>({
  data,
  slots,
  id,
  setMarkerRef,
  onClick,
  isSelected,
}: WithSlots<
  MarkerProps<TData>,
  {
    AdvancedMarker: (props: Omit<AdvancedMarkerProps, 'position' | 'onClick'>) => ReturnType<typeof AdvancedMarker>;
    Circle: (props: Omit<CircleProps, 'center' | 'onClick'>) => ReturnType<typeof Circle>;
    Pin: (props: PinProps) => ReturnType<typeof Pin>;
    OnClickContent: (props: MarkerProps<TData>) => ReturnType<typeof Box>;
  }
>) {
  const theme = useTheme();

  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => setMarkerRef?.(marker, id),
    [setMarkerRef, id]
  );

  const ADVANCED_MARKER = useMemo(() => slots?.AdvancedMarker ?? AdvancedMarker, [slots?.AdvancedMarker]);
  const CIRCLE = useMemo(() => slots?.Circle ?? Circle, [slots?.Circle]);
  const PIN = useMemo(() => slots?.Pin ?? Pin, [slots?.Pin]);
  const ON_CLICK_CONTENT = useMemo(
    () =>
      slots?.OnClickContent ??
      ((props: MarkerProps<TData>) => (
        <Box sx={{ p: 2, bgcolor: (theme) => theme.palette.background.default }}>
          lat: {props.data.lat}
          lon: {props.data.lat}
          {props.data.accuracy && `accuracy: ${props.data.lat}`}
        </Box>
      )),
    [slots?.OnClickContent]
  );

  return (
    <ADVANCED_MARKER
      key={id}
      position={{ lat: data.lat, lng: data.lng }}
      ref={ref}
      onClick={() => onClick?.(ON_CLICK_CONTENT({ data, id, isSelected }))}
    >
      {data.accuracy !== undefined && (
        <CIRCLE
          center={{ lat: data.lat, lng: data.lng }}
          radius={data.accuracy}
          strokeWeight={0}
          fillColor={theme.palette.primary.main}
        />
      )}
      <PIN
        background={isSelected ? theme.palette.error.main : theme.palette.primary.main}
        glyphColor={alpha(theme.palette.background.default, 0.2)}
        borderColor={isSelected ? theme.palette.error.light : theme.palette.primary.light}
      />
    </ADVANCED_MARKER>
  );
}
