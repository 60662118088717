import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import APP_CONFIG from '../../appConfig';
import { AuthState, login, logout } from '../slices/authSlice';
import { setRequestedLocation } from '../utils';

export enum API_VERSION {
  v1 = '/v1',
  v2 = '/v2',
}

const _query = fetchBaseQuery({
  baseUrl: `${APP_CONFIG.apiBaseUrl}`,
  prepareHeaders: (headers, { getState }) => {
    const {
      auth: { accessToken },
    } = getState() as { auth: AuthState };

    if (accessToken && !headers.has('x-ms-blob-type')) {
      // Prevents adding the authorization when uploading files in Azure
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const query = async (arg: FetchArgs | string, api: BaseQueryApi, extra: any) => {
  const result = await _query(arg, api, extra);

  if (result?.error?.status === 401) {
    // Unauthorized
    const {
      auth: { refreshToken },
    } = api.getState() as { auth: AuthState };

    const refreshResult = await _query(
      {
        method: 'PUT',
        url: `${API_VERSION.v1}/auth/login`,
        body: {
          appId: APP_CONFIG.appId,
          refreshToken,
        },
      },
      api,
      extra
    );

    if (refreshResult?.data) {
      const { data } = refreshResult.data as { data: AuthState };
      const { user, accessToken, refreshToken, roles } = data;
      api.dispatch(login({ user, accessToken, refreshToken, roles }));
      return await _query(arg, api, extra);
    } else {
      setRequestedLocation(window.location);
      api.dispatch(logout());
    }
  } else if (result?.error?.status === 403) {
    // Forbidden
    setRequestedLocation(window.location);
    api.dispatch(logout());
  }

  return result;
};

export default createApi({
  reducerPath: 'api',
  baseQuery: query,
  endpoints: (builder) => ({}),
});
