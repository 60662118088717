import { Box, Checkbox, Collapse, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import ExpandIcon from '@/components/Shared/AnimatedExpandIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ITEM_TYPE, MergedTelemetry } from '../../../../typings';

export default function BaseItemPicker({
  item,
  children,
  isChecked,
  isSelected,
  onChangeHandler,
}: {
  item: MergedTelemetry;
  children?: React.ReactNode;
  isChecked: boolean;
  isSelected: boolean;
  onChangeHandler: (isChecked: boolean, item: MergedTelemetry) => void;
}) {
  const [collapsed, setCollapsed] = useState(!isSelected);

  return (
    <Box
      sx={{
        p: 1,
        pl: 2,
        borderRadius: 1,
        color: (theme) => (isSelected ? theme.palette.primary.contrastText : 'inherit'),
        background: (theme) => (isSelected ? theme.palette.primary.main : theme.palette.background.grayShades[0]),
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <FormControlLabel
          checked={isChecked}
          sx={{ color: (theme) => (isSelected ? theme.palette.primary.contrastText : 'inherit') }}
          onChange={(_) => onChangeHandler(isChecked, item)}
          control={
            <Checkbox
              sx={{
                color: 'inherit',
                '& .MuiSvgIcon-root': {
                  fontSize: 28,
                  ...(isChecked ? { fill: (theme) => theme.palette.background.grayShades[0] } : {}),
                },
              }}
            />
          }
          label={item.name}
        />
        {item.type === ITEM_TYPE.GROUP && (
          <ExpandIcon rotate={!collapsed} color="inherit" sx={{ p: 2 }} onClick={() => setCollapsed((prev) => !prev)}>
            <ExpandMoreIcon />
          </ExpandIcon>
        )}
      </Box>
      {item.type === ITEM_TYPE.GROUP && <Collapse in={!collapsed}>{children}</Collapse>}
    </Box>
  );
}
