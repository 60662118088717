import { Box, List, ListItem, Skeleton, useMediaQuery } from '@mui/material';
import { FLEET_GENERAL_QUERY_WIDTH } from '.';

export default function LoadingDevicesList() {
  const matches = useMediaQuery(`(max-width:${FLEET_GENERAL_QUERY_WIDTH}px`);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'grid',
        gridTemplate: matches ? '0.5fr 1fr / 1fr' : '1fr / 0.5fr 1fr',
        gap: 1,
      }}
      id="mainContainer"
    >
      <Box
        display="flex"
        gap={1}
        flexDirection={matches ? 'row' : 'column'}
        {...(matches ? { sx: { width: '100%' } } : {})}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          sx={{
            borderRadius: 0.2,
          }}
        >
          <Skeleton variant="rounded" width="100%" height="100%" animation="pulse" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={2}
          sx={{
            borderRadius: 0.2,
          }}
        >
          <Skeleton variant="rounded" width="100%" height="100%" animation="pulse" />
        </Box>
      </Box>
      <List
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: 'repeat(4, auto)',
          gridAutoFlow: 'column',
          gap: 1,
          alignItems: 'stretch',
          flexGrow: 2,
          py: 0,
          color: 'text.primary',
        }}
      >
        {new Array(8).fill('').map(() => {
          return (
            <ListItem sx={{ p: 0 }} key={Math.random()}>
              <Skeleton variant="rounded" width="100%" height={50} animation="pulse" />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
