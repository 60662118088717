import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { DeviceIdentityRes } from '../DeviceIdentitiesPanel/typings';
import { useGetDeviceIdentityWithRelatedQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import DeviceIdentitySection from './DeviceIdentitySection';
import DeviceIdentityChannels from './DeviceIdentityChannels';
import { DeviceIdentityContext } from './DeviceIdentityContext';

const SafeDeviceIdentitySection = withErrorLoadingManagement(DeviceIdentitySection);

const SafeDeviceIdentityChannels = withErrorLoadingManagement(DeviceIdentityChannels);

function DeviceIdentityPanel({ deviceIdentity }: { deviceIdentity: DeviceIdentityRes }) {
  const {
    data: deviceIdentityWithRelated,
    isError,
    isLoading,
    isFetching,
  } = useGetDeviceIdentityWithRelatedQuery(deviceIdentity.id!);

  return (
    <>
      <DeviceIdentityContext.Provider value={deviceIdentityWithRelated?.data}>
        <SafeDeviceIdentitySection isLoading={isLoading || isFetching} isError={isError} />
        <SafeDeviceIdentityChannels isLoading={isLoading || isFetching} isError={isError} />
      </DeviceIdentityContext.Provider>
    </>
  );
}

export default withErrorLoadingManagement(DeviceIdentityPanel);
