import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export type InfoPanelHeaderProps<TData> = {
  onClose: () => void;
  marker: TData;
};
export default function InfoPanelHeader<TData>({ onClose }: InfoPanelHeaderProps<TData>) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-start' }}>
      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </Box>
  );
}
