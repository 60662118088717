import { Device } from '../../../DevicesPanel/typings';
import OverviewCard from '../DeviceCardInfo';
import { useTranslation } from 'react-i18next';
import PowerProfile from './PowerProfile';
import { alpha, Box, Skeleton, Tooltip, Typography } from '@mui/material';
import LogLevel from './LogLevel';
import { useCallback, useMemo } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import {
  usePostLogLevelCommandMutation,
  usePostOperatingModeCommandMutation,
  usePostPowerProfileCommandMutation,
} from '@/redux/api/admin/deviceCommandsApiSlice';
import { useSelector } from 'react-redux';
import { selectRoles } from '@/redux/slices/authSlice';
import { ROLES } from '@/shared/constants';
import { AppState } from '@/redux/store';
import React from 'react';
import {
  selectHasPendingLogLevel,
  selectHasPendingOperatingMode,
  selectHasPendingOTAUpdate,
  selectHasPendingPowerProfile,
  selectPendingCommands,
} from '@/redux/slices/deviceSlice';
import { SELECT_CARD_ROLES } from '@/components/Shared/AddEditDialog/SelectCardInput';
import { AREA_DESIGN_TOKENS, AreaRow, DataBoundAreaRow } from '@/components/Shared/Card/Area';
import { Body, Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import ConfirmDialog from '@/components/Shared/ConfirmDialog';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { usePostRebootCommandMutation } from '@/redux/api/admin/deviceCommandsApiSlice';
import {
  useGetOTAJobsDevicesListQuery,
  useLazyGetOTAListByDeviceIdQuery,
  usePostOTAJobMutation,
} from '@/redux/api/admin/otaApiSlice';
import { getPath, isGreaterVersion } from '@/shared/utils';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { DialogContent, DialogTitle } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { OperatingMode as OM } from '@culligan-iot/domain/culligan/one/device';
import { useNavigate } from 'react-router';

import Firmware from './Firmware';
import Status from './Status';
import { ParsedInfo } from '../..';
import { useOperatingModeColors } from './useOperatingModeColors';

import dayjs from 'dayjs';
import OperatingMode from './OperatingMode';
import AreaButton from '@/components/Shared/Card/Area/AreaButton';
function ProfileCard({
  device,
  info,
  otaPollingRequestAt,
  postPowerProfile,
  postOTAJob,
  postOperatingMode,
  postLogLevel,
  postReboot,
}: {
  device: Device;
  info: ParsedInfo;
  otaPollingRequestAt?: number;
  postReboot: ReturnType<typeof usePostRebootCommandMutation>[0];
  postOTAJob: ReturnType<typeof usePostOTAJobMutation>[0];
  postPowerProfile: ReturnType<typeof usePostPowerProfileCommandMutation>[0];
  postOperatingMode: ReturnType<typeof usePostOperatingModeCommandMutation>[0];
  postLogLevel: ReturnType<typeof usePostLogLevelCommandMutation>[0];
}) {
  const { t } = useTranslation();
  const isAdmin = useSelector((state: AppState) => selectRoles(state)).some(
    (role) => role === ROLES.SYS_ADMIN || role === ROLES.ADMIN
  );
  const pendingCommands = useSelector((state: AppState) => selectPendingCommands(state, device.serialNumber));
  const isInLoadingState = pendingCommands.length > 0;
  const hasPendingOperatingMode = useSelector((state: AppState) =>
    selectHasPendingOperatingMode(state, device.serialNumber)
  );
  const hasPendingPowerProfile = useSelector((state: AppState) =>
    selectHasPendingPowerProfile(state, device.serialNumber)
  );
  const hasPendingLogLevel = useSelector((state: AppState) => selectHasPendingLogLevel(state, device.serialNumber));
  const requestedLogLevel = useMemo(
    () => pendingCommands.find((command) => command.command === 'log.set')?.payload,
    [pendingCommands]
  );

  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [triggerGetOTAListByDeviceId, result] = useLazyGetOTAListByDeviceIdQuery();
  const hasPendingCommmands = pendingCommands.length > 0;
  const hasPendingOTAUpdate = useSelector((state: AppState) => selectHasPendingOTAUpdate(state, device.serialNumber));
  const hasPendingOperatingModeUpdate = useSelector((state: AppState) =>
    selectHasPendingOperatingMode(state, device.serialNumber)
  );
  const isLoadingReboot = pendingCommands.some((command) => command.command === 'reboot');
  const operatingModeColors = useOperatingModeColors();
  const isRebootSupported =
    device?.supportedOperatingModes?.length && device?.supportedOperatingModes.includes('Startup');
  const otaJobsResult = useGetOTAJobsDevicesListQuery(device.id);

  useEffect(() => {
    triggerGetOTAListByDeviceId(device.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentOTAVersion = useMemo(() => {
    const otaJobs = otaJobsResult.data?.data?.items || [];
    const availableOTAs = result.data?.data?.items || [];

    const sortedOTAJobs = otaJobs
      .filter((job) => job.status === 'completed')
      .sort((a, b) => new Date(b.completedAt ?? 0).getTime() - new Date(a.completedAt ?? 0).getTime());

    const lastCompletedOTAJob = sortedOTAJobs.find((job) => job.targetVersion === device.swVersion);

    if (lastCompletedOTAJob) {
      const matchingOTA = availableOTAs.find((ota) => ota.id === lastCompletedOTAJob.buildFile);

      if (matchingOTA) {
        return matchingOTA;
      }
    }
    const fallbackOTA = availableOTAs.find((OTA) => device.swVersion === OTA.version);
    if (fallbackOTA) {
      return fallbackOTA;
    }

    return null;
  }, [otaJobsResult.data, result.data, device.swVersion]);

  const displayedOTAVersion = useMemo(() => {
    if (currentOTAVersion) {
      return currentOTAVersion;
    }

    return {
      id: 'deletedOTAplaceholder',
      version: device.swVersion,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      minVersion: device.swVersion,
      model: device.model,
    };
  }, [currentOTAVersion, device.swVersion, device.model]);

  const renderCurrentArtifact = useMemo(() => {
    if (displayedOTAVersion.id === 'deletedOTAplaceholder') {
      return (
        <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: AREA_DESIGN_TOKENS.gap }}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              width: '100%',
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Skeleton variant="rounded" width={110} height={20} animation={false} />
              <Tooltip title={t('otaNoLongerExists')} arrow>
                <WarningIcon color="warning" sx={{ fontSize: 20 }} />
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              width: '100%',
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Box>
              <Subtitle>{t('version')}</Subtitle>
              <Title>{displayedOTAVersion.version}</Title>
            </Box>
            {displayedOTAVersion.minVersion && (
              <Box>
                <Subtitle sx={{ fontSize: 10 }}>{t('minVersion')}</Subtitle>
                <Body sx={{ fontWeight: '600' }}>{displayedOTAVersion.minVersion}</Body>
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
            <Skeleton variant="rounded" width={110} height={20} animation={false} />
            <Skeleton variant="rounded" width={110} height={20} animation={false} />
          </Box>
        </Box>
      );
    }

    return null;
  }, [displayedOTAVersion, t]);

  const isDeviceOnline = device?.status?.connection?.online;

  const canOTAUpdate =
    isDeviceOnline &&
    !hasPendingCommmands &&
    !hasPendingOTAUpdate &&
    device.operatingMode !== OM.enums.OTA &&
    device.operatingMode !== OM.enums.Startup;

  const getOTATooltipMessage = useCallback(
    (isPolling: boolean, isOnline: boolean, operatingMode: OM) => {
      if (isPolling) {
        return t('firmwareUpdateAlreadyRequested');
      }
      if (!isOnline) {
        return t('deviceIsntConnectedRetryLater');
      }
      if (operatingMode === OM.enums.OTA || operatingMode === OM.enums.Startup) {
        return t('cantPerformOTAinCurrentOperatingMode');
      }
    },
    [t]
  );

  const otaTooltipMessage = useMemo(
    () => getOTATooltipMessage(hasPendingOTAUpdate || false, isDeviceOnline, device.operatingMode as OM),
    [getOTATooltipMessage, hasPendingOTAUpdate, isDeviceOnline, device.operatingMode]
  );

  const parseVersionAndMinVersion = (versionString: string) => {
    const parts = versionString.split('.');
    if (parts.length <= 3) {
      return { version: versionString, minVersion: null };
    }
    const version = parts.slice(0, 3).join('.');
    const minVersion = parts.slice(3).join('.');
    return { version, minVersion };
  };

  const confirmDialogMessage = useMemo(() => {
    return (
      <Fragment>
        <DialogTitle>
          {t('reboot')} {device.name}
        </DialogTitle>
        <DialogContent>
          <Typography>{`Are you sure you want to reboot ${device.serialNumber}?`}</Typography>
        </DialogContent>
      </Fragment>
    );
  }, [device.name, device.serialNumber, t]);

  const availableOTAs = useMemo(() => {
    const otaItems = result.data?.data?.items || [];
    return otaItems;
  }, [result.data]);

  const { AddEditDialog, openDialog } = useAddEditDialog({
    title: t('firmware'),
    baseConfig: [
      {
        name: 'firmwareVersion',
        type: 'selectCard',
        options: { required: t('fieldRequiredError') as string },
        selectCardConfig: {
          options: () => triggerGetOTAListByDeviceId(device.id).then((res) => res.data?.data?.items || []),
          labels: {
            current: t('artifact'),
            selected: t('artifact'),
            options: t('artifacts'),
          },
          getItemIdentifier: (item) => item?.id ?? '',
          mapItemToHeaderElements: (item) => {
            if (!item) return renderCurrentArtifact;

            return (
              <Box key={item.id + 'name'} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Subtitle sx={{ fontWeight: '600', overflowWrap: 'break-word', wordBreak: 'break-all' }}>
                  {item.id}
                </Subtitle>
              </Box>
            );
          },

          mapItemToBodyElements: (item, _, role) => {
            if (!item) return null;

            const { version, minVersion } = parseVersionAndMinVersion(item.version);

            return (
              <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: AREA_DESIGN_TOKENS.gap }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexGrow: 1,
                    width: '100%',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-all',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                  }}
                >
                  <Box>
                    <Subtitle>{t('version')}</Subtitle>
                    <Title>
                      {version}
                      {role !== SELECT_CARD_ROLES.CURRENT &&
                        isGreaterVersion(displayedOTAVersion?.version as string, version) && (
                          <WarningIcon color="warning" sx={{ fontSize: 12 }} />
                        )}
                    </Title>
                  </Box>
                  <RenderIf condition={item.minVersion != null}>
                    <Box>
                      <Subtitle sx={{ fontSize: 10 }}>{t('minVersion')}</Subtitle>
                      <Body sx={{ fontWeight: '600' }}>{minVersion || item.minVersion}</Body>
                    </Box>
                  </RenderIf>
                </Box>
                <Box>
                  <Subtitle sx={{ fontSize: 10 }}>{t('createdAt')}</Subtitle>
                  <Body>{dayjs(item.createdAt).format('DD/MM/YYYY')}</Body>
                </Box>
                <Box>
                  <Subtitle sx={{ fontSize: 10 }}>{t('updatedAt')}</Subtitle>
                  <Body>{dayjs(item.updatedAt).format('DD/MM/YYYY')}</Body>
                </Box>
              </Box>
            );
          },
          isCurrentItem: (item, currentValue) => item.id === currentValue,
          isWarningItem: (item) => {
            if (!item) return false;
            const { version } = parseVersionAndMinVersion(item.version);
            return displayedOTAVersion ? isGreaterVersion(displayedOTAVersion.version, version) : false;
          },
          isDisabledItem: (item) => {
            if (!item) return true;
            const { version } = parseVersionAndMinVersion(item.version);

            if (isGreaterVersion(device.swVersion, version)) {
              return true;
            }

            if (version === device.swVersion) {
              return item.id === displayedOTAVersion.id;
            }

            return false;
          },
          currentValue: displayedOTAVersion?.id,
        },
      },
    ],
    onSubmit: async (_, data: { firmwareVersion: string }) => {
      const ota = availableOTAs.find((_ota) => _ota.id === data.firmwareVersion);
      if (!ota) {
        return;
      }

      postOTAJob({
        buildFile: ota.id,
        name: ota.id,
        version: ota.version,
        enabled: true,
        targetFilterParams: {
          serialNumbers: [device.id],
        },
        targetSelectionMode: 'filter',
      });
    },
  });

  const handleFirmwareAreaNavigation = () => navigate(getPath('FLEET_DEVICE_OTA_JOBS', { deviceId: device.id }));

  const handleFirmwareAreaClick = () => {
    if (!isDeviceOnline || hasPendingOTAUpdate || !canOTAUpdate) {
      handleFirmwareAreaNavigation();
      return;
    }

    currentOTAVersion && canOTAUpdate && openDialog(' ');
  };

  const renderCardSubtitle = useCallback(
    () => (
      <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <InfoOutlined sx={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6)', mr: 1 }} />
        <Typography
          variant="subtitle2"
          sx={(theme) => ({ pt: 0, color: alpha(theme.palette.common.black, 0.6) })}
          fontWeight={400}
        >
          {/* {t('lastUpdated')} {device?.updatedAt ? dayjs(device.updatedAt).fromNow().toString() : '--'} */}
        </Typography>
      </Box>
    ),
    []
  );
  return (
    <OverviewCard
      title={t('profile')}
      subtitle={renderCardSubtitle}
      cardContentProps={{ sx: { width: '100%', justifyContent: 'stretch', display: 'grid' } }}
      cardContainerProps={{ sx: { flexShrink: 1, flex: 'unset', display: 'flex', overflow: 'unset' } }}
      sx={{ position: 'relative' }}
    >
      <AreaRow sx={{ flexWrap: 'nowrap', flexBasis: '50%' }}>
        <DataBoundAreaRow sx={{ width: '50%', display: 'grid' }}>
          <OperatingMode
            device={device}
            isAdmin={isAdmin}
            isInLoadingState={isInLoadingState}
            isLoading={hasPendingOperatingMode}
            isDeviceOnline={device?.status.connection.online}
            postOperatingMode={postOperatingMode}
          />
        </DataBoundAreaRow>

        <DataBoundAreaRow
          sx={{
            width: '50%',
            display: 'grid',
            ...(Number(device?.supportedPowerProfiles?.length || 0) > 0 ? { gridTemplateColumns: '2fr 1fr' } : {}),
          }}
        >
          <PowerProfile
            isAdmin={isAdmin}
            serialNumber={device.serialNumber}
            operatingMode={device.operatingMode}
            powerProfile={device?.powerProfile}
            isInLoadingState={isInLoadingState}
            isDeviceOnline={device?.status.connection.online}
            postPowerProfile={postPowerProfile}
            isLoading={hasPendingPowerProfile}
            supportedPowerProfiles={device?.supportedPowerProfiles || []}
          />
        </DataBoundAreaRow>
      </AreaRow>
      <LogLevel
        device={device}
        postLogLevel={postLogLevel}
        isInLoadingState={isInLoadingState}
        isLoadingLogLevel={hasPendingLogLevel}
        isPollingLogLevel={false}
        requestLogLevel={requestedLogLevel}
      />
      <AddEditDialog />
      <ConfirmDialog
        isOpen={confirmDialogOpen}
        onConfirm={() =>
          postReboot({
            serialNumber: device.serialNumber,
          })
        }
        onClose={() => setConfirmDialogOpen(false)}
        MessageComponent={confirmDialogMessage}
      />

      <AreaRow sx={{ display: 'grid', gridTemplate: '1fr / 1fr 1fr' }}>
        <Firmware
          displayedOTAVersion={displayedOTAVersion}
          serialNumber={device.serialNumber}
          firmwareVersion={currentOTAVersion?.version || device.swVersion}
          tooltipMessage={otaTooltipMessage || ''}
          hasPendingOTAUpdate={hasPendingOTAUpdate}
          otaPollingRequestedAt={otaPollingRequestAt}
          openOTADialog={openDialog}
          canOTAUpdate={canOTAUpdate}
          currentOTAVersion={currentOTAVersion}
          isDeviceOnline={isDeviceOnline}
          handleFirmwareAreaClick={handleFirmwareAreaClick}
          handleFirmwareAreaNavigation={handleFirmwareAreaNavigation}
          info={info}
        />
        <DataBoundAreaRow sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifySelf: 'stretch' }}>
          <Status
            device={device}
            isDeviceOnline={isDeviceOnline}
            info={info}
            updatedAt={device.updatedAt}
            sx={{ display: 'flex', flex: 1 }}
          />
          <RenderIf condition={isRebootSupported || false}>
            <AreaButton
              disabled={[
                { disabled: !isDeviceOnline, reason: t('cantRebootDeviceOffline') as string },
                { disabled: isLoadingReboot, reason: t('rebootInProgress') as string },
                { disabled: device.operatingMode === 'Disabled', reason: t('cantRebootWhileDisabled') as string },
                { disabled: hasPendingCommmands, reason: t('pendingCommands') as string },
                { disabled: hasPendingOperatingModeUpdate, reason: t('pendingOperatingModeUpdate') as string },
              ]}
              color={operatingModeColors.Reboot}
              handler={() => setConfirmDialogOpen(true)}
              text={t('reboot')}
              Icon={RestartAltOutlinedIcon}
              isLoading={isLoadingReboot}
              sx={{ borderRadius: 1 }}
            />
          </RenderIf>
        </DataBoundAreaRow>
      </AreaRow>
    </OverviewCard>
  );
}
export default React.memo(ProfileCard);
