import {
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  Paper,
  Popper,
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import DateRangePicker from './DateRangePicker';
import { DateRange, DateRangeListItemProps, StoreDateRangeDefaultValue } from './typings';
import CheckIcon from '@mui/icons-material/Check';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(LocalizedFormat);
dayjs.extend(isLeapYear);

// ===

const DateRangeListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'current',
})<DateRangeListItemProps>(({ theme, current }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: current ? alpha(theme.palette.primary.light, 0.1) : 'transparent',
  minWidth: 200,
  transition: 'all 100ms ease-in-out',
  '&:hover': {
    background: theme.palette.grey['200'],
    cursor: 'pointer',
  },
}));

interface DateRangeProps extends TypographyProps {
  current?: boolean;
}

const DateRangeLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'current',
})<DateRangeProps>(({ current }) => ({
  fontWeight: current ? 600 : 400,
}));

// ===

const getDateRangeAsText = (range: DateRange<Dayjs>) => {
  return `${range.start?.locale(dayjs.locale()).format('L')} - ${range.end?.locale(dayjs.locale()).format('L')}`;
};
// ===

type DateRangeWithFixedRangesProps = {
  id: string;
  sx: SxProps<Theme>;
  defaultValue: StoreDateRangeDefaultValue;
  onChange: (event: any, dateRange: DateRange<number>) => void;
};

export default function DateRangeWithFixedRanges({ id, onChange, defaultValue, sx }: DateRangeWithFixedRangesProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [range, setRange] = useState<null | DateRange<Dayjs>>({
    start: dayjs(defaultValue.start),
    end: dayjs(defaultValue.end),
  });
  const [isCustomRange, setIsCustomRange] = useState<boolean>(false);

  useEffect(() => {
    if (!range || !range?.start || !range?.end) {
      return;
    }

    const dateRange: DateRange<number> = {
      start: range.start.valueOf(),
      end: range.end.valueOf(),
    };

    onChange(null, dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  useEffect(() => {
    if (!defaultValue || !defaultValue.start || !defaultValue.end) {
      return;
    }

    setRange({
      start: dayjs(defaultValue.start),
      end: dayjs(defaultValue.end),
    });
  }, [defaultValue]);

  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((open) => !open);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const idPopper = canBeOpen ? 'transition-popper' : undefined;

  const isCurrentRange = (unit: 'week' | 'month' | 'year' | 'days') => {
    if (!range && unit === 'days') {
      return true;
    }

    if (isCustomRange && unit === 'days') {
      return true;
    }

    if (isCustomRange) {
      return false;
    }

    if (!range || !range.start || !range.end) {
      return false;
    }

    const diff = range.end.diff(range.start, 'day');

    if (diff === 7 && unit === 'week') {
      return true;
    }

    if (diff >= 28 && diff <= 31 && unit === 'month') {
      return true;
    }

    const yearMaxValue =
      366 * Number(range.end.isLeapYear() || range.start.isLeapYear()) +
      365 * Number(!range.end.isLeapYear() && !range.start.isLeapYear());

    if (diff >= 364 && diff <= yearMaxValue && unit === 'year') {
      return true;
    }

    return false;
  };

  const handleDateRangeListItemClick = (range: DateRange<Dayjs> | null) => {
    if (!range || !range.end) {
      setIsCustomRange(true);
    }

    if (range) {
      setIsCustomRange(false);
    }

    setRange(range);
  };

  return (
    <Box id={id} sx={sx}>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: '1px solid',
          borderColor: theme.palette.grey['400'],
          borderRadius: 1,
          color: theme.palette.grey['700'],
          cursor: 'pointer',
          width: '100%',
          padding: theme.spacing(1.125),
          justifyContent: 'space-between',
          transition: 'all 100ms ease-in-out',
          ...(open && {
            outline: `2px solid ${theme.palette.primary.main}`,
            outlineOffset: -2,
          }),
          '&:hover': {
            borderColor: theme.palette.grey['900'],
            color: theme.palette.grey['900'],
          },
        }}
        onClick={handleOpenPopper}
      >
        <Box sx={{ height: theme.spacing(4.5), display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            color={range && range.start && range.end ? 'text.primary' : 'text.disabled'}
            sx={{
              paddingX: 0.9375,
              paddingTop: 0.5,
              paddingBottom: 0.625,
              fontSize: theme.spacing(2),
            }}
          >
            {range && range.start && range.end ? getDateRangeAsText(range) : t('selectRange')}
          </Typography>
        </Box>
        <CalendarMonthIcon color={'inherit'} />
      </Box>
      <Popper
        id={idPopper}
        open={open}
        anchorEl={anchorEl}
        transition
        sx={(theme) => ({ zIndex: theme.zIndex.mobileStepper })}
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ display: 'flex', overflow: 'hidden' }}>
                <Box display={'flex'} flexDirection="column" sx={{ py: 1 }}>
                  <DateRangeListItem
                    current={isCurrentRange('week')}
                    onClick={() =>
                      handleDateRangeListItemClick({
                        start: dayjs().subtract(1, 'week').startOf('day'),
                        end: dayjs().endOf('day'),
                      })
                    }
                  >
                    <DateRangeLabel variant="body1" current={isCurrentRange('week')}>
                      {t('lastWeek')}
                    </DateRangeLabel>
                    {isCurrentRange('week') && <CheckIcon color="success" />}
                  </DateRangeListItem>
                  <DateRangeListItem
                    current={isCurrentRange('month')}
                    onClick={() =>
                      handleDateRangeListItemClick({
                        start: dayjs().subtract(1, 'month').startOf('day'),
                        end: dayjs().endOf('day'),
                      })
                    }
                  >
                    <DateRangeLabel variant="body1" current={isCurrentRange('month')}>
                      {t('lastMonth')}
                    </DateRangeLabel>
                    {isCurrentRange('month') && <CheckIcon color="success" />}
                  </DateRangeListItem>
                  <DateRangeListItem
                    current={isCurrentRange('year')}
                    onClick={() =>
                      handleDateRangeListItemClick({
                        start: dayjs().subtract(1, 'year').startOf('day'),
                        end: dayjs().endOf('day'),
                      })
                    }
                  >
                    <DateRangeLabel variant="body1" current={isCurrentRange('year')}>
                      {t('lastYear')}
                    </DateRangeLabel>
                    {isCurrentRange('year') && <CheckIcon color="success" />}
                  </DateRangeListItem>
                  <DateRangeListItem
                    onClick={() => handleDateRangeListItemClick(null)}
                    current={isCurrentRange('days')}
                  >
                    <DateRangeLabel variant="body1" current={isCurrentRange('days')}>
                      {t('custom')}
                    </DateRangeLabel>
                    {isCurrentRange('days') && <CheckIcon color="success" />}
                  </DateRangeListItem>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box>
                  <DateRangePicker
                    range={range}
                    isCustomRange={isCustomRange}
                    isChoosingCustomRangeHandler={setIsCustomRange}
                    updateCustomRangeHandler={(range) => setRange(range)}
                  />
                </Box>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
}
