import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import { useHistoryTelemetryColumns } from './useHistoryTelemetryColumns';
import { useTranslation } from 'react-i18next';
import { TelemetryFiltersState } from '../../../typings';
import { HistoryTelemetry, MergedTelemetry } from '../../typings';
import { ApiResult } from '@typings';

export const TelemetryDataTable = ({
  telemetryFilters,
  telemetries,
  data,
  status,
}: {
  telemetryFilters: TelemetryFiltersState;
  telemetries: MergedTelemetry[] | undefined;
  data: ApiResult<{ items: HistoryTelemetry[] }> | undefined;
  status: {
    isFetching: boolean;
    isLoading: boolean;
  };
}) => {
  const { t } = useTranslation();
  const { columns, isLoading: isLoadingColumns } = useHistoryTelemetryColumns(
    telemetries || [],
    data?.data?.items || []
  );

  return (
    <GenericExportTable
      sx={{ minHeight: '500px', '@media print': { display: 'none' } }}
      isLoading={status.isLoading || status.isFetching || isLoadingColumns}
      title={t('telemetryHistory')}
      columns={
        (telemetryFilters?.key?.length || telemetryFilters?.tag?.length) && !isLoadingColumns && !status.isLoading
          ? columns
          : []
      }
      exportData={true}
      selection={false}
      data={
        (telemetryFilters?.key?.length || telemetryFilters?.tag?.length) && !status.isLoading
          ? data?.data?.items
              ?.map((i, index) => ({ ...i, id: index.toString() }))
              ?.sort((a, b) => b?.lastUpdate - a?.lastUpdate) || []
          : []
      }
      options={{
        pageSize: 20,
        sorting: true,
        pageSizeOptions: [20, 50, 100],
        headerStyle: {
          fontSize: '1rem',
          position: 'sticky',
          top: 0,
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          backgroundColor: 'rgb(242, 242, 242)',
          zIndex: 10,
        },
        maxBodyHeight: '500px',
        overflowY: 'scroll',
      }}
    />
  );
};
