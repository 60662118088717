import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryTelemetry, MergedTelemetry } from '../../typings';

type TelemetryColumn = object & {
  id: string;
  field: string;
  title: string;
  render?: (telemetrySnapshot: object) => ReactNode;
  exportTransformer?: (telemetrySnapshot: object) => string;
};

export const useHistoryTelemetryColumns = (telemetries: MergedTelemetry[], history: HistoryTelemetry[]) => {
  const getLastUpdateColumn = (index: number = 0) => {
    return {
      title: t('lastUpdated'),
      field: 'lastUpdate',
      id: index.toString(),
      render: (telemetrySnapshot: { id: string; lastUpdate: number }): ReactNode => {
        return (
          <>
            <Typography>{dayjs(telemetrySnapshot.lastUpdate).format('L')}</Typography>
            <Typography variant="caption">{dayjs(telemetrySnapshot.lastUpdate).fromNow()}</Typography>
          </>
        );
      },
      exportTransformer: (telemetrySnapshot: { id: string; lastUpdate: number }) =>
        dayjs(telemetrySnapshot.lastUpdate).format('L LTS'),
    };
  };
  const { t } = useTranslation();
  const [columns, setColumns] = useState([getLastUpdateColumn()] as TelemetryColumn[]);
  const [isLoading, setIsLoading] = useState(true);

  const getTelemetryName = (telemetryId: string) => {
    let label = null;
    telemetries.forEach((telemetry) => {
      if (telemetry?.id === telemetryId) {
        label = telemetry?.name;
        return;
      }
      if (telemetry?.items?.length) {
        telemetry.items.forEach((item) => {
          if (item.id === telemetryId) {
            label = item.name;
            return;
          }
        });
      }
    });
    return label ? `${telemetryId?.toUpperCase()} - ${label}` : telemetryId;
  };

  useEffect(() => {
    const getColumns = async () => {
      return new Promise((resolve) => {
        const uniqueHistoryTelemetryKeys = [] as string[];
        const columnsConfigs = [];
        if (!telemetries?.length || !history?.length) {
          resolve([getLastUpdateColumn()]);
        }

        let i = 0;

        for (i; i < history.length; i++) {
          const keys = Object.keys(history[i]);
          let j = 0;

          for (j; j < keys.length; j++) {
            const key = keys[j];
            if (uniqueHistoryTelemetryKeys.indexOf(key) !== -1) {
              continue;
            }
            const isLastUpdate = key === 'lastUpdate'; // could be good to lock this key with a constant.
            const telemetryName = !isLastUpdate ? getTelemetryName(key) : t('date');

            const column = isLastUpdate
              ? getLastUpdateColumn(i)
              : {
                  id: i.toString(),
                  title: telemetryName,
                  field: key,
                };
            uniqueHistoryTelemetryKeys.push(key);
            columnsConfigs.push(column);
          }
        }

        resolve(columnsConfigs);
      });
    };

    getColumns().then((cols) => {
      setColumns(cols as TelemetryColumn[]);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(history), JSON.stringify(telemetries)]);

  return { columns, isLoading };
};
