import { Box } from '@mui/material';
import React, { useContext } from 'react';
import OtaJobsTable from '@/components/Fleet/Devices/DevicePanel/OtaJobsPanel/OtaJobsTable';
import { DeviceContext } from './DeviceContext';
import { useGetOTAJobsDevicesListQuery } from '@/redux/api/admin/otaApiSlice';

export default function OtaJobs() {
  const context = useContext(DeviceContext);
  const otaJobsResult = useGetOTAJobsDevicesListQuery(context?.data?.id || '', { skip: !context || !context.data });

  return (
    <Box>
      <OtaJobsTable items={otaJobsResult.data?.data?.items || []} />
    </Box>
  );
}
