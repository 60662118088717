import { SCATTER_PROPS } from '@/components/Shared/Charts/constants';
import { alpha } from '@mui/material';
import { isDatum } from './helpers/isDatum';
import { Range } from './helpers/getRanges';

import { VictoryArea, VictoryLine, VictoryScatter } from 'victory';
import { Dispatch, SetStateAction } from 'react';
import { Colors, Datum, GroupDatum, Multiline } from './typings';

export const getLines = (
  plottableData: Multiline,
  ranges: Range[],
  colors: Colors,
  setNames: Dispatch<SetStateAction<string[]>>
) => {
  const _names: string[] = [];
  if (plottableData && Object.keys(plottableData).length > 0) {
    let Lines = Object.keys(plottableData)
      .flatMap((key) => {
        if (isDatum(plottableData[key])) {
          const _data = plottableData[key] as Datum[];
          _names.push(key);
          const range = ranges.find((range) => range.id === key);
          if (!_data?.length || _data?.length === 0) {
            return [{ Line: <></> }];
          }

          return [
            {
              Area: range?.min && range?.max && (
                <VictoryArea
                  key={`${key}~~Area`}
                  name={`${key}~~Area`}
                  style={{
                    data: {
                      ...(colors ? { fill: alpha(colors[key] as string, 0.2) as string } : {}),
                      opacity: 0,
                    },
                  }}
                  data={[
                    { x: Number(_data[0].x), y: Number(range.max || 0), y0: Number(range.min || 0) },
                    { x: Number(_data[_data.length - 1].x), y: Number(range.max || 0), y0: Number(range.min || 0) },
                  ]}
                />
              ),
            },
            {
              Line: (
                <VictoryLine
                  padding={10}
                  name={`${key}~~Line`}
                  style={{
                    data: {
                      ...(colors ? { stroke: colors[key] as string } : {}),
                      strokeWidth: 6,
                      strokeLinecap: 'round',
                    },
                  }}
                  interpolation="cardinal"
                  data={_data.filter((v) => v.x && v.y)}
                  key={`${key}~~Line`}
                />
              ),
            },
            {
              Scatter: (
                <VictoryScatter
                  padding={10}
                  name={`${key}~~Scatter`}
                  style={{
                    data: {
                      ...(colors ? { stroke: colors[key] as string } : {}),
                      pointerEvents: 'none',
                      strokeWidth: 6,
                      strokeLinecap: 'round',
                    },
                  }}
                  data={_data.filter((v) => v.x && v.y)}
                  key={`${key}~~Scatter`}
                />
              ),
            },
          ];
        }

        const _data = plottableData[key] as GroupDatum;
        if (!plottableData[key]) {
          return [];
        }

        return Object.keys(_data).flatMap((k) => {
          const color = (colors?.[key] as { [key in string]: string })[k];
          _names.push(`${key}~~${k}`);
          const range = ranges.find((range) => range.id === k);
          if (!_data[k]?.length || _data[k]?.length === 0) {
            return [{ Line: <></> }];
          }
          return [
            {
              Area: range?.min && range?.max && (
                <VictoryArea
                  key={`${key}~~${k}~~Area`}
                  name={`${key}~~${k}~~Area`}
                  style={{
                    data: {
                      ...(colors ? { fill: alpha(color as string, 0.2) as string } : {}),
                      opacity: 0,
                    },
                  }}
                  data={[
                    { x: Number(_data[k][0].x), y: Number(range.max || 0), y0: Number(range.min || 0) },
                    {
                      x: Number(_data[k][_data[k].length - 1].x),
                      y: Number(range.max || 0),
                      y0: Number(range.min || 0),
                    },
                  ]}
                />
              ),
            },
            {
              Line: (
                <VictoryLine
                  padding={10}
                  name={`${key}~~${k}~~Line`}
                  style={{
                    data: {
                      ...(colors ? { stroke: color } : {}),
                      strokeWidth: 6,
                      strokeLinecap: 'round',
                    },
                  }}
                  interpolation="cardinal"
                  data={_data[k].filter((v) => v.x && v.y)}
                  key={`${key}~~${k}~~Line`}
                />
              ),
            },
            {
              Scatter: (
                <VictoryScatter
                  {...SCATTER_PROPS}
                  data={_data[k].filter((v) => v.x && v.y)}
                  style={{
                    ...SCATTER_PROPS.style,
                    data: {
                      ...SCATTER_PROPS.style?.data,
                      ...(colors ? { stroke: color } : {}),
                      fill: color,
                    },
                  }}
                  padding={10}
                  name={`${key}~~${k}~~Scatter`}
                  key={`${key}~~${k}~~Scatter`}
                />
              ),
            },
          ];
        });
      })
      .sort((curr) => {
        if (curr.Area) {
          return -1;
        }

        if (curr.Scatter) {
          return 0;
        }

        return 1;
      })
      .flatMap((v) => [v.Area || v.Line || v.Scatter]);

    setNames(_names);
    return Lines;
  }
};
