import DeviceTelemetryPanel from '@/components/Fleet/Devices/DevicePanel/TelemetryPanel';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';

export default function Telemetry() {
  const context = useContext(DeviceContext);

  if (!context) {
    return <></>;
  }

  return <DeviceTelemetryPanel data={context.data?.telemetry} />;
}
