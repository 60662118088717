import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import DevicesLocationMap from './DevicesLocationMap';
import { useNavigate } from 'react-router';
import BaseTabsLayout from '@/components/Shared/TabsLayout/BaseTabsLayout';

const FleetLocationsCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const config: TabConfig[] = [
    {
      id: 'devices_locations',
      label: t('devicesLocations'),
      useHash: true,
      ContentComponent: <DevicesLocationMap />,
    },
  ];

  return (
    <Box flex={1} display="flex" flexDirection="column" mt={'2rem'} position="relative">
      <Box position="absolute" mt={1} sx={{ ml: '100%', transform: 'translatex(-100%)', zIndex: 1 }}>
        <Button variant="text" onClick={() => navigate('/fleet/devices')} sx={{ whiteSpace: 'nowrap' }}>
          {t('seeAll')}
        </Button>
      </Box>
      <BaseTabsLayout config={config} mt="1rem" />
    </Box>
  );
};

export default FleetLocationsCard;
