import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { datadogRum } from '@datadog/browser-rum';
import APP_CONFIG from './appConfig';

if (APP_CONFIG.environment === 'tst' || APP_CONFIG.environment === 'prd') {
  datadogRum.init({
    applicationId: 'fdbcbe67-e264-44be-8c23-2849c66c31ec',
    clientToken: 'pub5ff0c452d8226a9ca14628c2cbc05282',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us3.datadoghq.com',
    service: 'one-dashboard',
    env: APP_CONFIG.environment,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: APP_CONFIG.appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*\.culliganiot\.com/],
  });
}

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
