import { AREA_DESIGN_TOKENS } from '@/components/Shared/Card/Area';
import { OperatingModeCommandPayload } from '@/redux/api/admin/deviceCommandsApiSlice';
import { getSupportedOperatingModeActions } from '@/shared/validations';
import CommentsDisabledOutlinedIcon from '@mui/icons-material/CommentsDisabledOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { Box } from '@mui/material';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { NotEmptyList } from '@typings';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from '../../../DevicesPanel/typings';
import LoadingFeedback from '../../ConsumablesPanel/LoadingFeedback';
import AreaButton from '@/components/Shared/Card/Area/AreaButton';
import { useOperatingModeColors } from './useOperatingModeColors';
import React from 'react';
import RenderIf from '@/components/Shared/RenderIf/RenderIf';
import { useSelector } from 'react-redux';
import { AppState } from '@/redux/store';
import { selectPendingOperatingMode } from '@/redux/slices/deviceSlice';
import { OperatingMode } from '@culligan-iot/domain/culligan/one/device';
function CommandsButtonGroup({
  serialNumber,
  operatingMode,
  supportedOperatingModes,
  isDeviceOnline,
  isInLoadingState,
  postOperatingMode,
}: {
  serialNumber: Device['serialNumber'];
  operatingMode: OperatingMode;
  supportedOperatingModes: NotEmptyList<OperatingMode>;
  isDeviceOnline: boolean;
  isInLoadingState: boolean;
  postOperatingMode: MutationTrigger<OperatingModeCommandPayload & any>;
}) {
  const { t } = useTranslation();
  const operatingModeColors = useOperatingModeColors();
  const pendingOperatingModeCommand = useSelector((state: AppState) => selectPendingOperatingMode(state, serialNumber));
  const isLoading = pendingOperatingModeCommand?.command === 'operating_mode.set';
  const isOperatingModeDisabled = operatingMode === 'Disabled';
  const supportedActions = useMemo(() => getSupportedOperatingModeActions(operatingMode), [operatingMode]);
  const canSetOperatingModeEnable = supportedActions.includes('ENABLE');
  // const canSetOperatingModeDisable = supportedActions.includes('DISABLE');
  const identitySupportsDisabledOperatingMode = useMemo(
    () => supportedOperatingModes.includes('Disabled') && supportedOperatingModes.includes('Standard'),
    [supportedOperatingModes]
  );
  const { internalActionGap } = AREA_DESIGN_TOKENS;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: internalActionGap,
        flexGrow: 1,
        position: 'relative',
        gridColumnStart: 3,
        gridRowStart: 1,
        gridRowEnd: -1,
      }}
    >
      <LoadingFeedback loading={isLoading} />
      <RenderIf condition={identitySupportsDisabledOperatingMode}>
        <AreaButton
          sx={{
            borderRadius: 1,
          }}
          disabled={[
            {
              disabled: !isDeviceOnline,
              reason: t('deviceOffline') as string,
            },
            { disabled: isLoading, reason: t('operatingModeChangeInProgress') as string },
            { disabled: isInLoadingState, reason: t('pendingCommands') as string },
            {
              disabled: isOperatingModeDisabled && !canSetOperatingModeEnable,
              reason: t('notSupported') as string,
            },
            /*
            Temporary disable the enforce of the operating mode path.
            {
              disabled: !isOperatingModeDisabled && !canSetOperatingModeDisable,
              reason: t('notSupported') as string,
            },
            */
          ]}
          color={() => (isOperatingModeDisabled ? operatingModeColors.Startup : operatingModeColors.Disabled)}
          handler={() => {
            postOperatingMode({
              serialNumber: serialNumber,
              operatingMode: isOperatingModeDisabled ? 'Standard' : 'Disabled',
            });
          }}
          Icon={isOperatingModeDisabled ? PlayCircleFilledWhiteOutlinedIcon : CommentsDisabledOutlinedIcon}
          text={operatingMode === 'Disabled' ? t('enable') : t('disable')}
        />
      </RenderIf>
    </Box>
  );
}
export default React.memo(CommandsButtonGroup);
