import { BoxProps } from '@mui/material';
import { TabsProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { TabConfig } from './typings';
import { useLocation, useNavigate } from 'react-router';
import BaseTabsLayout from './BaseTabsLayout';

function NavigationTabsLayout({
  config,
  orientation = 'horizontal',
  navigateOnChange = true,
  defaultIndex = undefined,
  ...props
}: TabsProps & {
  config: TabConfig[];
  navigateOnChange?: boolean;
  defaultIndex?: number;
} & BoxProps) {
  const [_defaultIndex, setDefaultIndex] = useState<number | undefined>(defaultIndex);
  const { state, hash } = useLocation();
  const navigate = useNavigate();
  const useHash = config.every((tab) => tab.useHash);

  useEffect(() => {
    setDefaultIndex(defaultIndex);
  }, [defaultIndex]);

  useEffect(() => {
    if (!useHash) {
      return;
    }

    if (hash) {
      const index = config.findIndex(
        (tab) => tab.label.replace(/\s/g, '_').toLowerCase() === hash.match(/#([^?]+)/)?.[1]
      );

      if (index === -1) {
        return;
      }
      setDefaultIndex(index);
    } else if (!hash) {
      setDefaultIndex(0);
    }
  }, [hash, config, state, useHash]);

  const handleTabChange = (nextIndex: any) => {
    const _config = config[nextIndex];

    if (!_config.useHash && _config.onNavigate) {
      _config.onNavigate();
      return;
    }

    navigateOnChange &&
      navigate(window.location.pathname + '#' + config[nextIndex].label.replace(/\s/g, '_').toLowerCase());
    // eslint-disable-next-line no-restricted-globals
    !navigateOnChange && history.pushState(null, '', `#${config[nextIndex].label.replace(/\s/g, '_').toLowerCase()}`);
  };

  return <BaseTabsLayout config={config} defaultIndex={_defaultIndex} handleTabChange={handleTabChange} {...props} />;
}

export default NavigationTabsLayout;
