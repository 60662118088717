import { Column } from '@material-table/core';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ConsumableRes } from './typings';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { getPath } from '@/shared/utils';

export default function useConsumablesColumns(itemsInDeviceIdentity?: ConsumableRes[]): Array<Column<ConsumableRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data_columns_array: Array<Column<ConsumableRes>> = [
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('type'),
      field: 'kind',
      export: false,
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (consumable) => dayjs(consumable.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (consumable) => dayjs(consumable.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
  ];

  const actions_columns_array: Array<Column<ConsumableRes>> = itemsInDeviceIdentity
    ? [
        {
          title: t('actions'),
          width: '10%',
          render: (consumable) => (
            <Box display="flex" justifyContent="flex-start" gap="0.5rem">
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  navigate(getPath('ONETOOL_CHANNELS_CONSUMABLES'), {
                    state: {
                      searchText: consumable.name,
                      highlight: {
                        refField: 'id',
                        matchValue: consumable.id,
                      },
                    },
                  })
                }
              >
                {t('view')}
              </Button>
            </Box>
          ),
          sorting: false,
        },
      ]
    : [];

  return [...data_columns_array, ...actions_columns_array];
}
