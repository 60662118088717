import { useGetDeviceIdentitiesQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import { useDeviceIdentityColumns } from './useDeviceIdentityColumns';
import { DeviceIdentityRes } from './typings';
import { useTranslation } from 'react-i18next';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ROUTES } from '@/shared/constants';
import React, { useDeferredValue } from 'react';

export const DeviceIdentitiesTable = React.memo(
  withErrorLoadingManagement(withResetNavigationState(GenericExportTable<DeviceIdentityRes>))
);

function DeviceIdentitiesPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetDeviceIdentitiesQuery(undefined, { refetchOnMountOrArgChange: false });
  const columns = useDeviceIdentityColumns();

  const Table = useDeferredValue(() => (
    <DeviceIdentitiesTable
      title={t(ROUTES.ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
    />
  ));

  return <Table />;
}

export default React.memo(DeviceIdentitiesPanel);
