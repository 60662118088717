import StatisticsCard from '../Shared/StatisticsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import withMissingDataManagement from './withMissingDataManagement';
import PieChart from '../Shared/Charts/PieChart';
import { FiltersContext } from './FiltersContext';
import { useContext } from 'react';
import { useGetOverviewQuery } from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';

const SafePieChart = withMissingDataManagement(PieChart);

export default function WaterTypesCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const filters = useContext(FiltersContext);
  const { data } = useGetOverviewQuery(filters);
  const filteredData = data?.data.items;

  return (
    <StatisticsCard
      variant="boxed"
      title={t('waterTypes')}
      action={{
        label: t('details'),
        fn: () => navigate('/'),
      }}
      tooltipText={t('waterTypesTooltip') || ''}
    >
      <SafePieChart
        hasData={!!(filteredData && filteredData.waterTypes && filteredData.waterTypes.value.length > 0)}
        data={filteredData?.waterTypes?.value.map((waterType) => ({ x: waterType.label, y: waterType.value }))}
      />
    </StatisticsCard>
  );
}
