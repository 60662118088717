import useEventsColumns from './useEventsColumns';
import { useGetEventsQuery } from '@/redux/api/system/eventsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { EventRes } from './typings';
import { useTranslation } from 'react-i18next';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ROUTES } from '@/shared/constants';

export const EventsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<EventRes>));

export default function EventsPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetEventsQuery();
  const columns = useEventsColumns();

  return (
    <EventsTable
      title={t(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
      exportData={true}
      selection={true}
    />
  );
}
