import { Box, Button, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useGetDeviceIdentityQuery } from '@/redux/api/system/deviceIdentitiesApiSlice';
import useIsTablet from '../../../hooks/useIsTablet';
import DeviceIdentityPanel from '@/components/OneTools/Ecosystem/DeviceIdentities/DeviceIdentityPanel';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { useLayoutEffect, useMemo } from 'react';
import Page404 from '@/router/core/404';
import LoadingMessage from '@/components/Shared/withErrorLoadingManagement/LoadingMessage';
import FixedToolbar, { scrolledAmountIsGreaterThan } from '@/components/Shared/Toolbar';
import { getPath } from '@/shared/utils';

const DeviceIdentity = () => {
  const { t } = useTranslation();
  const { deviceIdentityId } = useParams();
  const navigate = useNavigate();
  const isTablet = useIsTablet();

  const { data, isLoading, isError } = useGetDeviceIdentityQuery(deviceIdentityId!, { skip: !deviceIdentityId });

  useLayoutEffect(() => {
    const beforeUnloadListener = () => {
      return isLoading && <LoadingMessage />;
    };
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  });

  const DeviceIdentityComponent = useMemo(() => {
    const toolbarBody = (
      <Box
        display="flex"
        flexDirection={isTablet ? 'column' : 'row'}
        justifyContent={'space-between'}
        alignItems={isTablet ? 'flex-start' : 'center'}
      >
        <Button
          id="backToList"
          variant="text"
          onClick={() => navigate(getPath('ONETOOL_ECOSYSTEM'))}
          sx={{ mb: isTablet ? '1rem' : 0 }}
          startIcon={<ArrowCircleLeftIcon />}
        >
          {t('backToList')}
        </Button>

        <Box display="flex" ml={isTablet ? 0 : '4rem'}>
          <Button
            id="setInterventions"
            variant="outlined"
            disabled={false}
            startIcon={<SystemUpdateAltIcon sx={{ mr: '0.6rem' }} />}
            onClick={() => window.print()}
          >
            {t('export')}
          </Button>
        </Box>
      </Box>
    );

    return (
      <>
        <FixedToolbar
          event={'scroll'}
          slideInPredicate={scrolledAmountIsGreaterThan(100)}
          body={toolbarBody}
          sx={{ '@media print': { display: 'none' } }}
        />

        {!isLoading ? (
          <>
            {data?.data?.id !== deviceIdentityId ? (
              <Page404 />
            ) : (
              <>
                <Card
                  sx={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: 1,
                    '@media print': { display: 'none' },
                  }}
                  elevation={1}
                >
                  {toolbarBody}
                </Card>

                <DeviceIdentityPanel deviceIdentity={data?.data} isLoading={isLoading} isError={isError} />
              </>
            )}
          </>
        ) : (
          <LoadingMessage />
        )}
      </>
    );
  }, [data?.data, deviceIdentityId, isError, isLoading, isTablet, navigate, t]);

  useLayoutEffect(() => {
    const beforePrintListener = () => {
      document.body.id = 'print';
    };
    const afterPrintListener = () => {
      document.body.id = '';
    };

    if (window) {
      window.addEventListener('beforeprint', beforePrintListener);
      window.addEventListener('afterprint', afterPrintListener);
    }

    return () => {
      window.removeEventListener('beforeprint', beforePrintListener);
      window.removeEventListener('afterprint', afterPrintListener);
    };
  }, []);

  return <>{DeviceIdentityComponent}</>;
};
export default DeviceIdentity;
