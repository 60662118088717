import { LoaderFunctionArgs, redirect } from 'react-router';
import AlarmsAndErrorsPage from '../pages/Fleet/AlarmsAndErrors';
import Consumables from '../pages/Fleet/Consumables';
import Devices from '../pages/Fleet/Devices/index';
import Overview from '../pages/Fleet/Overview';
import ScrollTopOutlet from '../core/ScrollTopOutlet';
import DevicesOtherTwoTone from '@mui/icons-material/DevicesOtherTwoTone';
import { getPath } from '@/shared/utils';
import { APP_ROUTE_ID, ROUTES } from '@/shared/constants';
import ConsumablesPanel from '../../components/Fleet/Consumables/ConsumablesPanel';
import DevicePage from '../pages/Fleet/Devices/Device';
import List from '../pages/Fleet/Devices/List';
import DevicesMap from '../pages/Fleet/Devices/MapDevices';
import DeviceOverview from '../pages/Fleet/Devices/Device/Overview';
import Alarms from '../pages/Fleet/Devices/Device/Alarms';
import Errors from '../pages/Fleet/Devices/Device/Errors';
import Events from '../pages/Fleet/Devices/Device/Events';
import DeviceConsumables from '../pages/Fleet/Devices/Device/Consumables';
import Telemetry from '../pages/Fleet/Devices/Device/Telemetry';
import OtaJobs from '../pages/Fleet/Devices/Device/OtaJobs';
import LoyaltyPrograms from '../pages/Fleet/Devices/Device/LoyaltyPrograms';
import AlarmsPanel from '@/components/Fleet/AlarmsAndErrors/Alarms/AlarmsPanel';
import ErrorsPanel from '@/components/Fleet/AlarmsAndErrors/Errors/ErrorsPanel';
import { Kind } from '@culligan-iot/domain/culligan/one/consumable';
import { FleetConsumables } from '@/components/Fleet/Consumables/ConsumablesPanel/typings';

const FleetRoutes = {
  path: `/${ROUTES.FLEET.fragment}`,
  label: 'Fleet',
  element: <ScrollTopOutlet />,
  icon: <DevicesOtherTwoTone />,
  children: [
    {
      index: true,
      loader: () => redirect(getPath('FLEET_OVERVIEW')),
    },
    {
      path: ROUTES.FLEET_OVERVIEW.fragment,
      label: 'Overview',
      element: <Overview />,
    },
    {
      path: ROUTES.FLEET_CONSUMABLES.fragment,
      element: <Consumables />,
      label: 'Consumables',
      children: [
        {
          index: true,
          loader: () =>
            redirect(getPath(`FLEET_CONSUMABLES_${FleetConsumables.INITIAL_KIND.toUpperCase()}` as APP_ROUTE_ID)),
        },
        ...Object.values(Kind.enums).map((kind) => {
          const routeId = `FLEET_CONSUMABLES_${kind.toUpperCase()}`;
          const path = ROUTES[routeId as APP_ROUTE_ID].fragment;
          return {
            path,
            element: <ConsumablesPanel kind={path as Kind} />,
          };
        }),
      ],
    },
    {
      path: ROUTES.FLEET_DEVICES.fragment,
      label: 'Devices',
      element: <Devices />,
      children: [
        {
          index: true,
          loader: () => redirect(getPath('FLEET_DEVICES_LIST')),
        },
        {
          path: ROUTES.FLEET_DEVICES_LIST.fragment,
          element: <List />,
        },
        {
          path: ROUTES.FLEET_DEVICES_MAP.fragment,
          element: <DevicesMap />,
        },
        {
          path: ROUTES.FLEET_DEVICE.fragment,
          element: <DevicePage />,
          children: [
            {
              index: true,
              loader: ({ params }: LoaderFunctionArgs) =>
                redirect(getPath('FLEET_DEVICE_OVERVIEW', { deviceId: params.deviceId ?? null })),
            },
            {
              path: ROUTES.FLEET_DEVICE_OVERVIEW.fragment,
              element: <DeviceOverview />,
            },
            {
              path: ROUTES.FLEET_DEVICE_ALARMS.fragment,
              element: <Alarms />,
            },
            {
              path: ROUTES.FLEET_DEVICE_ERRORS.fragment,
              element: <Errors />,
            },
            {
              path: ROUTES.FLEET_DEVICE_EVENTS.fragment,
              element: <Events />,
            },
            {
              path: ROUTES.FLEET_DEVICE_CONSUMABLES.fragment,
              element: <DeviceConsumables />,
            },
            {
              path: ROUTES.FLEET_DEVICE_TELEMETRY.fragment,
              element: <Telemetry />,
            },
            {
              path: ROUTES.FLEET_DEVICE_OTA_JOBS.fragment,
              element: <OtaJobs />,
            },
            {
              path: ROUTES.FLEET_DEVICE_LOYALTY_PROGRAMS.fragment,
              element: <LoyaltyPrograms />,
            },
          ],
        },
      ],
    },
    {
      path: ROUTES.FLEET_ALARMS_ERRORS.fragment,
      element: <AlarmsAndErrorsPage />,
      label: 'Alarms and errors',
      children: [
        {
          index: true,
          loader: () => redirect(getPath('FLEET_ALARMS')),
        },
        {
          path: ROUTES.FLEET_ALARMS.fragment,
          element: <AlarmsPanel />,
        },
        {
          path: ROUTES.FLEET_ERRORS.fragment,
          element: <ErrorsPanel />,
        },
      ],
    },
    // TODO
    // {
    //   path: 'interventions',
    //   element: <Interventions />,
    //   label: 'Interventions',
    // },
    // {
    //   path: 'glossary',
    //   element: <Glossary />,
    //   label: 'Glossary',
    // },
  ],
};

export default FleetRoutes;
