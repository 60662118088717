import { Box, Button, Typography, TypographyProps } from '@mui/material';
import { StatisticsCardProps } from './typings';
import TypographyWithTooltip from '../Tooltip';
import { CulliganCard } from '../Card';

const StatisticsCard = ({ title, variant, action, children, tooltipText }: StatisticsCardProps) => {
  const header = action && (
    <Box display="flex" justifyContent={'space-between'} alignItems={'flex-start'} width="100%">
      {tooltipText ? (
        <TypographyWithTooltip
          anchorText={title}
          tooltipText={tooltipText}
          slots={{
            AnchorTextTypography: ({ children }: TypographyProps) => (
              <Typography variant="h6" sx={{ pt: 0 }} fontWeight={400}>
                {children}
              </Typography>
            ),
          }}
        />
      ) : (
        <Typography variant="h6" sx={{ pt: 0 }} fontWeight={400}>
          {title}
        </Typography>
      )}
      <Button variant="text" size="small" onClick={() => action.fn()}>
        {action.label}
      </Button>
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        '@media print': {
          breakInside: 'avoid',
        },
      }}
    >
      {variant === 'boxed' ? (
        <CulliganCard sx={{ p: 2, overflow: 'visible', height: 400, position: 'relative' }} header={header}>
          {children}
        </CulliganCard>
      ) : variant === 'bigBoxed' ? (
        <CulliganCard header={header} sx={{ p: 2, overflow: 'visible', position: 'relative' }}>
          {children}
        </CulliganCard>
      ) : (
        children
      )}
    </Box>
  );
};
export default StatisticsCard;
