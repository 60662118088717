import { ConfigsTable } from '@/components/OneTools/Channels/Configs';
import { ROUTES } from '@/shared/constants';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityContext } from '../DeviceIdentityContext';
import useConfigColumns from '@/components/OneTools/Channels/Configs/useConfigColumns';

export default function Configs() {
  const { t } = useTranslation();
  const deviceIdentity = useContext(DeviceIdentityContext);
  const configsColumns = useConfigColumns(deviceIdentity?.configs);

  return (
    <ConfigsTable
      title={t(ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment)}
      data={deviceIdentity?.configs ?? []}
      columns={configsColumns}
      isLoading={false}
      isError={false}
      resetStateButtonVisible={false}
      exportData={false}
      selection={false}
    />
  );
}
