import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSize from '@/hooks/useSize';
import { findDomain } from './helpers/findDomain';
import { getColor } from './helpers/color';
import { getPlottableData } from './helpers/plottableData';
import { getLines } from './Line';
import { getRanges } from './helpers/getRanges';
import { useTelemetryFiltersCtx, useDeviceTelemetryCtx } from '../../TelemetryProvider';

import { Box, Chip, Paper, Typography, useTheme } from '@mui/material';
import { HistoryTelemetry } from '../../typings';
import { VictoryAxis, VictoryChart, VictoryTheme, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import Flyout from '../../../../../../Shared/Charts/Flyout';
import { Datum, Domain, GroupDatum } from './typings';

const cropString = (value: string, length: number) => {
  if (String.length > length - 3) {
    return `${value.substring(0, 17)}...`;
  }

  return value;
};

function isGroupDatum(value: GroupDatum | Datum[]): value is GroupDatum {
  return typeof value === 'object' && value !== null && Object.values(value).every(Array.isArray);
}

const hasNonZeroValues = (data: Datum[] | GroupDatum): boolean => {
  if (!data) return false;

  if (Array.isArray(data)) {
    return data.some((point) => point.y !== 0 && point.x !== 0);
  } else {
    return Object.values(data).some((subData) => subData.some((point) => point.y !== 0 && point.x !== 0));
  }
};

export default function TelemetryChart({ data }: { data: HistoryTelemetry[] }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { telemetries: telemetryFilters } = useTelemetryFiltersCtx();
  const { telemetries: deviceTelemetries } = useDeviceTelemetryCtx();
  const [names, setNames] = useState<string[]>([]);

  const chartContainerRef = useRef<HTMLBaseElement | null>(null);
  const size = useSize(chartContainerRef.current);

  const plottableData = useMemo(() => getPlottableData(telemetryFilters, data), [telemetryFilters, data]);
  const colors = useMemo(() => getColor(telemetryFilters), [telemetryFilters]);
  const ranges = useMemo(() => getRanges(deviceTelemetries), [deviceTelemetries]);
  const Lines = useMemo(
    () => getLines(plottableData, ranges, colors, setNames),
    [colors, plottableData, ranges, setNames]
  );
  const _domain = useMemo(() => {
    const domain = { padding: 10 };
    if ((domain as Domain)?.tuple) {
      return (domain as Domain)?.tuple;
    }

    return findDomain(plottableData, (domain as Domain)?.padding);
  }, [plottableData]);

  return (
    <Paper sx={{ p: 2, '@media print': { boxShadow: 0, padding: 0 } }}>
      <Box>
        {Object.keys(colors || {}).length > 0 && (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
              '@media print': { printColorAdjust: 'exact', WebkitPrintColorAdjust: 'exact' },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                '@media print': { display: 'block', lineHeight: 1, marginBottom: '5px' },
              }}
            >
              {t('legend')}
            </Typography>
            <Box display="flex" gap={2} alignItems="start" flexWrap="wrap">
              {Object.keys(colors || {})
                .filter((key) => typeof colors?.[key] !== 'string')
                .map((key) => {
                  return (
                    <Box key={key + 'Legend'}>
                      <Typography fontWeight={'bold'}>{key}</Typography>
                      <Box display="flex" gap={0.5} flexDirection="column">
                        {Object.keys(colors?.[key] || {})
                          .filter((k) => {
                            const groupData = plottableData[key];
                            if (!isGroupDatum(groupData)) return false;
                            return hasNonZeroValues(groupData[k]);
                          })
                          .map((k) => (
                            <Box key={key + k + 'Legend'} display="flex" alignItems="center" gap={1}>
                              <Chip
                                icon={
                                  <Box
                                    width={15}
                                    height={15}
                                    bgcolor={(colors?.[key] as { [x: string]: string })?.[k]}
                                    sx={{
                                      borderRadius: 2,
                                      border: '1px solid white',
                                      '@media print': {
                                        bgcolor: (colors?.[key] as { [x: string]: string })?.[k],
                                        printColorAdjust: 'exact',
                                        WebkitPrintColorAdjust: 'exact',
                                      },
                                    }}
                                  ></Box>
                                }
                                size="small"
                                label={k}
                              />
                              <Typography>
                                {deviceTelemetries?.find((t) => t.id === key)?.items?.find((t) => t.id === k)?.name ||
                                  ''}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  );
                })}
              <Box>
                {Object.keys(colors || {}).filter(
                  (key) =>
                    typeof colors?.[key] === 'string' && plottableData[key] && hasNonZeroValues(plottableData[key])
                ).length > 0 && <Typography fontWeight={'bold'}>{t('singles')}</Typography>}
                <Box display="flex" gap={0.3} flexDirection="column">
                  {Object.keys(colors || {})
                    .filter(
                      (key) =>
                        typeof colors?.[key] === 'string' && plottableData[key] && hasNonZeroValues(plottableData[key])
                    )
                    .map((key) => {
                      return (
                        <Box key={key + 'Legend'} display="flex" alignItems="center" gap={1}>
                          <Chip
                            icon={
                              <Box
                                width={15}
                                height={15}
                                bgcolor={colors?.[key]}
                                sx={{
                                  borderRadius: 2,
                                  border: '1px solid white',
                                  '@media print': {
                                    bgcolor: colors?.[key],
                                    printColorAdjust: 'exact',
                                    WebkitPrintColorAdjust: 'exact',
                                  },
                                }}
                              ></Box>
                            }
                            size="small"
                            label={key}
                          />
                          <Typography>{deviceTelemetries?.find((t) => t.id === key)?.name || ''}</Typography>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <Box
          ref={chartContainerRef}
          sx={{
            height: '400px',
            width: '100%',
            '& .VictoryContainer': { top: '-24px' },
            '@media print': {
              marginTop: '-10mm',
            },
          }}
        >
          <VictoryChart
            theme={VictoryTheme.material}
            {...size}
            containerComponent={
              <VictoryVoronoiContainer
                labels={() => ' '}
                labelComponent={
                  <VictoryTooltip
                    constrainToVisibleArea={true}
                    pointerLength={4}
                    flyoutComponent={
                      <Flyout
                        dy={10}
                        parentSize={size}
                        shouldHide={(props) => (props.activePoints?.[0]?.childName as string).includes('Area')}
                        body={({ datum, flyoutProps }) => {
                          const keys = flyoutProps.activePoints?.[0]?.childName
                            .replace('Line', '')
                            .replace('Area', '')
                            .split('~~');
                          return (
                            <Box
                              sx={{
                                background: 'black',
                                color: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: 1,
                                gap: 1,
                                width: 'fit-content',
                                borderRadius: 1,
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Box
                                  sx={{
                                    backgroundColor: flyoutProps.activePoints?.[0].style.data.stroke || 'white',
                                    height: 20,
                                    width: 20,
                                    borderRadius: 10,
                                  }}
                                />
                                <Typography>{cropString(keys?.[1] || keys?.[0] || '', 20)}</Typography>
                                <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                                  {datum.y}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                                  {dayjs(datum.x).format('L')}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }}
                      />
                    }
                    flyoutStyle={{ fill: theme.palette.text.primary }}
                  />
                }
              />
            }
            events={names.map((name) => ({
              childName: `${name}~~Line`,
              target: 'data',
              eventHandlers: {
                onMouseOver: () => {
                  return [
                    ...names
                      .filter((n) => n !== name)
                      .map((name) => ({
                        childName: `${name}~~Line`,
                        target: 'data',
                        mutation: ({ style }: { style: any }) => ({
                          style: Object.assign({}, style, { strokeWidth: 2 }),
                        }),
                      })),
                    {
                      childName: `${name}~~Area`,
                      target: 'data',
                      mutation: ({ style }) => ({ style: Object.assign({}, style, { opacity: 1 }) }),
                    },
                  ];
                },
                onMouseOut: () => {
                  return [
                    ...names
                      .filter((n) => n !== name)
                      .map((name) => ({
                        childName: `${name}~~Line`,
                        target: 'data',
                        mutation: () => null,
                      })),
                    {
                      childName: `${name}~~Area`,
                      target: 'data',
                      mutation: () => null,
                    },
                  ];
                },
              },
            }))}
          >
            <VictoryAxis dependentAxis domain={_domain} />
            <VictoryAxis tickFormat={(tick) => `${dayjs(tick).format('L\nLTS')}`} />
            {Lines}
          </VictoryChart>
        </Box>
      </Box>
    </Paper>
  );
}
