import { ApiResult } from '@typings';
import { EventRes } from '@/components/OneTools/Channels/Events/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase, CommonIsEntityUniqueRes } from '@/components/OneTools/typings';

const URL_PREFIX = `${API_VERSION.v2}/system/events`;

export const eventsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Events'] }).injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<ApiResult<{ items: EventRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Events'],
    }),
    getEvent: builder.query<ApiResult<EventRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Events'],
    }),
    isEventUnique: builder.query<ApiResult<CommonIsEntityUniqueRes>, CommonIsEntityUniqueBase>({
      query: (params: CommonIsEntityUniqueBase) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
  }),
});

export const { useLazyGetEventsQuery, useGetEventsQuery, useLazyGetEventQuery, useLazyIsEventUniqueQuery } =
  eventsApiSlice;
