import useTelemetryColumns from './useTelemetryColumns';
import { useGetTelemetryEntriesQuery } from '@/redux/api/system/telemetryApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { TelemetryRes } from './typings';
import { useTranslation } from 'react-i18next';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ROUTES } from '@/shared/constants';

export const TelemetryTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<TelemetryRes>));

export default function TelemetryPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetTelemetryEntriesQuery();
  const columns = useTelemetryColumns();

  return (
    <TelemetryTable
      title={t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
      exportData={true}
      selection={true}
    />
  );
}
