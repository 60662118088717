import { StatusT } from '@typings';
import React from 'react';

export const FiltersContext = React.createContext<{
  model?: string;
  brand?: string;
  status?: StatusT;
  customer?: string;
}>({
  model: undefined,
  brand: undefined,
  status: undefined,
  customer: undefined,
});
