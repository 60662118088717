import { TelemetryFilters } from '../../../typings';

export const getFiltersArray = (telemetryFilters?: TelemetryFilters) => {
  if (!telemetryFilters) return [];

  const singleTelemetryFilter = telemetryFilters.key;
  const groupTelemetryFilter = telemetryFilters.tag;

  return [
    ...(Array.isArray(singleTelemetryFilter) ? singleTelemetryFilter : []),
    ...(Array.isArray(groupTelemetryFilter) ? groupTelemetryFilter : []),
  ];
};
