import { Box } from '@mui/material';
import { ITEM_TYPE, MergedTelemetry } from '../../../../typings';
import SingleItemPicker from './SingleItemPicker';
import GroupItemPicker from './GroupItemPicker';
import { useDeviceTelemetryCtx } from '../../../../TelemetryProvider';

const renderTelemetryItem = (telemetry: MergedTelemetry) => {
  switch (telemetry.type) {
    case ITEM_TYPE.GROUP:
      return <GroupItemPicker item={telemetry} key={telemetry.id} />;
    case ITEM_TYPE.SINGLE:
      return <SingleItemPicker item={telemetry} key={telemetry.id} />;
    default:
      return <></>;
  }
};

export default function ItemsPicker() {
  const { telemetries } = useDeviceTelemetryCtx();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        height: '30rem',
        overflowY: 'auto',
      }}
    >
      {telemetries && telemetries.map(renderTelemetryItem)}
    </Box>
  );
}
