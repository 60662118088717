import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import apiSlice from './api/apiSlice';
import authReducer from './slices/authSlice';
import themeSlice from './slices/themeSlice';
import operationSlice from './slices/operationSlice';
import pollingSlice from './slices/pollingSlice';
import localStorage from 'redux-persist/es/storage';
import devicesSlice from './slices/deviceSlice';

const persistAuthConfig = {
  key: 'auth',
  storage,
};

const persistOperationConfig = {
  key: 'operation',
  storage: localStorage,
};
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [apiSlice.reducerPath],
};
const persistPollingConfig = { key: 'pollings', storage: storageSession };

const reducer = combineReducers({
  auth: persistReducer(persistAuthConfig, authReducer),
  themeSlice,
  devices: devicesSlice,
  operations: persistReducer(persistOperationConfig, operationSlice),
  pollings: persistReducer(persistPollingConfig, pollingSlice),

  [apiSlice.reducerPath]: apiSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppStore = typeof store;
export type AppState = ReturnType<AppStore['getState']>;
export type RootState = ReturnType<typeof store.getState>;

const persistedStore = persistStore(store);
export { store, persistedStore };
