import Area, { AREA_DESIGN_TOKENS, AreaBody, AreaHeader } from '@/components/Shared/Card/Area';
import { Subtitle, Title } from '@/components/Shared/Card/Area/Text';
import TagIcon from '@mui/icons-material/Tag';
import { Box, Chip, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Device } from '../../../DevicesPanel/typings';
import CopyChip from '@/components/Shared/Chips/CopyChip';
import TypographyWithTooltip from '@/components/Shared/Tooltip';

function Identity({
  serialNumber,
  model,
  name,
  sx,
  metaData,
  brandName,
}: {
  serialNumber: Device['serialNumber'];
  model: Device['model'];
  name: Device['name'];
  sx?: SxProps;
  metaData: Device['metaData'];
  brandName: Device['brandName'];
}) {
  const { t } = useTranslation();

  return (
    <Area sx={sx ? sx : {}}>
      <AreaHeader>
        <TypographyWithTooltip
          anchorText={t('identity')}
          slots={{
            AnchorTextTypography: Subtitle,
          }}
          tooltipText={t('deviceIdentityTooltip')}
        />
      </AreaHeader>
      <AreaBody>
        <Title>{`${name || '--'}`}</Title>
        <Box
          sx={{ fontWeight: 'bold', paddingTop: '0.5rem' }}
          display="flex"
          flexWrap="wrap"
          gap={AREA_DESIGN_TOKENS.gap}
          alignItems="flex-end"
        >
          <CopyChip
            icon={<TagIcon color="primary" />}
            sx={{
              color: '#2968fe',
              backgroundColor: '#e9eff6',
              border: 'solid 1px',
              borderColor: '#2968fe',
            }}
            label={serialNumber}
          />

          <CopyChip label={model || '--'} icon={<TagIcon />} />

          {brandName === 'quench' &&
            (metaData?.deviceQnumber ? (
              <CopyChip
                sx={{ fontWeight: 'bold' }}
                label={metaData?.deviceQnumber}
                icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
              />
            ) : (
              <Chip icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />} label={t('qUnassigned')} size="small" />
            ))}

          {metaData?.manufacturingSerialNumber ? (
            <CopyChip
              sx={{ fontWeight: 'bold' }}
              label={metaData?.manufacturingSerialNumber}
              icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
            />
          ) : (
            <Chip
              icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
              label={t('manufacturingSerialNumberUnassigned')}
              size="small"
            />
          )}
        </Box>
      </AreaBody>
    </Area>
  );
}

export default Identity;
