import { useContext } from 'react';
import { useTelemetryFilters } from '../useTelemetryFilters';
import { DeviceTelemetryContext } from './DeviceTelemetryContext';
import { TelemetryFiltersContext } from './TelemetryFiltersContext';
import { TelemetryFiltersHandlerContext } from './TelemetryFiltersHandlerContext';
import { FiltersState } from '../../typings';
import { MergedTelemetry } from '../typings';

export default function TelemetryProvider({
  children,
  mergedTelemetries,
}: {
  children: React.ReactNode;
  mergedTelemetries: MergedTelemetry[];
}) {
  const { getQueryFilters, ...handlers } = useTelemetryFilters();
  const filters = getQueryFilters();

  return (
    <TelemetryFiltersContext.Provider value={filters as FiltersState}>
      <TelemetryFiltersHandlerContext.Provider value={handlers}>
        <DeviceTelemetryContext.Provider
          value={{
            telemetries: mergedTelemetries,
          }}
        >
          {children}
        </DeviceTelemetryContext.Provider>
      </TelemetryFiltersHandlerContext.Provider>
    </TelemetryFiltersContext.Provider>
  );
}

export const useTelemetryFiltersCtx = () => useContext(TelemetryFiltersContext);
export const useTelemetryFiltersHandlerCtx = () => useContext(TelemetryFiltersHandlerContext);
export const useDeviceTelemetryCtx = () => useContext(DeviceTelemetryContext);
