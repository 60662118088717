import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AlarmRes } from './typings';
import dayjs from 'dayjs';
import ExpandableField from '@/components/Shared/ExpandableField';
import { useNavigate } from 'react-router';
import { ROLES } from '@/shared/constants';
import { getPath } from '@/shared/utils';

export default function useAlarmColumns(itemsInDeviceIdentity?: AlarmRes[]): Array<Column<AlarmRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data_columns_array: Array<Column<AlarmRes>> = [
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('sendingStyle'),
      field: 'sendingStyle',
    },
    {
      title: t('parameters'),
      field: 'parameters',
      render: (alarms) => <Typography>{alarms.parameters ? t('yes') : t('no')}</Typography>,
    },
    {
      title: t('payloadExample'),
      width: '15%',
      field: 'payloadExample',
      render: (alarms) => alarms.payloadExample && <ExpandableField content={alarms.payloadExample} />,
      sorting: false,
    },
    {
      title: t('roles'),
      field: 'role',
      customFilterAndSearch: (filter, alarms) => {
        const roles = alarms?.role || [];
        const roleFilter = roles.some((role) => Object.values(ROLES).includes(role));

        return roleFilter && roles.some((role) => role.startsWith(filter));
      },
      render: (alarms) =>
        alarms.role?.map((r, index) => (
          <Typography sx={{ width: 'max-content' }} key={`${r}-${index}`}>
            {t(r)}
          </Typography>
        )),
      exportTransformer: (alarms) =>
        (alarms.role as string[])?.join ? (alarms.role as string[])?.join(', ') : alarms.role,
    },
    {
      title: t('severity'),
      field: 'severity',
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (alarms) => dayjs(alarms.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (alarms) => dayjs(alarms.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
  ];

  const actions_columns_array: Array<Column<AlarmRes>> = itemsInDeviceIdentity
    ? [
        {
          title: t('actions'),
          width: '10%',
          render: (alarms) => (
            <Box display="flex" justifyContent="flex-start" gap="0.5rem">
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  navigate(getPath('ONETOOL_CHANNELS_ALARMS'), {
                    state: {
                      searchText: alarms.name,
                      highlight: {
                        refField: 'id',
                        matchValue: alarms.id,
                      },
                    },
                  })
                }
              >
                {t('view')}
              </Button>
            </Box>
          ),
          sorting: false,
          searchable: false,
        },
      ]
    : [];

  return [...data_columns_array, ...actions_columns_array];
}
