import { Autocomplete, AutocompleteInputChangeReason, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StoreAsyncAutocompleteFilterConfig, StoreFilterOption, StoreOnFilterChange } from './typings';
import useDebounce from '@/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import React from 'react';

function AutocompleteAsync({
  id,
  lazyQueryHook,
  renderOption,
  transformFn,
  debounceTime = 400,
  onFilterChange,
  getInitialValue,
  renderInput,
  label,
  shouldFetch,
  getQueryParam,
  ...props
}: StoreAsyncAutocompleteFilterConfig & { onFilterChange: StoreOnFilterChange }) {
  const [queryTrigger, { isFetching, isLoading, currentData, isUninitialized }] = lazyQueryHook();
  const [value, setValue] = useState<StoreFilterOption | null>(null);
  const [query, setQuery] = useState('');
  const { t } = useTranslation();
  const fetchedInitialValue = useRef(false);
  const debouncedQueryTrigger = useDebounce(() => queryTrigger(queryParams), 800);
  const parsedData = useMemo(() => {
    return (currentData as { data?: { items: any[] } })?.data?.items?.length
      ? transformFn((currentData as { data: { items: any } }).data)
      : [];
  }, [currentData, transformFn]);
  const showLoader = isFetching || isLoading;
  const lastInputValue = useRef('*');
  const options = useMemo(() => {
    const uniqueOptions = new Map(parsedData.filter(Boolean).map((item) => [item.optionId, item]));
    if (value && !uniqueOptions.has(value?.optionId)) {
      uniqueOptions.set(value?.optionId, value);
    }
    return Array.from(uniqueOptions.values());
  }, [value, parsedData]);

  const queryParams = useMemo(() => getQueryParam(query), [getQueryParam, query]);

  const handleChange = useCallback(
    (_: React.SyntheticEvent, val: StoreFilterOption | null) => {
      setValue(val);
      onFilterChange(id, val);
    },
    [onFilterChange, id]
  );

  const handleInputChange = useCallback(
    (_: React.SyntheticEvent, newInputValue: string, reason: AutocompleteInputChangeReason) => {
      if (reason !== 'input') return;

      setQuery((prev) => {
        lastInputValue.current = prev;
        return newInputValue;
      });
    },
    []
  );

  const memoizedRenderInput = useMemo(() => {
    return typeof renderInput === 'function'
      ? renderInput
      : (params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
            }}
          />
        );
  }, [renderInput, label]);

  useEffect(() => {
    shouldFetch(query) && lastInputValue.current !== query && debouncedQueryTrigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (typeof getInitialValue === 'function' && !value && !fetchedInitialValue.current) {
      getInitialValue().then((value) => {
        setValue(value);
        onFilterChange(id, value);
        fetchedInitialValue.current = true;
      });
    }
    return () => {
      fetchedInitialValue.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      {...props}
      key={id}
      value={value}
      id={id}
      getOptionLabel={(option) => option?.label || ''}
      filterOptions={(x) => x}
      options={options}
      isOptionEqualToValue={(o, v) => {
        return o?.optionId === v?.optionId;
      }}
      autoComplete
      disablePortal
      loading={showLoader}
      loadingText={`${t('loading')}...`}
      noOptionsText={isUninitialized ? t('search3Letters') : t('noResultsFound')}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={memoizedRenderInput}
      renderOption={renderOption}
    />
  );
}
export default React.memo(AutocompleteAsync);
