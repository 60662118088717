import { produce } from 'immer';
import {
  ADD_TELEMETRY,
  CHANGE_DATE_RANGE,
  REMOVE_TELEMETRY,
  ChangeFilterAction,
  TelemetryFiltersPayload,
  GroupTelemetry,
} from './actions';
import { FiltersState } from '../../typings';

const isGroupTelemetry = (payload: TelemetryFiltersPayload): payload is GroupTelemetry =>
  typeof payload !== 'string' && Boolean(payload.tagName);

export const reduceState = (state: FiltersState, action: ChangeFilterAction): FiltersState => {
  switch (action.type) {
    case ADD_TELEMETRY: {
      const { payload } = action;
      if (!state.telemetries) {
        state.telemetries = {};
      }

      if (!isGroupTelemetry(payload)) {
        if (!state.telemetries.key) {
          state.telemetries.key = [];
        }

        if (state.telemetries.key.includes(payload)) {
          return state;
        } else {
          const nextState = produce(state, (draft) => {
            draft.telemetries!.key!.push(payload as string);
          });
          return nextState;
        }
      }

      if (isGroupTelemetry(payload)) {
        if (!state.telemetries.tag) {
          state.telemetries.tag = [];
        }
        const foundTag = state.telemetries?.tag.find(({ tagName }) => tagName === payload.tagName);
        if (!foundTag) {
          const nextState = produce(state, (draft) => {
            if (Array.isArray(payload.key)) {
              draft.telemetries!.tag!.push({ tagName: payload.tagName, key: payload.key });
            } else {
              draft.telemetries!.tag!.push({ tagName: payload.tagName, key: [payload.key] });
            }
          });
          return nextState;
        }

        if (foundTag.key.includes(payload.key as string)) {
          return state;
        } else {
          const nextState = produce(state, (draft) => {
            const foundtag = draft.telemetries!.tag!.find(({ tagName }) => tagName === payload.tagName);
            foundtag!.key.push(payload.key as string);
          });
          return nextState;
        }
      }

      // this is a safety net, it should never happen
      if (state?.telemetries && !Object.entries(state.telemetries).length) {
        delete state.telemetries;
      }
      if (state?.telemetries?.key && state.telemetries.key.length) {
        delete state.telemetries.key;
      }
      if (state?.telemetries?.tag && !state.telemetries.tag.length) {
        delete state.telemetries!.tag;
      }

      return state;
    }
    case REMOVE_TELEMETRY: {
      if (!state.telemetries) {
        return state;
      }

      const { payload } = action;

      if (isGroupTelemetry(payload)) {
        if (!state.telemetries?.tag || !state.telemetries?.tag?.length) {
          return state;
        }
        const foundTag = state.telemetries?.tag.find(({ tagName }) => tagName === payload.tagName);
        if (!foundTag) {
          return state;
        }

        // used when the group telemetry tag is selected, an array of all the telemetries of that group
        // is passed to the action builder
        if (Array.isArray(payload.key)) {
          const nextState = produce(state, (draft) => {
            const newTag = draft.telemetries?.tag!.filter(({ tagName }) => tagName !== payload.tagName);
            draft.telemetries!.tag = newTag;
          });
          return nextState;
        }

        if (foundTag.key.includes(payload.key as string)) {
          const nextState = produce(state, (draft) => {
            const foundTag = draft.telemetries?.tag!.find(({ tagName }) => tagName === payload.tagName);

            // remove the entire group if the command was to remove the last key of that group
            if (foundTag!.key.length === 1 && foundTag!.key[0] === payload.key) {
              draft.telemetries!.tag = draft.telemetries!.tag!.filter(({ tagName }) => tagName !== payload.tagName);
            } else {
              foundTag!.key = foundTag!.key.filter((id) => id !== payload.key);
            }
          });
          return nextState;
        }

        if (!state.telemetries.tag) {
          return state;
        }
      }

      if (!state.telemetries?.key || !state.telemetries?.key?.length) {
        return state;
      }

      if (state.telemetries.key.includes(payload as string)) {
        const nextState = produce(state, (draft) => {
          draft.telemetries!.key = draft.telemetries!.key!.filter((id) => id !== payload);
        });
        return nextState;
      }

      return state;
    }
    case CHANGE_DATE_RANGE: {
      const nextState = produce(state, (draft) => {
        draft.start = action.payload.start;
        draft.end = action.payload.end;
      });
      return nextState;
    }
    default:
      return state;
  }
};
