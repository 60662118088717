import useConfigColumns from './useConfigColumns';
import { useGetConfigsQuery } from '@/redux/api/system/configsApiSlice';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import { useTranslation } from 'react-i18next';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ROUTES } from '@/shared/constants';
import { ConfigRes } from './typings';

export const ConfigsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ConfigRes>));

export default function ConfigPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetConfigsQuery();
  const columns = useConfigColumns();

  return (
    <ConfigsTable
      title={t(ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
      exportData={true}
      selection={true}
    />
  );
}
