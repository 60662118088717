import { ITEM_TYPE, MergedTelemetry } from '../../../typings';

export type Range = { id: string; min: number; max: number };

export const getRanges = (deviceTelemetries?: MergedTelemetry[]): Range[] => {
  if (!deviceTelemetries) {
    return [];
  }

  return deviceTelemetries.reduce((acc, { id, type, items, ranges }) => {
    if (type === ITEM_TYPE.SINGLE) {
      if (ranges && Object.entries(ranges).length) {
        if (ranges.min && ranges.max) {
          acc.push({
            id,
            min: ranges.min,
            max: ranges.max,
          });
        }
        return acc;
      }
      return acc;
    }

    if (type === ITEM_TYPE.GROUP) {
      if (items && items?.length) {
        const newAcc = [
          ...items.reduce((acc, { id, ranges }) => {
            if (ranges && Object.entries(ranges).length) {
              if (ranges.min && ranges.max) {
                acc.push({
                  id,
                  min: ranges.min,
                  max: ranges.max,
                });
              }
              return acc;
            }
            return acc;
          }, [] as Range[]),
          ...acc,
        ];
        return newAcc;
      }
    }

    return acc;
  }, [] as Range[]);
};
