import {
  StoreAsyncAutocompleteFilterConfig,
  StoreAutocompleteFilterConfig,
  StoreDateRangeFilterConfig,
} from '@/components/Shared/Filters/typings';
import { useLazyGetUsersQuery, UsersFilterResponse } from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import CustomerListItem from '../Lists/Items/CustomerListItem';

type AutocompleteMakerArgs = Partial<Omit<StoreAutocompleteFilterConfig, 'options' | 'id' | 'kind'>> & {
  id: StoreAutocompleteFilterConfig['id'];
  options: StoreAutocompleteFilterConfig['options'];
  label: StoreAutocompleteFilterConfig['label'];
};

type AsyncAutocompleteMakerArgs = Partial<Omit<StoreAsyncAutocompleteFilterConfig, 'id' | 'kind'>> & {
  id: StoreAsyncAutocompleteFilterConfig['id'];
  label: StoreAsyncAutocompleteFilterConfig['label'];
  getInitialValue: StoreAsyncAutocompleteFilterConfig['getInitialValue'];
};

type DateRangeMakerArgs = Partial<Omit<StoreDateRangeFilterConfig, 'kind' | 'id' | 'label' | 'defaultValue'>> & {
  id: StoreDateRangeFilterConfig['id'];
  label: StoreDateRangeFilterConfig['label'];
  defaultValue: StoreDateRangeFilterConfig['defaultValue'];
};

const makeBrandsFilter = ({ label, options, id, ...rest }: AutocompleteMakerArgs): StoreAutocompleteFilterConfig => ({
  id: id,
  kind: 'autocomplete',
  label: label,
  options: options,
  ...rest,
});

const makeModelsFilter = ({ label, options, id, ...rest }: AutocompleteMakerArgs): StoreAutocompleteFilterConfig => ({
  id: id,
  kind: 'autocomplete',
  label: label,
  options: options,
  ...rest,
});

const makeDateRangeFilter = ({ label, id, defaultValue, ...rest }: DateRangeMakerArgs): StoreDateRangeFilterConfig => ({
  id: id,
  kind: 'dateRangeWithFixedRanges',
  label: label,
  defaultValue: defaultValue,
  ...rest,
});

const makeCustomerFilter = ({
  label,
  id,
  getInitialValue,
  ...rest
}: AsyncAutocompleteMakerArgs): StoreAsyncAutocompleteFilterConfig => ({
  id: id,
  kind: 'asyncAutocomplete',
  label: label,
  shouldFetch: (query: string) => query.length >= 3 && /[a-zA-Z]/g.test(query),
  transformFn: (data: { items: UsersFilterResponse[] }) => {
    return data?.items?.map((item) => {
      const toSearchForMatches = data?.items.filter((user) => user.id !== item.id);
      const hasHomonym =
        toSearchForMatches?.length > 0 &&
        toSearchForMatches.findIndex(
          (user) =>
            user?.firstName?.toLowerCase() === item?.firstName?.toLowerCase() &&
            user?.lastName?.toLowerCase() === item?.lastName?.toLowerCase()
        ) !== -1;
      return { ...item, optionId: item?.id, label: `${item?.firstName} ${item?.lastName}`, hasHomonym };
    });
  },
  lazyQueryHook: useLazyGetUsersQuery,
  getInitialValue: getInitialValue,
  getQueryParam: (query: string) => ({ query }),
  renderOption: CustomerListItem,
  debounceTime: 400,
  ...rest,
});

export { makeBrandsFilter, makeModelsFilter, makeCustomerFilter, makeDateRangeFilter };
