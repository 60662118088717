import { useGetReactionsQuery } from '@/redux/api/system/reactionsApiSlice';
import { useTranslation } from 'react-i18next';
import { ReactionRes } from './typings';
import useReactionColumns from './useReactionColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import { ROUTES } from '@/shared/constants';

export const ReactionsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<ReactionRes>));

export default function ReactionsPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetReactionsQuery();
  const columns = useReactionColumns();

  return (
    <ReactionsTable
      title={t(ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment)}
      data={data?.data?.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
    />
  );
}
