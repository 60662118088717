import { useContext, useEffect, useState } from 'react';
import StatisticsCard from '../Shared/StatisticsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import LineChartCard from './LineChartCard';
import withMissingDataManagement from './withMissingDataManagement';
import { useGetOverviewQuery } from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import { FiltersContext } from './FiltersContext';

const SafeLineChart = withMissingDataManagement(LineChartCard);

export default function FiltersStatusCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const filters = useContext(FiltersContext);
  const { data } = useGetOverviewQuery(filters);
  const filteredData = data?.data.items;
  const [total, setTotal] = useState<number>(0);
  const bigNumberLabel = ['Running'];

  useEffect(() => {
    if (!filteredData || !filteredData.filtersStatus || filteredData.filtersStatus.value.length === 0) {
      return;
    }
    setTotal(filteredData.filtersStatus?.total || 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData?.filtersStatus]);

  return (
    <StatisticsCard
      variant="boxed"
      title={t('filtersStatus')}
      action={{
        label: t('details'),
        fn: () => navigate('/'),
      }}
    >
      <SafeLineChart
        hasData={!!(filteredData && filteredData.filtersStatus && filteredData.filtersStatus.value.length > 1)}
        data={filteredData?.filtersStatus?.value.map((filterStatus) => ({
          x: filterStatus.date,
          y: filterStatus.value,
        }))}
        id={'filtersStatus'}
        isPercentage={true}
        bigNumberLabel={bigNumberLabel}
        isValueTogglable={false}
        smallLabel={'Based on 675 dispensed'}
        bigNumber={total}
      />
    </StatisticsCard>
  );
}
