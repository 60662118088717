import { Paper } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { WithSlots } from '@typings';
import InfoPanelHeader from './Device/InfoPanelHeader';
import DefaultInfoPanelHeader, { InfoPanelHeaderProps } from './InfoPanelHeader';

export type InfoPanelProps<TData> = WithSlots<
  PropsWithChildren<{
    onClose: () => void;
    marker: TData;
  }>,
  {
    Header: (props: InfoPanelHeaderProps<TData>) => ReturnType<typeof InfoPanelHeader>;
  }
>;

export default function InfoPanel<TData>({ children, onClose, slots, marker }: InfoPanelProps<TData>) {
  const INFO_PANEL_HEADER = useMemo(() => slots?.Header ?? DefaultInfoPanelHeader, [slots?.Header]);
  return (
    <Paper
      sx={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        p: 2,
        width: 'fit-content',
        bgcolor: (theme) => theme.palette.background.default,
        maxHeight: 'calc(100% - 126px);',
        maxWidth: '100%',
        overflow: 'scroll',
        overflowX: 'hidden',
      }}
      elevation={2}
    >
      <INFO_PANEL_HEADER onClose={onClose} marker={marker} />

      {children}
    </Paper>
  );
}
