import LoyaltyProgramTable from '@/components/Fleet/Devices/DevicePanel/OverviewPanel/LoyaltyProgramTable';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { DeviceContext } from './DeviceContext';

export default function LoyaltyPrograms() {
  const context = useContext(DeviceContext);

  if (!context || !context.data) {
    return <></>;
  }

  return (
    <Box>
      <LoyaltyProgramTable
        items={context.data.loyaltyPrograms}
        serialNumber={context.data.id}
        isFleet={false}
        excludeColumns={['updatedAt', 'createdAt', 'edit', 'delete']}
      />
    </Box>
  );
}
