import { TAGS } from '@/redux/slices/operationSlice';
import DeviceConsumableDepleteVariant from './DeviceConsumableDeplete';
import DeviceConsumableRemoveVariant from './DeviceConsumableRemove';
import DeviceConsumableSetVariant from './DeviceConsumableSet';
import { CommonSnackbarProps } from '../typings';
import { getPath } from '@/shared/utils';

export default function DeviceConsumableVariant({ operation, dismissAction }: CommonSnackbarProps) {
  const path = getPath('FLEET_DEVICE_CONSUMABLES', { deviceId: operation.entity });
  switch (operation.tag) {
    case TAGS.DEVICE_CONSUMABLE_DEPLETE:
      return (
        <DeviceConsumableDepleteVariant
          operation={{
            ...operation,
            path,
          }}
          dismissAction={dismissAction}
        />
      );

    case TAGS.DEVICE_CONSUMABLE_REMOVE:
      return (
        <DeviceConsumableRemoveVariant
          operation={{
            ...operation,
            path,
          }}
          dismissAction={dismissAction}
        />
      );

    case TAGS.DEVICE_CONSUMABLE_SET:
      return (
        <DeviceConsumableSetVariant
          operation={{
            ...operation,
            path,
          }}
          dismissAction={dismissAction}
        />
      );

    default:
      return <></>;
  }
}
