import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityRes } from './typings';
import dayjs from 'dayjs';
import RelatedEntityLabel from '@/components/Shared/Chips/RelatedEntityChip';
import { useGetTelemetryEntriesQuery } from '@/redux/api/system/telemetryApiSlice';
import { useGetConsumablesQuery } from '@/redux/api/system/consumablesApiSlice';
import { useGetAlarmsQuery } from '@/redux/api/system/alarmsApiSlice';
import { useGetBrandsQuery } from '@/redux/api/system/brandsApiSlice';
import { useNavigate } from 'react-router';
import { useGetEventsQuery } from '@/redux/api/system/eventsApiSlice';
import { useGetCommandsQuery } from '@/redux/api/system/commandsApiSlice';
import CulliganChip from '@/components/Shared/Chips/CulliganChip';
import Check from '@mui/icons-material/Check';
import ExpandableField from '@/components/Shared/ExpandableField';
import { ROUTES } from '@/shared/constants';
import { useGetConfigsQuery } from '@/redux/api/system/configsApiSlice';
import { useMemo } from 'react';
import { getPath } from '@/shared/utils';

const MAX_RELATED_ITEMS = 3;

export function useDeviceIdentityColumns(): Array<Column<DeviceIdentityRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Brands
  const { data: allBrands, isLoading: isLoadingBrands } = useGetBrandsQuery();

  // Telemetry
  const { data: allTelemetries, isLoading: isLoadingTelemetry } = useGetTelemetryEntriesQuery();

  // Consumables
  const { data: allConsumables, isLoading: isLoadingConsumables } = useGetConsumablesQuery();

  // Alarms
  const { data: allAlarms, isLoading: isLoadingAlarms } = useGetAlarmsQuery();

  // Events
  const { data: allEvents, isLoading: isLoadingEvents } = useGetEventsQuery();

  // Commands
  const { data: allCommands, isLoading: isLoadingCommands } = useGetCommandsQuery();

  // Configs
  const { data: allConfigs, isLoading: isLoadingConfigs } = useGetConfigsQuery();

  // Columns
  const nameColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('name'),
      width: '15%',
      field: 'name',
    }),
    [t]
  );

  const idColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('code'),
      width: '15%',
      field: 'id',
    }),
    [t]
  );

  const baseProtocolVersionColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('baseProtocolVersion'),
      width: '15%',
      field: 'baseProtocolVersion',
    }),
    [t]
  );

  const descriptionColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('description'),
      width: '15%',
      field: 'description',
    }),
    [t]
  );

  const connectivityColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('connectivity'),
      width: '15%',
      field: 'connectivity',
      render: ({ connectivity }) => connectivity.join(', '),
      exportTransformer: ({ connectivity = [] }) =>
        Array.isArray(connectivity) ? connectivity.join(', ') : connectivity,
      sorting: false,
    }),
    [t]
  );

  const brandsColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t(ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment),
      width: '15%',
      field: ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment,
      customFilterAndSearch: (filter: string, { brands = [] }) => {
        const brandsData = (allBrands?.data?.items ?? []).filter(({ id }) => brands.includes(id));
        return brandsData.some(({ name = '' }) => name.startsWith(filter));
      },
      render: ({ brands = [] }) => {
        if (!Array.isArray(brands) || brands.length === 0) {
          return;
        }
        const brandsData = (allBrands?.data?.items ?? []).filter(({ id }) => brands.includes(id));
        return (
          !isLoadingBrands &&
          (brandsData.length === 0 ? (
            <RelatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={brandsData.map(({ id, name }) => (
                <RelatedEntityLabel
                  key={id}
                  text={name}
                  severity="warning"
                  onEntityClick={() =>
                    navigate(getPath('ONETOOL_ECOSYSTEM_BRANDS'), {
                      state: {
                        searchText: name,
                        highlight: {
                          refField: 'id',
                          matchValue: id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: ({ brands = [] }) => (Array.isArray(brands) ? brands.join(', ') : brands),
      sorting: false,
    }),
    [allBrands?.data?.items, isLoadingBrands, navigate, t]
  );

  const tierColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('tier'),
      width: '15%',
      field: 'tier',
    }),
    [t]
  );

  const typeColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('type'),
      width: '15%',
      field: 'type',
    }),
    [t]
  );

  const alwaysConnectedColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('alwaysConnected'),
      width: '15%',
      field: 'alwaysConnected',
      render: ({ alwaysConnected }) => <Typography>{alwaysConnected ? t('yes') : t('no')}</Typography>,
    }),
    [t]
  );

  const supportedOperatingModesColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('operatingModes'),
      width: '15%',
      field: 'supportedOperatingModes',
      render: ({ operatingModes = [] }) =>
        operatingModes.map((om) => <CulliganChip key={om} label={t(om)}></CulliganChip>),
      exportTransformer: ({ operatingModes = [] }) =>
        Array.isArray(operatingModes) ? operatingModes.join(', ') : operatingModes,
    }),
    [t]
  );

  const powerProfilesColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('powerProfiles'),
      width: '15%',
      field: 'powerProfiles',
      render: ({ powerProfiles = [] }) =>
        powerProfiles.map((pp) => <CulliganChip key={pp} label={t(pp)}></CulliganChip>),
      exportTransformer: ({ powerProfiles = [] }) =>
        Array.isArray(powerProfiles) ? powerProfiles.join(', ') : powerProfiles,
    }),
    [t]
  );

  const imageColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('image'),
      width: '15%',
      field: 'image',
      customFilterAndSearch: (filter: string, { image }) => (image ? 'Set' : 'Unset').startsWith(filter),
      render: ({ image }) => (
        <CulliganChip icon={<Check color={image ? 'success' : 'error'} />} label={t(image ? 'set' : 'unset')} />
      ),
    }),
    [t]
  );

  const telemetryColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment),
      width: '15%',
      field: ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment,
      customFilterAndSearch: (filter: string, { telemetry = [] }) => {
        const telemetryEntries = (allTelemetries?.data?.items ?? []).filter(({ id }) => telemetry.includes(id));
        return telemetryEntries.some(({ name = '' }) => name.startsWith(filter));
      },
      render: ({ telemetry = [] }) => {
        if (!Array.isArray(telemetry) || telemetry.length === 0) {
          return;
        }
        const telemetryEntries = (allTelemetries?.data?.items ?? []).filter(({ id }) => telemetry.includes(id));
        return (
          !isLoadingTelemetry &&
          (telemetryEntries.length === 0 ? (
            <RelatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={telemetryEntries.map(({ id, name }) => (
                <RelatedEntityLabel
                  key={id}
                  severity="warning"
                  text={name}
                  onEntityClick={() =>
                    navigate(getPath('ONETOOL_CHANNELS_TELEMETRY'), {
                      state: {
                        searchText: name,
                        highlight: {
                          refField: 'id',
                          matchValue: id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: ({ telemetry = [] }) => (Array.isArray(telemetry) ? telemetry.join(', ') : telemetry),
      sorting: false,
    }),
    [allTelemetries?.data?.items, isLoadingTelemetry, navigate, t]
  );

  const alarmsColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
      width: '15%',
      field: ROUTES.ONETOOL_CHANNELS_ALARMS.fragment,
      customFilterAndSearch: (filter: string, { alarms = [] }) => {
        const alarmsEntries = (allAlarms?.data?.items ?? []).filter(({ id }) => alarms.includes(id));
        return alarmsEntries.some(({ name = '' }) => name.startsWith(filter));
      },
      render: ({ alarms = [] }) => {
        if (!Array.isArray(alarms) || alarms.length === 0) {
          return;
        }
        const alarmEntries = (allAlarms?.data?.items || []).filter(({ id }) => alarms.includes(id));
        return (
          !isLoadingAlarms &&
          (alarmEntries.length === 0 ? (
            <RelatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={alarmEntries.map(({ id, name }) => (
                <RelatedEntityLabel
                  key={id}
                  severity="warning"
                  text={name}
                  onEntityClick={() =>
                    navigate(getPath('ONETOOL_CHANNELS_ALARMS'), {
                      state: {
                        searchText: name,
                        highlight: {
                          refField: 'id',
                          matchValue: id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: ({ alarms = [] }) => (Array.isArray(alarms) ? alarms.join(', ') : alarms),
      sorting: false,
    }),
    [allAlarms?.data?.items, isLoadingAlarms, navigate, t]
  );

  const configsColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t(ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment),
      width: '15%',
      field: ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment,
      customFilterAndSearch: (filter: string, { configs = [] }) => {
        const configsEntries = (allConfigs?.data?.items ?? []).filter(({ id }) => configs.includes(id));
        return configsEntries.some(({ name = '' }) => name.startsWith(filter));
      },
      render: ({ configs = [] }) => {
        if (!Array.isArray(configs) || configs.length === 0) {
          return;
        }
        const configEntries = (allConfigs?.data?.items ?? []).filter(({ id }) => configs.includes(id));
        return (
          !isLoadingConfigs &&
          (configEntries.length === 0 ? (
            <RelatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={configEntries.map(({ id, name }) => (
                <RelatedEntityLabel
                  key={id}
                  severity="warning"
                  text={name}
                  onEntityClick={() =>
                    navigate(getPath('ONETOOL_CHANNELS_CONFIGS'), {
                      state: {
                        searchText: name,
                        highlight: {
                          refField: 'id',
                          matchValue: id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: ({ configs = [] }) => (Array.isArray(configs) ? configs.join(', ') : configs),
      sorting: false,
    }),
    [allConfigs?.data?.items, isLoadingConfigs, navigate, t]
  );

  const consumablesColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment),
      width: '15%',
      field: ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment,
      customFilterAndSearch: (filter: string, { consumables = [] }) => {
        const consumablesEntries = (allConsumables?.data?.items ?? []).filter(({ id }) => consumables.includes(id));
        return consumablesEntries.some(({ name = '' }) => name.startsWith(filter));
      },
      render: ({ consumables = [] }) => {
        if (!Array.isArray(consumables) || consumables.length === 0) {
          return;
        }
        const consumableEntries = consumables.flatMap(
          (c) => (allConsumables?.data?.items ?? []).find(({ id }) => id === c) ?? []
        );

        return (
          !isLoadingConsumables &&
          (consumableEntries.length === 0 ? (
            <RelatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={consumableEntries.map(({ id, name }) => (
                <RelatedEntityLabel
                  key={id + Math.random()}
                  severity="warning"
                  text={name}
                  onEntityClick={() =>
                    navigate(getPath('ONETOOL_CHANNELS_CONSUMABLES'), {
                      state: {
                        searchText: name,
                        highlight: {
                          refField: 'id',
                          matchValue: id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: ({ consumables = [] }) => (Array.isArray(consumables) ? consumables.join(', ') : consumables),
      sorting: false,
    }),
    [allConsumables?.data?.items, isLoadingConsumables, navigate, t]
  );

  const eventsColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment),
      width: '15%',
      field: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
      customFilterAndSearch: (filter: string, { events = [] }) => {
        const eventsEntries = (allEvents?.data?.items ?? []).filter(({ id }) => events.includes(id));
        return eventsEntries.some(({ name = '' }) => name.startsWith(filter));
      },
      render: ({ events = [] }) => {
        if (!Array.isArray(events) || events.length === 0) {
          return;
        }
        const eventEntries = (allEvents?.data?.items ?? []).filter(({ id }) => events.includes(id));
        return (
          !isLoadingEvents &&
          (eventEntries.length === 0 ? (
            <RelatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={eventEntries.map(({ id, name }) => (
                <RelatedEntityLabel
                  key={id}
                  severity="warning"
                  text={name}
                  onEntityClick={() =>
                    navigate(getPath('ONETOOL_CHANNELS_EVENTS'), {
                      state: {
                        searchText: name,
                        highlight: {
                          refField: 'id',
                          matchValue: id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: ({ events = [] }) => (Array.isArray(events) ? events.join(', ') : events),
      sorting: false,
    }),
    [allEvents?.data?.items, isLoadingEvents, navigate, t]
  );

  const commandsColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t(ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment),
      width: '15%',
      field: ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment,
      customFilterAndSearch: (filter: string, { commands = [] }) => {
        const commandsEntries = (allCommands?.data?.items ?? []).filter(({ id }) => commands.includes(id));
        return commandsEntries.some(({ name = '' }) => name.startsWith(filter));
      },
      render: ({ commands = [] }) => {
        if (!Array.isArray(commands) || commands.length === 0) {
          return;
        }
        const commandEntries = (allCommands?.data?.items ?? []).filter(({ id }) => commands.includes(id));
        return (
          !isLoadingCommands &&
          (commandEntries.length === 0 ? (
            <RelatedEntityLabel severity="warning" />
          ) : (
            <ExpandableField
              content={commandEntries.map(({ id, name }) => (
                <RelatedEntityLabel
                  key={id}
                  severity="warning"
                  text={name}
                  onEntityClick={() =>
                    navigate(getPath('ONETOOL_CHANNELS_COMMANDS'), {
                      state: {
                        searchText: name,
                        highlight: {
                          refField: 'id',
                          matchValue: id,
                        },
                      },
                    })
                  }
                />
              ))}
              maxItems={MAX_RELATED_ITEMS}
              ItemsContainer={Box}
            />
          ))
        );
      },
      exportTransformer: ({ commands = [] }) => (Array.isArray(commands) ? commands.join(', ') : commands),
      sorting: false,
    }),
    [allCommands?.data?.items, isLoadingCommands, navigate, t]
  );

  const createdAtColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('creationDate'),
      width: '15%',
      field: 'createdAt',
      render: ({ createdAt }) => dayjs(createdAt).format('YYYY/MM/DD HH:mm'),
    }),
    [t]
  );

  const updatedAtColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('lastUpdated'),
      width: '15%',
      field: 'updatedAt',
      render: ({ updatedAt }) => dayjs(updatedAt).format('YYYY/MM/DD HH:mm'),
    }),
    [t]
  );

  const actionsColumn = useMemo<Column<DeviceIdentityRes>>(
    () => ({
      title: t('actions'),
      width: '11%',
      render: ({ id }) => (
        <Box display="flex" justifyContent="flex-start" gap="0.5rem">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(
                getPath('ONETOOL_ECOSYSTEM_DEVICE_IDENTITY', {
                  deviceIdentityId: id,
                })
              )
            }
          >
            {t('view')}
          </Button>
        </Box>
      ),
      sorting: false,
      searchable: false,
    }),
    [navigate, t]
  );

  return [
    nameColumn,
    idColumn,
    baseProtocolVersionColumn,
    descriptionColumn,
    connectivityColumn,
    brandsColumn,
    tierColumn,
    typeColumn,
    alwaysConnectedColumn,
    supportedOperatingModesColumn,
    powerProfilesColumn,
    imageColumn,
    telemetryColumn,
    alarmsColumn,
    configsColumn,
    consumablesColumn,
    eventsColumn,
    commandsColumn,
    createdAtColumn,
    updatedAtColumn,
    actionsColumn,
  ];
}
