import { Box } from '@mui/material';
import CalendarPicker from './CalendarPicker';
import ItemsPicker from './ItemsPicker';

export const TelemetryFiltersForm = () => {
  return (
    <Box sx={{ display: 'grid', gap: 2, gridTemplate: 'auto / 1fr 1fr' }}>
      <CalendarPicker />
      <ItemsPicker />
    </Box>
  );
};
