import { OverviewDispensingStatisticsResponse } from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import { isValidNumber } from '@/shared/utils';
import { List, ListItem, ListItemText } from '@mui/material';
import dayjs from 'dayjs';

export default function WaterDispensedPrintResults({
  waterDispensed,
  isGallons,
}: {
  waterDispensed: OverviewDispensingStatisticsResponse['waterDispensed'] | undefined;
  isGallons: boolean;
}) {
  return (
    <List
      sx={{
        display: 'none',
        '@media print': {
          display: 'flex',
          flexWrap: 'wrap',
        },
      }}
    >
      {waterDispensed?.value.map((data) => (
        <ListItem dense key={data.id} style={{ width: 'auto' }}>
          <ListItemText
            primaryTypographyProps={{ fontSize: '8px' }}
            secondaryTypographyProps={{ fontSize: '8px' }}
            primary={dayjs(data.day).format('L LTS')}
            secondary={`${
              isGallons && isValidNumber(data.totalVolume) ? (data.totalVolume / 3.785).toFixed(2) : data.totalVolume
            } ${isGallons ? 'Gallons' : 'Liters'}`}
          />
        </ListItem>
      ))}
    </List>
  );
}
