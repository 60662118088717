import { GroupTelemetryState, SingleTelemetry } from '../../typings';

export type GroupTelemetry = Pick<GroupTelemetryState, 'tagName'> & {
  key: Array<string> | string;
};

export type DateChangeAction = {
  type: 'date/change';
  payload: {
    start: number;
    end: number;
  };
};

export type TelemetryFiltersPayload = SingleTelemetry | GroupTelemetry;

export type TelemetryFiltersAddAction = {
  type: 'telemetry_filter/add';
  payload: TelemetryFiltersPayload;
};

export type TelemetryFiltersRemoveAction = {
  type: 'telemetry_filter/remove';
  payload: TelemetryFiltersPayload;
};

export type ChangeFilterAction = DateChangeAction | TelemetryFiltersAddAction | TelemetryFiltersRemoveAction;

export const CHANGE_DATE_RANGE = 'date/change' as const;
export const changeDateAction = (dateRange: DateChangeAction['payload']) => ({
  type: CHANGE_DATE_RANGE,
  payload: dateRange,
});

export const ADD_TELEMETRY = 'telemetry_filter/add' as const;
export const addTelemetryAction = (telemetry: TelemetryFiltersPayload) => ({ type: ADD_TELEMETRY, payload: telemetry });

export const REMOVE_TELEMETRY = 'telemetry_filter/remove' as const;
export const removeTelemetryAction = (telemetry: TelemetryFiltersPayload) => ({
  type: REMOVE_TELEMETRY,
  payload: telemetry,
});
