import { Fragment } from 'react';
import { AutucompleteAsyncFilterProps } from './typings';
import AutocompleteAsync from './AutocompleteAsync';

export default function AutocompleteAsyncFilter({
  config,
  kind,
  onFilterChange,
  ...props
}: AutucompleteAsyncFilterProps) {
  return kind === 'store' ? (
    <AutocompleteAsync
      {...props}
      defaultValue={config?.defaultValue}
      id={config.id}
      label={config.label}
      kind={config.kind}
      onFilterChange={onFilterChange}
      shouldFetch={config.shouldFetch}
      lazyQueryHook={config.lazyQueryHook}
      transformFn={config.transformFn}
      getQueryParam={config.getQueryParam}
      renderOption={config.renderOption}
      renderInput={config?.renderInput}
      debounceTime={config?.debounceTime}
      getInitialValue={config?.getInitialValue}
      style={config?.style}
    />
  ) : (
    // local version to be implemented.
    <Fragment />
  );
}
