import { CommandsTable } from '@/components/OneTools/Channels/Commands';
import { ROUTES } from '@/shared/constants';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityContext } from '../DeviceIdentityContext';
import { useCommandsColumns } from '@/components/OneTools/Channels/Commands/useCommandsColumns';

export default function Commands() {
  const { t } = useTranslation();
  const deviceIdentity = useContext(DeviceIdentityContext);
  const commandsColumns = useCommandsColumns(deviceIdentity?.commands);

  return (
    <CommandsTable
      title={t(ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment)}
      data={deviceIdentity?.commands}
      columns={commandsColumns}
      isLoading={false}
      isError={false}
      resetStateButtonVisible={false}
      exportData={false}
      selection={false}
    />
  );
}
