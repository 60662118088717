export const extractTelemetryFilters = (
  singleTelemetryFilters: Array<string> | undefined,
  groupTelemetryFilters: Array<{ tagName: string; key: Array<string> }> | undefined
) => {
  let acc: Array<string> = [];
  if (singleTelemetryFilters && singleTelemetryFilters.length) {
    acc = acc.concat(singleTelemetryFilters);
  }

  if (groupTelemetryFilters && groupTelemetryFilters.length) {
    const keyOfGroupTelemetryFilters = groupTelemetryFilters.reduce((acc, { key }) => {
      if (key.length) {
        const keyNoDuplicate = key.filter((k) => !acc.includes(k));
        return acc.concat(keyNoDuplicate);
      }
      return acc;
    }, acc);
    acc = acc.concat(keyOfGroupTelemetryFilters);
  }

  return acc;
};
