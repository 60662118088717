import { Column } from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TelemetryRes } from './typings';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { PRIMITIVES, ROLES } from '@/shared/constants';
import { getPath, isValidNumber } from '@/shared/utils';
import Area from '@/components/Shared/Card/Area';

export default function useTelemetryColumns(itemsInDeviceIdentity?: TelemetryRes[]): Array<Column<TelemetryRes>> {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data_columns_array: Array<Column<TelemetryRes>> = [
    {
      title: t('key'),
      field: 'id',
    },
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('description'),
      field: 'description',
    },
    {
      title: t('primitive'),
      field: 'primitive',
    },
    {
      title: t('unitOfMeasure'),
      field: 'unitOfMeasure',
    },
    {
      title: t('sendingStyle'),
      field: 'sendingStyle',
    },
    {
      title: t('sendingRule'),
      field: 'sendingRule',
      render: (telemetry) => telemetry.sendingRule && t(telemetry.sendingRule),
    },
    {
      title: t('value'),
      field: 'value',
    },
    {
      title: t('roles'),
      width: '20%',
      field: 'role',
      customFilterAndSearch: (filter, telemetry) => {
        const roles = telemetry?.role || [];
        const roleFilter = roles.some((role) => Object.values(ROLES).includes(role));

        return roleFilter && roles.some((role) => role.startsWith(filter));
      },
      render: (telemetry) =>
        telemetry.role?.map((r, index) => (
          <Typography sx={{ width: 'max-content' }} key={`${r}-${index}`}>
            {t(r)}
          </Typography>
        )),
      exportTransformer: (telemetry) =>
        (telemetry.role as string[])?.join ? (telemetry.role as string[])?.join(', ') : telemetry.role,
      sorting: false,
    },
    {
      title: t('triageValues'),
      width: '20%',
      render: (telemetry) => {
        let hasTriageValues;
        if (
          (telemetry.primitive === PRIMITIVES.BOOLEAN || telemetry.primitive === PRIMITIVES.STRING) &&
          telemetry.triageExpectedValue
        ) {
          hasTriageValues = true;
        }
        if (telemetry.primitive === PRIMITIVES.NUMBER && (telemetry.triageRangeMax || telemetry.triageRangeMin)) {
          hasTriageValues = true;
        }

        return (
          hasTriageValues && (
            <Area
              sx={{ backgroundColor: (theme) => theme.palette.background.grayShades[0], p: 1, borderRadius: 1, gap: 0 }}
            >
              {telemetry.primitive === PRIMITIVES.BOOLEAN && telemetry.triageExpectedValue && (
                <Typography sx={{ width: 'max-content' }}>{`${t('expectedValue')}: ${String(
                  telemetry.triageExpectedValue
                )}`}</Typography>
              )}
              {telemetry.primitive === PRIMITIVES.STRING && telemetry.triageExpectedValue && (
                <Typography sx={{ width: 'max-content' }}>{`${t('expectedValue')}: ${
                  telemetry.triageExpectedValue
                }`}</Typography>
              )}
              {telemetry.primitive === PRIMITIVES.NUMBER && isValidNumber(Number(telemetry?.triageRangeMin)) && (
                <Typography sx={{ width: 'max-content' }}>{`${t('min')}: ${telemetry.triageRangeMin}`}</Typography>
              )}
              {telemetry.primitive === PRIMITIVES.NUMBER && isValidNumber(Number(telemetry?.triageRangeMax)) && (
                <Typography sx={{ width: 'max-content' }}>{`${t('max')}: ${telemetry.triageRangeMax}`}</Typography>
              )}
            </Area>
          )
        );
      },
    },
    {
      title: t('alertIntegrationTable'),
      render: (telemetry) => {
        return <Typography>{telemetry.alertIntegration ? 'True' : 'False'}</Typography>;
      },
    },
    {
      title: t('tags'),
      render: (telemetry) => <Box>{telemetry.tags?.join(', ')}</Box>,
    },
    {
      title: t('creationDate'),
      field: 'createdAt',
      render: (telemetry) => dayjs(telemetry.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: t('lastUpdated'),
      field: 'updatedAt',
      render: (telemetry) => dayjs(telemetry.updatedAt).format('YYYY/MM/DD HH:mm'),
    },
  ];

  const actions_columns_array: Array<Column<TelemetryRes>> = itemsInDeviceIdentity
    ? [
        {
          title: t('actions'),
          width: '10%',
          render: (telemetry) => (
            <Box display="flex" justifyContent="flex-start" gap="0.5rem">
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  navigate(getPath('ONETOOL_CHANNELS_TELEMETRY'), {
                    state: {
                      searchText: telemetry.name,
                      highlight: {
                        refField: 'id',
                        matchValue: telemetry.id,
                      },
                    },
                  })
                }
              >
                {t('view')}
              </Button>
            </Box>
          ),
          sorting: false,
          searchable: false,
        },
      ]
    : [];

  return [...data_columns_array, ...actions_columns_array];
}
