import { useTranslation } from 'react-i18next';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import { ROUTES } from '@/shared/constants';
import { Outlet, useNavigate } from 'react-router';
import useCurrentTab from '@/hooks/useCurrentTab';

function Channels() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabsConfig = [
    {
      id: ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_EVENTS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_ALARMS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_COMMANDS.fragment),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_CONSUMABLES.fragment),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_CONSUMABLE_SUBSETS.fragment),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_REACTIONS.fragment),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_CONFIGS.fragment),
    },
    {
      id: ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment,
      label: t(ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => navigate(ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment),
    },
  ] satisfies TabConfig[];

  const { index } = useCurrentTab(tabsConfig);

  return (
    <>
      {index !== 0 && <NavigationTabsLayout config={tabsConfig} defaultIndex={index - 1} />}
      <Outlet />
    </>
  );
}

export default Channels;
