import {
  useLazyGetBrandQuery,
  useGetBrandsQuery,
  usePostBrandMutation,
  useDeleteBrandMutation,
  usePutBrandMutation,
  useUploadLogoMutation,
  useLazyIsBrandUniqueQuery,
} from '@/redux/api/system/brandsApiSlice';
import { useBrandColumns } from './useBrandColumns';
import { BrandReq, BrandRes } from './typings';
import useAddEditDialog from '@/hooks/useAddEditDialog';
import { useTranslation } from 'react-i18next';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import useUniqueness from '@/hooks/useUniqueness';
import { ROUTES } from '@/shared/constants';

export const BrandsTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<BrandRes>));

export default function BrandsPanel() {
  const { t } = useTranslation();

  const [getBrand] = useLazyGetBrandQuery();
  const { data, isLoading, isError } = useGetBrandsQuery();
  const [postBrandTrigger] = usePostBrandMutation();
  const [putBrandTrigger] = usePutBrandMutation();
  const [uploadLogo] = useUploadLogoMutation();
  const [deleteBrandTrigger] = useDeleteBrandMutation();
  const [isBrandUnique] = useLazyIsBrandUniqueQuery();
  const validateUniqueness = useUniqueness<BrandRes, 'name'>({ isUniqueTrigger: isBrandUnique });

  const { AddEditDialog, openDialog } = useAddEditDialog<BrandReq>({
    title: t('brand'),
    baseConfig: [
      {
        name: 'name',
        placeholder: t('name'),
        options: {
          required: t('fieldRequiredError') as string,
          setupValidate: (initialValue) => (currValue, formValues, prevValue, prevResult) => {
            if (currValue === prevValue) {
              return prevResult;
            }

            return currValue === initialValue || validateUniqueness('name', currValue);
          },
        },
      },
      {
        name: 'countries',
        placeholder: t('countries'),
        options: { required: t('fieldRequiredError') as string },
        helperText: t('commaSeparatedValuesHelperText', { subject: t('countries') }) as string,
      },
      {
        name: 'address',
        placeholder: t('address'),
      },
      {
        name: 'domain',
        placeholder: t('domain'),
        helperText: t('commaSeparatedValuesHelperText', { subject: t('domain') }) as string,
      },
      {
        name: 'color',
        placeholder: t('color'),
        type: 'color',
      },
      {
        type: 'file',
        name: 'logo',
        placeholder: t('logo'),
        fileConfig: {
          preview: { default: true },
          accept: 'image/png, image/svg+xml',
        },
        helperText: t('acceptedTypes', {
          types: 'image/png, image/svg+xml',
          interpolation: { escapeValue: false },
        }) as string,
      },
    ],
    getEditData: async (dialogId: string | boolean) => {
      const res: any = await getBrand(dialogId as string);
      const brand: BrandRes = { ...res.data?.data };
      brand.countries = (brand.countries as string[]).join(', ');
      return brand;
    },
    onSubmit: async (dialogId: string | boolean, data) => {
      const isEditing = typeof dialogId === 'string';

      function _uploadLogo(id: string) {
        const hasFiles = Object.values(data).some((value) => value instanceof File);
        if (hasFiles) {
          Object.values(data).forEach(async (value) => {
            if (value instanceof File) {
              const form = new FormData();
              form.append('id', id);
              form.append('mime', value?.type);
              form.append('logo', value);
              await uploadLogo(form);
            }
          });
        }
      }

      if (data.countries && data.countries.includes(',')) {
        data.countries = (data.countries as unknown as string)
          .split(',')
          .filter((country) => !!country)
          .map((country) => country.trim().toUpperCase());
      }

      if (data.countries) {
        if (typeof data.countries === 'string') {
          if (data.countries.includes(',')) {
            data.countries = data.countries
              .split(',')
              .filter((country) => !!country)
              .map((country) => country.trim().toUpperCase());
          } else {
            data.countries = [data.countries.trim().toUpperCase()];
          }
        }
      } else {
        data.countries = [];
      }

      if (data.domain && data.domain.includes(',')) {
        data.domain = (data.domain as unknown as string)
          .split(',')
          .filter((domain) => !!domain)
          .map((domain) => domain.trim());
      }

      const _data: any = { ...data };
      typeof _data.logo !== 'string' && delete _data.logo;

      if (isEditing) {
        await putBrandTrigger({ id: dialogId, body: _data });
        if (data.logo && data.logo instanceof File) _uploadLogo(dialogId);
      } else {
        const res = await postBrandTrigger(_data);
        if (data.logo) _uploadLogo((res as any).data?.data?.id);
      }
    },
    validateMode: 'onBlur',
    revalidateMode: 'onBlur',
  });

  const { ConfirmDialog: DeleteDialog, confirm: confirmDeletion } = useConfirmDialog({
    title: t('delete') + ' ' + t('brand'),
    message: (extra: any) => t('deleteMessage', { entity: t('brand'), name: extra.name }) as string,
    onConfirm: (extra: any) => deleteBrandTrigger({ id: extra.id, name: extra.name }),
  });

  const columns = useBrandColumns(openDialog, confirmDeletion);

  return (
    <>
      <BrandsTable
        title={t(ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment)}
        data={data?.data?.items}
        columns={columns}
        isLoading={isLoading}
        isError={isError}
        resetStateButtonVisible={!isLoading}
        resetStateButtonLabel={t('add') + ' ' + t('brand')}
        onResetStateButtonClick={openDialog}
      />

      <AddEditDialog />

      <DeleteDialog />
    </>
  );
}
