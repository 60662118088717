import { Box, Button, Typography } from '@mui/material';
import TelemetryStringCard from './TelemetryStringCard';
import TelemetryNumberCard from './TelemetryNumberCard';
import TelemetryBooleanCard from './TelemetryBooleanCard';
import { DeviceTelemetry } from '../typings';
import { useTranslation } from 'react-i18next';
import { clamp } from '@/shared/utils';
import { PRIMITIVES } from '@/shared/constants';
import { TelemetryGroupContext } from './TelemetryGroupContext';
import useScrollWithOffset from '@/hooks/useScrollWithOffset';
import { HISTORYDATASECTION_ID } from '..';
import { CulliganCard } from '@/components/Shared/Card';
import { useTelemetryFiltersHandlerCtx } from '../TelemetryProvider';

export default function TelemetryGroupCard({
  title,
  telemetriesGroup,
}: {
  title: string;
  telemetriesGroup: DeviceTelemetry[];
}) {
  const { t } = useTranslation();
  const { handleAddTelemetryFilter } = useTelemetryFiltersHandlerCtx();
  const lastIndex = telemetriesGroup.length - 1;
  const isEven = telemetriesGroup.length % 2 === 0;
  const columns = clamp(Math.ceil(telemetriesGroup.length / 2), 2);
  const scrollElementIntoViewWithOffset = useScrollWithOffset(HISTORYDATASECTION_ID);

  const getTelemetryCard = (telemetry: DeviceTelemetry, index: number) => {
    if (telemetry?.primitive === PRIMITIVES.STRING) {
      return (
        <TelemetryStringCard
          key={telemetry.id}
          telemetry={telemetry}
          group={true}
          double={index === lastIndex && !isEven}
          title={title}
        />
      );
    }
    if (telemetry?.primitive === PRIMITIVES.NUMBER) {
      return (
        <TelemetryNumberCard
          key={telemetry.id}
          telemetry={telemetry}
          group={true}
          double={index === lastIndex && !isEven}
          title={title}
        />
      );
    }
    if (telemetry?.primitive === PRIMITIVES.BOOLEAN) {
      return (
        <TelemetryBooleanCard
          key={telemetry.id}
          telemetry={telemetry}
          group={true}
          double={index === lastIndex && !isEven}
          title={title}
        />
      );
    }
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'baseline'}>
        <Typography variant="h6" marginBottom={'1rem'}>
          {title}
        </Typography>
        <Button
          onClick={() => {
            scrollElementIntoViewWithOffset();
          }}
        >
          {t('history')}
        </Button>
      </Box>

      <TelemetryGroupContext.Provider value={{ setKeys: () => handleAddTelemetryFilter({ tagName: title, key: '' }) }}>
        <CulliganCard
          sx={{
            display: 'grid',
            alignItems: 'stretch',
            gridTemplateColumns: `repeat(${columns}, auto)`,
            gridTemplateRows: 'auto',
          }}
        >
          {telemetriesGroup?.map((telemetry: any, index: number) => getTelemetryCard(telemetry, index))}
        </CulliganCard>
      </TelemetryGroupContext.Provider>
    </Box>
  );
}
