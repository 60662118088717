import { doubleDateRange, withoutNullables } from '@/shared/utils';
import apiSlice, { API_VERSION } from '../apiSlice';

type CommonResponse<T> = {
  success: boolean;
  data: {
    items: T;
  };
};

type OverviewDispensingStatisticsRequest = {
  brand?: string;
  type?: string;
  customer?: string;
  start: number;
  end: number;
};

export type UsersFilterRequest = {
  query: string;
};

export type UsersFilterResponse = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type TimeSeriesValue = {
  value: number;
  date: string;
  totalVolume: number;
  day: string;
  id: string;
  type: string;
};

export type AggregatedValue = {
  value: number;
  label: string;
};

type ExtendedAggregatedValue = AggregatedValue & {
  totalAmount: number;
  waterType: number;
};

type StatisticDataRes<T extends AggregatedValue | TimeSeriesValue> = {
  status: 'fullfilled' | 'rejected';
  total?: number;
  value: T[];
};

export type OverviewDispensingStatisticsResponse = {
  waterDispensed?: StatisticDataRes<TimeSeriesValue>;
  pourings?: StatisticDataRes<TimeSeriesValue>;
  filtersStatus?: StatisticDataRes<TimeSeriesValue>;
  waterTypes?: StatisticDataRes<AggregatedValue>;
  topThreeFlavorsResult?: StatisticDataRes<AggregatedValue>;
  waterFlavorMix?: StatisticDataRes<AggregatedValue>;
  preferredDrinkCombinations?: StatisticDataRes<ExtendedAggregatedValue>;
};

const dispensingStatisticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOverview: builder.query<
      CommonResponse<OverviewDispensingStatisticsResponse>,
      OverviewDispensingStatisticsRequest
    >({
      query: (filters: OverviewDispensingStatisticsRequest) => {
        const doubledDateRange = doubleDateRange(filters.start, filters.end);
        const filtersWithDoubledDateRange = {
          ...filters,
          start: doubledDateRange.start,
          end: doubledDateRange.end,
        };
        const _filters = withoutNullables({
          ...filtersWithDoubledDateRange,
          start: String(filtersWithDoubledDateRange.start),
          end: String(filtersWithDoubledDateRange.end),
        });
        return `${API_VERSION.v2}/business-analysis/dispensing-statistics/overview?` + new URLSearchParams(_filters);
      },
    }),
    getUsers: builder.query<CommonResponse<UsersFilterResponse[]>, UsersFilterRequest>({
      query: (filter: UsersFilterRequest) =>
        `${API_VERSION.v2}/business-analysis/dispensing-statistics/users?` + new URLSearchParams(filter),
    }),
  }),
});

export const { useLazyGetOverviewQuery, useGetOverviewQuery, useLazyGetUsersQuery } = dispensingStatisticsApiSlice;
