import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useMemo } from 'react';
import { MarkerInfo } from '@/components/Shared/Map/typings';
import { MARKER_TYPE } from '@/shared/constants';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import LoadingMap from './LoadingMap';
import CulliganMap from '@/components/Shared/Map';
import { isValidNumber } from '@/shared/utils';
import InfoPanel from '@/components/Shared/Map/InfoPanel';
import DeviceInfoPanelHeader from '@/components/Shared/Map/Device/InfoPanelHeader';
import { FiltersContext } from '../FiltersContext';
import { useGetFleetQuery } from '@/redux/api/fleet/fleetApiSlice';
import { FleetOverview } from '../typings';
import DeviceMarker from '@/components/Shared/Map/Device/DeviceMarker';

const DevicesLocationMap = () => {
  const { t } = useTranslation();
  const filters = useContext(FiltersContext);
  const { isError, isLoading, data } = useGetFleetQuery(filters);
  const devices = useMemo(() => data?.data?.devices || [], [data]);
  const result = useMemo(
    () =>
      devices?.reduce(
        (
          acc: { devicesWithCoordinates: FleetOverview.Device[]; devicesWithoutCoordinates: FleetOverview.Device[] },
          device: FleetOverview.Device
        ) => {
          if (
            isValidNumber(device.lat ?? device.detectedLocation?.lat) &&
            isValidNumber(device.lon ?? device.detectedLocation?.lon)
          ) {
            acc.devicesWithCoordinates.push(device);
          } else {
            acc.devicesWithoutCoordinates.push(device);
          }

          return acc;
        },
        {
          devicesWithCoordinates: [],
          devicesWithoutCoordinates: [],
        }
      ),
    [devices]
  );

  const markers: MarkerInfo[] = useMemo(() => {
    return result?.devicesWithCoordinates?.map((fd) => ({
      /** Type guard isValidNumber asserted on reduction. */
      position: {
        lat: (fd.lat ?? fd.detectedLocation?.lat) as number,
        lng: (fd.lon ?? fd.detectedLocation?.lon) as number,
        accuracy: fd.detectedLocation?.accuracy,
      },
      title: fd.name || '',
      description: fd.id,
      type: MARKER_TYPE.DEVICE,
      deviceQNumber: fd.metaData?.deviceQnumber,
      manufacturingSerialNumber: fd.metaData?.manufacturingSerialNumber,
      extra: {
        serialNumber: fd.id,
        name: fd.name,
        model: fd.model,
        id: fd.id,
        status: {
          errors: fd?.errors?.length || 0,
          alarms: fd?.alarms?.length || 0,
        },
      },
    }));
  }, [result?.devicesWithCoordinates]);

  const decodeMarkerInfo = useCallback(
    (marker: MarkerInfo) => ({
      ...marker.position,
    }),
    []
  );

  const SafeMapBody = withErrorLoadingManagement(() => (
    <Box position="relative" height="400px" overflow="hidden" borderRadius={1}>
      <CulliganMap
        data={markers}
        decode={decodeMarkerInfo}
        createMarker={(props) => <DeviceMarker {...props} />}
        slots={{
          InfoPanel: (props) => (
            <InfoPanel
              {...props}
              slots={{
                Header: (props) => (
                  <DeviceInfoPanelHeader {...props} title={props.marker.title} subtitle={props.marker.description} />
                ),
              }}
            />
          ),
        }}
        noDataMessage={t('noDevicesFound') as string}
        id="devicesLocationMap"
      />
    </Box>
  ));

  return <SafeMapBody isError={isError} isLoading={isLoading} LoadingComponent={<LoadingMap />} />;
};

export default DevicesLocationMap;
