import { Fragment, useContext } from 'react';
import StatisticsCard from '../Shared/StatisticsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import LineChartCard from './LineChartCard';
import withMissingDataManagement from './withMissingDataManagement';
import PouringsPrintResult from './PouringsPrintResults';
import { FiltersContext } from './FiltersContext';
import { useGetOverviewQuery } from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';

const SafeLineChart = withMissingDataManagement(LineChartCard);

export default function PouringsCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const filters = useContext(FiltersContext);
  const { data } = useGetOverviewQuery(filters);
  const filteredData = data?.data.items;
  const totalPouringsVolume =
    filteredData?.pourings?.value.reduce((acc, current, _, array) => {
      return acc + (current.totalVolume || 0) / array.length;
    }, 0) || 0;
  const totalPouringsAmount =
    filteredData?.pourings?.value.reduce((acc, current) => {
      return acc + (current.value || 0);
    }, 0) || 0;
  const bigNumberLabel = ['Completed'];
  const hasFilteredData = filteredData && filteredData.pourings && filteredData.pourings.value.length > 1;

  return (
    <Fragment>
      <StatisticsCard
        variant="boxed"
        title={t('pourings')}
        action={{
          label: t('details'),
          fn: () => navigate('/'),
        }}
        tooltipText={t('pouringsTooltip') || ''}
      >
        <SafeLineChart
          hasData={!!(filteredData && filteredData.pourings && filteredData.pourings.value.length > 1)}
          data={filteredData?.pourings?.value.map((pouring) => ({ x: pouring.day, y: pouring.value }))}
          id={'pourings'}
          smallNumber={totalPouringsAmount}
          hasSmallBottomCard={false}
          isPercentage={true}
          bigNumberLabel={bigNumberLabel}
          isValueTogglable={false}
          smallLabel={'successfull pourings'}
          bigNumber={totalPouringsVolume}
        />
      </StatisticsCard>
      {hasFilteredData ? <PouringsPrintResult pourings={filteredData?.pourings} /> : null}
    </Fragment>
  );
}
