import StoreFilters from '../Shared/Filters/StoreFilters';
import Button from '@mui/material/Button/Button';
import { Box, Typography } from '@mui/material';
import CulliganFAB from '@/components/Shared/CulliganFAB';
import StatisticsRow from '@/components/Shared/StatisticsRow';
import WaterDispensedCard from './WaterDispensedCard';
import TopFlavorsCard from './TopFlavorsCard';
import dayjs from 'dayjs';
import WaterTypesCard from './WaterTypesCard';
import TopWaterFlavorMixCard from './TopWaterFlavorMixCard';
import PouringsCard from './PouringsCard';
import FiltersStatusCard from './FiltersStatusCard';
import { withoutNullables } from '@/shared/utils';
import {
  AggregatedValue,
  OverviewDispensingStatisticsResponse,
  TimeSeriesValue,
  useGetOverviewQuery,
} from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';
import { useState } from 'react';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import Header from '../Shared/Print/Header';
import { ExportCsv } from '@material-table/exporters';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Column } from '@material-table/core';
import PrintFilters from '../Shared/Filters/PrintFilters';
import PreferredWaterTypeAndFlavorCard from './PreferredWaterTypeAndFlavorCard';
import { useBusinessAnalysisFilters } from './useBusinessAnalysisFilters';
import { FiltersContext } from './FiltersContext';

function handleCsvExport(filteredData: OverviewDispensingStatisticsResponse | undefined) {
  if (!filteredData) {
    return;
  }
  const uniqueKeys = new Map<string, Column<Partial<TimeSeriesValue & AggregatedValue>>>();
  for (const key in filteredData) {
    filteredData[key as keyof typeof filteredData]?.value.forEach((value) => {
      Object.keys(value).forEach((k) => {
        if (uniqueKeys.has(k)) {
          return;
        }
        uniqueKeys.set(k, {
          title: i18n.t(k),
          field: k,
          ...(k === 'day'
            ? {
                exportTransformer: (row) => row.day && dayjs(row.day).format('YYYY/MM/DD HH:mm:ss'),
              }
            : {}),
        });
      });
    });
  }
  const columns = [...uniqueKeys.values()];

  columns.unshift({ title: i18n.t('entity'), field: 'entity' });

  const data = Object.keys(filteredData).reduce<Partial<TimeSeriesValue & AggregatedValue & { entity: string }>[]>(
    (acc, k) => {
      return [
        ...acc,
        ...(filteredData[k as keyof typeof filteredData]?.value.map((v) => ({
          entity: k,
          ...v,
        })) || []),
      ];
    },
    []
  );

  ExportCsv<Partial<TimeSeriesValue & AggregatedValue>>(columns, data, 'Business Analysis');
}

export default function Overview() {
  const { t } = useTranslation();
  const {
    isLoadingFilters,
    isErrorFilters,
    filterConfig,
    filtersOptions,
    handleFiltersApplied,
    handleFiltersCleared,
    filters,
  } = useBusinessAnalysisFilters();
  const { data, isLoading, isFetching } = useGetOverviewQuery(filters);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const filteredData = data?.data?.items;
  const filteredFiltersState = withoutNullables(filters);
  const hasTopWaterFlavorMixData =
    filteredData && filteredData.waterFlavorMix && filteredData.waterFlavorMix.value.length > 0;
  const hasFilterStatusData = filteredData && filteredData.filtersStatus && filteredData.filtersStatus.value.length > 0;

  const dialogBody = (
    <Box>
      <Typography variant="h5" fontWeight={400}>
        {t('generateReportBusinessOverview')}
      </Typography>
      <Box
        sx={{
          marginTop: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <Button variant="outlined" onClick={() => window.print()} disabled={isLoading || isFetching}>
          {t('downloadPDF')}
        </Button>
        <Button variant="outlined" onClick={() => handleCsvExport(filteredData)} disabled={isLoading || isFetching}>
          {t('downloadCSV')}
        </Button>
      </Box>
    </Box>
  );

  const { dialog } = useCulliganDialog({
    open: openDialog,
    handleClose: (_: any, _reason: any) => setOpenDialog(false),
    tabs: [
      {
        id: 'exportDialog',
        label: t('downloadTheReport'),
        body: dialogBody,
      },
    ],
    styles: {
      dialogContentContainer: {
        p: 5,
      },
    },
  });

  const handleDialog = () => {
    setOpenDialog(true);
  };

  return (
    <Box>
      {dialog}
      <CulliganFAB label={t('export')} onClick={() => handleDialog()} />
      {!isLoadingFilters && !isErrorFilters && (
        <StoreFilters
          sx={{ '@media print': { display: 'none' } }}
          filterConfigs={filterConfig}
          onFiltersApplied={handleFiltersApplied}
          onFiltersCleared={handleFiltersCleared}
        />
      )}
      <FiltersContext.Provider value={filters}>
        <Header />
        <Box
          sx={{
            marginTop: '100px',
          }}
        >
          <PrintFilters
            filtersState={filteredFiltersState}
            transformers={{
              brand: (value) => filtersOptions?.data?.items.brands.find((item) => item.id === value)?.name || value,
              customer: (value) =>
                filtersOptions?.data?.items.customer.find((item) => item.id === value)?.firstName || value,
            }}
          />

          <StatisticsRow
            sx={(theme) => ({
              gridTemplate: 'auto / 1fr 1fr',
              [theme.breakpoints.down('lg')]: {
                gridTemplate: '1fr / 1fr',
              },
            })}
          >
            <WaterDispensedCard />
            <PouringsCard />
          </StatisticsRow>

          <StatisticsRow
            sx={(theme) => ({
              gridTemplate: 'auto / 3fr 2fr',
              [theme.breakpoints.down('lg')]: {
                gridTemplate: '1fr / 1fr',
              },
            })}
          >
            <WaterTypesCard />
            <TopFlavorsCard />
          </StatisticsRow>
          <StatisticsRow
            sx={(theme) => ({
              gridTemplate: 'auto / 2fr 3fr',
              [theme.breakpoints.down('lg')]: {
                gridTemplate: '1fr / 1fr',
              },
            })}
          >
            {hasTopWaterFlavorMixData && <TopWaterFlavorMixCard />}
            {hasFilterStatusData && <FiltersStatusCard />}
          </StatisticsRow>

          <StatisticsRow>
            <PreferredWaterTypeAndFlavorCard />
          </StatisticsRow>
        </Box>
      </FiltersContext.Provider>
    </Box>
  );
}
