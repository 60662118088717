import { AREA_DESIGN_TOKENS, AreaBody, AreaHeader, StatusAreaContainer } from '@/components/Shared/Card/Area';
import { Subtitle } from '@/components/Shared/Card/Area/Text';
import { SxProps, Theme, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import LoadingFeedback from '../../../Fleet/Devices/DevicePanel/ConsumablesPanel/LoadingFeedback';

interface AreaButtonProps {
  disabled?: Array<{ disabled: boolean; reason?: string }>;
  handler: () => void;
  color: string | (() => string);
  text: string;
  Icon: JSX.Element & any;
  sx?: SxProps<Theme>;
  isLoading?: boolean;
}

function AreaButton({ disabled, isLoading, handler, color, text, Icon, sx }: AreaButtonProps) {
  const TRANSPARENT = '#f0f8ff00';
  const disableReason = disabled?.find((r) => r.disabled === true);
  const _color = typeof color === 'function' ? color() : color;

  const onClickHandler = useCallback(() => {
    if (!disableReason?.disabled) {
      handler();
    }
  }, [disableReason?.disabled, handler]);

  return (
    <StatusAreaContainer
      shade={TRANSPARENT}
      disabled={disableReason ? disableReason.disabled : false}
      hoverShade={_color}
      hoverLineColor={_color}
      onClick={onClickHandler}
      sx={sx ? sx : {}}
    >
      <LoadingFeedback loading={isLoading || false} />
      <AreaHeader sx={{ justifyContent: 'center', opacity: disableReason?.disabled ? 0.4 : 1 }}>
        <Subtitle>{text}</Subtitle>
      </AreaHeader>
      <AreaBody sx={{ alignItems: 'center' }}>
        <Tooltip title={disableReason?.disabled ? disableReason?.reason || '' : ''} placement="top">
          <Icon
            sx={{
              fontSize: AREA_DESIGN_TOKENS.buttonIconSize,
              fill: _color,
              opacity: !disableReason?.disabled ? 1 : 0.4,
            }}
          />
        </Tooltip>
      </AreaBody>
    </StatusAreaContainer>
  );
}

export default React.memo(AreaButton);
