import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import Header from '@/components/Shared/Print/Header';
import { ROUTES } from '@/shared/constants';
import { useContext } from 'react';
import { DeviceIdentityContext } from './DeviceIdentityContext';
import { CulliganCard } from '@/components/Shared/Card';

export default function DeviceIdentitySection() {
  const { t } = useTranslation();
  const deviceIdentity = useContext(DeviceIdentityContext);

  return (
    <>
      <Header>
        <Typography variant="h4" fontWeight={400} sx={{ fontSize: '23pt' }}>
          {t('deviceIdentitySpecs')}
        </Typography>
      </Header>
      <Box
        sx={{
          display: 'grid',
          gridTemplate: `min-content / ${deviceIdentity?.image ? 'min-content auto' : 'auto'}`,
          marginY: 2,
          gap: 2,
          '& *': {
            printColorAdjust: 'exact',
            WebkitPrintColorAdjust: 'exact',
          },
        }}
      >
        {/* Image card */}
        {deviceIdentity?.image && (
          <CulliganCard
            wrapInBox={false}
            sx={{
              padding: 2,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              '@media print': {
                backgroundColor: (theme) => theme.palette.background.grayShades[0],
                boxShadow: '0',
              },
            }}
          >
            <Typography variant="body1" color="text.secondary" mb={1}>
              {t('image')}
            </Typography>
            <Box
              sx={{
                flexGrow: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={deviceIdentity?.image} alt={deviceIdentity?.name}></img>
            </Box>
          </CulliganCard>
        )}
        {/* Device Info */}
        <CulliganCard
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridTemplateRows: 'repeat(4, min-content)',
            '@media print': {
              background: 'none',
              padding: 0,
              boxShadow: '0',
            },
          }}
        >
          <Box
            sx={{
              gridColumn: 'span 7',
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {t('name')}
            </Typography>
            <Typography variant="h5" fontWeight={800}>
              {deviceIdentity?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 5',
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {t('code')}
            </Typography>
            <Typography variant="h5" fontWeight={800}>
              {deviceIdentity?.id}
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 9',
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {t('description')}
            </Typography>
            <Typography variant="h6" fontWeight={400} sx={{ lineHeight: 1.25 }}>
              {deviceIdentity?.description}
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 3',
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {t('connectivity')}
            </Typography>
            {deviceIdentity?.connectivity?.map((r, index) => (
              <Typography
                variant="h6"
                sx={{ lineHeight: 1.25, width: 'max-content' }}
                fontWeight={400}
                key={`${r}-${index}`}
              >
                {t(r)}
              </Typography>
            ))}
          </Box>
          <Box
            sx={{
              gridColumn: 'span 5',
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {t(ROUTES.ONETOOL_ECOSYSTEM_BRANDS.fragment)}
            </Typography>
            <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
              {deviceIdentity?.brands?.map((brand: any, index) => (
                <Box key={index} display="flex" alignItems="center" gap={1}>
                  <Typography variant="body1" fontWeight={400}>
                    {brand}
                  </Typography>
                  <Box
                    style={{
                      backgroundColor: brand.color,
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                    }}
                  ></Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 7',
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {t('type')}
            </Typography>
            <Typography variant="h6" fontWeight={400}>
              {deviceIdentity?.type}
            </Typography>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 5',
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {t('tier')}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end">
              <Typography variant="h6" fontWeight={400}>
                {deviceIdentity?.tier}
              </Typography>
              <Box display="flex">
                <Box
                  sx={{
                    position: 'relative',
                    backgroundColor: (theme) => theme.palette.primary.light,
                    height: '40px',
                    width: '40px',
                    borderRadius: '100%',
                    left: '20px',
                    ...(deviceIdentity?.tier === 1
                      ? {
                          outline: (theme) => `4px solid ${theme.palette.background.grayShades[0]}`,
                          zIndex: 2,
                        }
                      : {}),
                  }}
                ></Box>
                <Box
                  sx={{
                    position: 'relative',
                    backgroundColor: (theme) => theme.palette.primary.main,
                    height: '40px',
                    width: '40px',
                    borderRadius: '100%',
                    left: '10px',
                    ...(deviceIdentity?.tier === 2
                      ? {
                          outline: (theme) => `4px solid ${theme.palette.background.grayShades[0]}`,
                          zIndex: 1,
                        }
                      : {}),
                    ...(deviceIdentity?.tier === 1 ? { zIndex: 1 } : {}),
                  }}
                ></Box>
                <Box
                  sx={{
                    position: 'relative',
                    backgroundColor: (theme) => theme.palette.primary.dark,
                    height: '40px',
                    width: '40px',
                    borderRadius: '100%',
                    ...(deviceIdentity?.tier === 3
                      ? {
                          outline: (theme) => `4px solid ${theme.palette.background.grayShades[0]}`,
                          zIndex: 1,
                        }
                      : {}),
                  }}
                ></Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              gridColumn: 'span 7',
              backgroundColor: (theme) => theme.palette.background.grayShades[0],
              borderRadius: 2,
              p: 2,
            }}
          >
            <Typography variant="body1" color="text.secondary">
              {t('alwaysConnected')}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="flex-end">
              <Typography variant="h6" fontWeight={400}>
                {deviceIdentity?.alwaysConnected ? t('yes') : t('no')}
              </Typography>
              <Box
                sx={{
                  backgroundColor: (theme) =>
                    deviceIdentity?.alwaysConnected ? theme.palette.success.main : theme.palette.error.main,
                  height: '40px',
                  width: '40px',
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {deviceIdentity?.alwaysConnected ? (
                  <CheckIcon sx={{ fontSize: 20, color: (theme) => theme.palette.background.grayShades[0] }} />
                ) : (
                  <ClearIcon sx={{ fontSize: 20, color: (theme) => theme.palette.background.grayShades[0] }} />
                )}
              </Box>
            </Box>
          </Box>
        </CulliganCard>
      </Box>
    </>
  );
}
