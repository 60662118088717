import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { FleetError } from '../typings';
import { useTranslation } from 'react-i18next';
import useErrorsColumns from './useErrorsColumns';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { MaterialTableProps } from '@material-table/core';
import { FleetOverview } from '../../Overview/typings';
import { DeviceError } from '../../Devices/DevicesPanel/typings';

export const SafeTable = withErrorLoadingManagement(withResetNavigationState(GenericExportTable<FleetError>));

type BaseProps = Omit<MaterialTableProps<any>, 'data' | 'columns'> & {
  excludeColumns?: string[];
  onDetailClick?: (id: FleetError['id']) => void;
  slots?: {
    dialog: () => ReturnType<typeof useCulliganDialog>['dialog'];
  };
};

type ErrorsTableProps =
  | (BaseProps & {
      kind: 'fleet';
      items: FleetError[];
    })
  | (BaseProps & {
      kind: 'overview';
      items: FleetOverview.Error[];
    })
  | (BaseProps & {
      kind: 'device';
      items: DeviceError[];
    });

export default function ErrorsTable({ items, kind, excludeColumns, slots, onDetailClick, ...props }: ErrorsTableProps) {
  const { t } = useTranslation();

  const columns = useErrorsColumns({
    kind: kind,
    onDetailClick: onDetailClick,
    excludeColumns: excludeColumns,
  });

  return (
    <>
      <GenericExportTable
        {...props}
        title={t('error')}
        data={items}
        columns={columns}
        exportData={true}
        selection={true}
      />
      {slots && slots.dialog()}
    </>
  );
}
