import { DeviceTelemetries, ITEM_TYPE } from './typings';
import { PRIMITIVES } from '@/shared/constants';

export const parseDeviceTelemetry = (data?: DeviceTelemetries) => {
  const telemetries = data?.telemetries || [];
  const telemetriesWithGroup = telemetries.filter((telemetry) => telemetry?.tags?.length);
  const telemetriesWithoutGroupTag = telemetries.filter((telemetry) => !telemetry?.tags);
  const telemetriesTags = [...new Set(telemetriesWithGroup.flatMap((telemetry) => telemetry?.tags))];

  const telemetriesGroups = telemetriesTags
    .filter((tag) => tag)
    .map((tag) => ({
      tag: tag,
      items: telemetriesWithGroup.filter((telemetry) => tag && telemetry?.tags?.includes(tag)),
    }))
    .map((group) => {
      if (group?.items?.length === 1) {
        const telemetry = group.items[0];
        const isAlreadyShowedAsSingleTelemetry = telemetriesWithoutGroupTag.some((t) => t.id === telemetry?.id);
        if (!isAlreadyShowedAsSingleTelemetry) {
          telemetriesWithoutGroupTag.push(group.items[0]);
        }
        return null;
      }
      return group;
    })
    .filter((group) => group && group.items.length)
    .sort((curr, next) => (next?.items.length || 0) - (curr?.items.length || 0));

  const getMergedTelemetries = () => {
    let merged = [];
    merged.push(
      ...(telemetriesWithoutGroupTag?.map((telemetry) => {
        return {
          id: telemetry.id,
          name: telemetry.name,
          type: ITEM_TYPE.SINGLE,
          items: undefined,
          ranges:
            telemetry.primitive === PRIMITIVES.NUMBER
              ? {
                  max: telemetry.max,
                  min: telemetry.min,
                }
              : undefined,
        };
      }) || []),
      ...(telemetriesGroups?.map((_group) => ({
        id: _group?.tag,
        name: _group?.tag,
        type: ITEM_TYPE.GROUP,
        items: _group?.items.map((telemetry) => {
          return {
            id: telemetry.id,
            name: telemetry.name,
            type: ITEM_TYPE.SINGLE,
            items: undefined,
            ranges:
              telemetry.primitive === PRIMITIVES.NUMBER
                ? {
                    max: telemetry.max,
                    min: telemetry.min,
                  }
                : undefined,
          };
        }),
      })) || [])
    );

    return merged;
  };

  return { telemetries, telemetriesWithoutGroupTag, telemetriesGroups, mergedTelemetries: getMergedTelemetries() };
};
