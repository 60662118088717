import { ApiResult } from '@typings';
import { ReactionRes } from '@/components/OneTools/Channels/Reactions/typings';
import apiSlice, { API_VERSION } from '../apiSlice';
import { CommonIsEntityUniqueBase } from '@/components/OneTools/typings';

const URL_PREFIX = `${API_VERSION.v2}/system/reactions`;

export const reactionsApiSlice = apiSlice.enhanceEndpoints({ addTagTypes: ['Reactions'] }).injectEndpoints({
  endpoints: (builder) => ({
    getReactions: builder.query<ApiResult<{ items: ReactionRes[] }>, void>({
      query: () => URL_PREFIX + '/list',
      providesTags: ['Reactions'],
    }),
    getReaction: builder.query<ApiResult<ReactionRes>, string>({
      query: (id: string) => ({
        url: URL_PREFIX,
        params: { id },
      }),
      providesTags: ['Reactions'],
    }),
    isReactionUnique: builder.query<ApiResult<{ name: boolean }>, Required<Pick<CommonIsEntityUniqueBase, 'name'>>>({
      query: (params: Required<Pick<CommonIsEntityUniqueBase, 'name'>>) => ({
        url: `${URL_PREFIX}/unique`,
        params,
      }),
    }),
  }),
});

export const {
  useGetReactionsQuery,
  useLazyGetReactionsQuery,
  useGetReactionQuery,
  useLazyGetReactionQuery,
  useLazyIsReactionUniqueQuery,
} = reactionsApiSlice;
