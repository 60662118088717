import { TelemetryTable } from '@/components/OneTools/Channels/Telemetry';
import { ROUTES } from '@/shared/constants';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityContext } from '../DeviceIdentityContext';
import useTelemetryColumns from '@/components/OneTools/Channels/Telemetry/useTelemetryColumns';

export default function Telemetry() {
  const { t } = useTranslation();
  const deviceIdentity = useContext(DeviceIdentityContext);
  const telemetryColumns = useTelemetryColumns(deviceIdentity?.telemetry);

  return (
    <TelemetryTable
      title={t(ROUTES.ONETOOL_CHANNELS_TELEMETRY.fragment)}
      data={deviceIdentity?.telemetry}
      columns={telemetryColumns}
      isLoading={false}
      isError={false}
      resetStateButtonVisible={false}
      exportData={false}
      selection={false}
    />
  );
}
