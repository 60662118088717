import { Autocomplete, Box, Checkbox, Chip, FormControlLabel, InputLabel, TextField, Typography } from '@mui/material';
import {
  AddEditDialogConfig,
  AddEditDialogConfigAutocomplete,
  AddEditDialogConfigBase,
  AddEditDialogConfigFile,
  AddEditDialogConfigNumber,
  AddEditDialogConfigSelectBox,
  AddEditDialogConfigSelectCard,
  AutocompleteOption,
  DialogInputProps,
  EnhancedValidate,
  SetupValidate,
} from './typings';
import { Controller, FieldValues, RegisterOptions, Validate, ValidateResult } from 'react-hook-form';
import FileInput from './FileInput';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ColorInputAdornement from './ColorInputAdornement';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DatePickerToolbarProps } from '@mui/x-date-pickers';
import { isValidNumber } from '@/shared/utils';
import NumberInput from './NumberInput';
import SelectCardInput from './SelectCardInput';
import { SelectBoxInput } from './SelectBoxInput';
import { memo, useMemo, useRef } from 'react';

export const REGEX_COLOR = /^#([0-9A-F]{6}|[0-9A-F]{8})$/;

const isNullOrEmptyString = (value: any) => value == null || value === '';

const getValidationOption = <TConfig extends AddEditDialogConfig>(config: TConfig) => {
  const _config = { ...config };
  const _setupValidate = (_config.options as { setupValidate: SetupValidate<TConfig['initialValue']> })
    ?.setupValidate as SetupValidate<TConfig['initialValue']>;

  if (_setupValidate && _config.options) {
    const setupValidate = _setupValidate(_config.initialValue);

    _config.options.validate = _config.options.validate
      ? {
          ...(typeof _config.options.validate === 'function' && {
            base: _config.options.validate,
          }),
          ...(typeof _config.options.validate === 'object' && {
            ..._config.options.validate,
          }),
          ...(typeof setupValidate === 'function' && {
            validateWithInitialValue: setupValidate as Validate<any, FieldValues>,
          }),
          ...(typeof setupValidate === 'object' && {
            ...setupValidate,
          }),
        }
      : (setupValidate as Validate<any, FieldValues>);

    delete (_config.options as { setupValidate?: SetupValidate<TConfig['initialValue']> })?.setupValidate;
  }

  return _config as Omit<TConfig, 'options'> & {
    options: Omit<TConfig['options'], 'setupValidate'> & { validate: EnhancedValidate<TConfig['initialValue']> };
  };
};

function DialogInput({
  type = 'text',
  configItem,
  hasErrors,
  register,
  control,
  disabled,
  getValues,
  onChange,
  ...props
}: DialogInputProps) {
  const { t } = useTranslation();
  const placeholder = configItem.placeholder && configItem.placeholder + (configItem.options?.required ? ' *' : '');

  const _configItem = useMemo(() => getValidationOption<typeof configItem>(configItem), [configItem]);
  const previousValue = useRef<typeof _configItem.initialValue>();
  const previousResult = useRef<ValidateResult | Promise<ValidateResult>>();

  const _enanchedValidate = useMemo(() => _configItem.options?.validate, [_configItem]);

  if (_configItem.options) {
    const validate = (async (value, formValues) => {
      if (typeof _enanchedValidate === 'function') {
        previousResult.current = await _enanchedValidate(
          value,
          formValues,
          previousValue.current,
          previousResult.current
        );
        previousValue.current = value;
        return previousResult.current;
      }
      for (const validate of Object.values(_configItem.options?.validate || {})) {
        previousResult.current = validate(value, formValues, previousValue.current, previousResult.current);
        if (previousResult.current !== true) {
          break;
        }
      }
      previousValue.current = value;
      return previousResult.current;
    }) as Validate<typeof configItem.initialValue, FieldValues>;
    _configItem.options.validate = validate;
  }

  switch (type) {
    case 'text': {
      return (
        <TextField
          label={placeholder}
          placeholder={
            placeholder ||
            (t('textField') as string) +
              ((_configItem.options as unknown as { required: boolean })?.required ? ' *' : '')
          }
          error={hasErrors}
          margin="dense"
          helperText={!hasErrors && configItem.helperText ? configItem.helperText : null}
          fullWidth
          // {...register(configItem.name, _configItem.options as unknown as RegisterOptions)}
          {...register(configItem.name, _configItem.options as unknown as RegisterOptions)}
          {...((disabled && { disabled: true }) || {})}
          {...props}
        />
      );
    }
    case 'checkbox': {
      return (
        <Controller
          control={control}
          name={configItem.name}
          rules={_configItem.options as unknown as RegisterOptions}
          defaultValue={_configItem.initialValue}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  value={field.value ?? false}
                  checked={field.value ?? false}
                  onChange={() => {
                    onChange({ name: configItem.name }, () => field.onChange(!field.value));
                  }}
                />
              }
              label={placeholder}
            />
          )}
        />
      );
    }
    case 'color': {
      return (
        <TextField
          label={placeholder || t('chooseColor') + (configItem.options?.required ? ' *' : '')}
          margin="dense"
          fullWidth
          error={hasErrors}
          helperText={configItem.helperText}
          InputProps={{
            startAdornment: <ColorInputAdornement color={getValues(configItem.name)} />,
          }}
          {...register(configItem.name, {
            ..._configItem.options,
            validate: {
              ..._configItem.options?.validate,
              startsWithHash: (value) => {
                return !value || /^#/.test(value) || (t('hexMustStartWithHash') as string);
              },
              validFormat: (value) => !value || REGEX_COLOR.test(value) || (t('invalidHexFormat') as string),
            },
            setValueAs: (value) => (value ? value.toUpperCase() : value),
          })}
        />
      );
    }
    case 'autocomplete': {
      return (
        <Controller
          control={control}
          name={configItem.name}
          rules={_configItem.options as unknown as RegisterOptions}
          render={({ field }) => {
            const _configItem = configItem as AddEditDialogConfigBase & AddEditDialogConfigAutocomplete;
            const isMultiple = _configItem.selectConfig?.multiple;
            const isRepeatable = _configItem.selectConfig?.repeatable;
            const fixedOptions =
              (_configItem.selectConfig?.options as AutocompleteOption[])?.filter((o) => o?.fixed && o?.value) || [];
            const registeredValue = field.value || (isMultiple ? [] : null);
            const valueWithFixedOptions = isMultiple
              ? registeredValue
                  .concat(fixedOptions)
                  .filter(
                    (option: AutocompleteOption, index: number, arr: AutocompleteOption[]) =>
                      arr.findIndex((o) => o.value === option.value) === index
                  )
              : registeredValue;
            const _value = fixedOptions.length ? valueWithFixedOptions : registeredValue;

            return (
              <Autocomplete
                options={
                  (isRepeatable
                    ? (_configItem.selectConfig?.options as AutocompleteOption[]).map((o) => ({
                        id: Math.random(),
                        ...o,
                      }))
                    : (_configItem.selectConfig?.options as AutocompleteOption[])) || []
                }
                getOptionLabel={(o) => o.label || ''}
                {...(isRepeatable ? { freeSolo: true } : {})}
                isOptionEqualToValue={(o, v) => o.value === v.value && (isRepeatable ? o.id === v.id : true)}
                multiple={isMultiple}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={placeholder || t('chooseOption') + (configItem.options?.required ? ' *' : '')}
                    error={hasErrors}
                    helperText={!hasErrors && configItem.helperText ? configItem.helperText : null}
                    margin="dense"
                  />
                )}
                renderTags={(tagValue, getTagProps, ownerState) => {
                  return tagValue.map((option: AutocompleteOption, index) => (
                    <Chip
                      sx={option?.style ? option.style : {}}
                      label={option.label}
                      {...getTagProps({ index })}
                      disabled={option?.fixed || false}
                    />
                  ));
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.value + Math.random()}>
                      {option.label}
                    </li>
                  );
                }}
                onChange={(_, data) =>
                  onChange({ name: configItem.name }, () => {
                    if (isMultiple && Array.isArray(data) && data.length === 0 && fixedOptions.length) {
                      return field.onChange(fixedOptions);
                    }
                    return field.onChange(data);
                  })
                }
                value={_value}
              />
            );
          }}
        />
      );
    }
    case 'file': {
      return (
        <Controller
          control={control}
          name={configItem.name}
          rules={_configItem.options as unknown as RegisterOptions}
          render={({ field }) => (
            <FileInput
              placeholder={placeholder || t('chooseFile') + (configItem.options?.required ? ' *' : '')}
              configItem={configItem as AddEditDialogConfigBase & AddEditDialogConfigFile}
              onChange={(file) => field.onChange(file)}
              value={field.value || configItem.initialValue || null}
              getValues={getValues}
            />
          )}
        />
      );
    }
    case 'date': {
      return (
        <Controller
          control={control}
          name={configItem.name}
          rules={_configItem.options as unknown as RegisterOptions}
          render={({ field: { onChange, value } }) => (
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={value ? dayjs(value) : null}
              sx={{
                width: '100%',
                p: 1.6,
                border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-between',
                '& .MuiDateCalendar-root': {
                  margin: 'initial',
                  width: '100%',
                },
                '& .MuiPickersCalendarHeader-root': {
                  pl: 0,
                },
              }}
              orientation="portrait"
              disablePast={true}
              onChange={(val: dayjs.Dayjs | null) => onChange(val && val.isValid() ? val.toISOString() : '_invalid_')}
              slotProps={{
                actionBar: {
                  actions: [],
                },
                toolbar: {
                  toolbarFormat: 'L',
                  toolbarPlaceholder: placeholder || t('selectDate') + (configItem.options?.required ? ' *' : ''),
                },
              }}
              slots={{ toolbar: (props) => <DatePickerToolbar {...props} /> }}
            />
          )}
        />
      );
    }
    case 'number': {
      return (
        <Controller
          control={control}
          name={configItem.name}
          rules={{
            ..._configItem.options,
            validate: {
              ...(typeof _configItem.options?.validate === 'function'
                ? { enhanced: _configItem.options?.validate }
                : _configItem.options?.validate),
              isValidNumber: (value) => {
                return !isNullOrEmptyString(value)
                  ? isValidNumber(Number(value))
                    ? true
                    : (t('fieldNumberError') as string)
                  : true;
              },
            },
          }}
          render={({ field }) => {
            return (
              <NumberInput
                configItem={configItem as AddEditDialogConfigBase & AddEditDialogConfigNumber}
                getValues={getValues}
                onBlur={field.onBlur}
                onChange={(event) => {
                  let _event = event as any;
                  _event = {
                    ..._event,
                    target: {
                      ..._event.target,
                      value: isNullOrEmptyString(_event.target.value)
                        ? ''
                        : isValidNumber(Number(_event.target.value))
                        ? Number(_event.target.value)
                        : _event.target.value,
                    },
                  };
                  field.onChange(_event);
                }}
                placeholder={configItem.adornmentStartValue ? '0' : placeholder || (t('chooseNumber') as string)}
                label={placeholder}
                helperText={!hasErrors && configItem.helperText ? configItem.helperText : null}
                value={field.value}
                fullWidth
                error={hasErrors}
                margin="dense"
                {...((disabled && { disabled: true }) || {})}
              />
            );
          }}
        />
      );
    }
    case 'selectCard': {
      return (
        <Controller
          control={control}
          name={configItem.name}
          rules={_configItem.options as unknown as RegisterOptions}
          render={({ field }) => {
            const _configItem = configItem as AddEditDialogConfigBase & AddEditDialogConfigSelectCard;
            const registeredValue = field.value;
            return <SelectCardInput onChange={field.onChange} configItem={_configItem} value={registeredValue} />;
          }}
        />
      );
    }
    case 'selectBox': {
      return (
        <Controller
          control={control}
          name={configItem.name}
          rules={configItem.options}
          render={({ field }) => {
            const _configItem = configItem as AddEditDialogConfigBase & AddEditDialogConfigSelectBox;
            const registeredValue = field.value;
            return <SelectBoxInput onChange={field.onChange} configItem={_configItem} value={registeredValue} />;
          }}
        />
      );
    }

    default:
      throw new Error(`Unknown type: ${type}`);
  }
}

export default memo(DialogInput);

export function DatePickerToolbar(props: DatePickerToolbarProps<dayjs.Dayjs>) {
  const { t } = useTranslation();
  return (
    <Box className={props.className}>
      <InputLabel sx={{ textTransform: 'capitalize' }}>{props.toolbarPlaceholder}</InputLabel>
      <Typography
        variant="body1"
        fontWeight={props.value ? 'bold' : 'normal'}
        fontStyle={props.value ? 'normal' : 'italic'}
      >
        {props.value?.format(props.toolbarFormat || 'L') || t('noDateSelected')}
      </Typography>
    </Box>
  );
}
