import { EventsTable } from '@/components/OneTools/Channels/Events';
import { ROUTES } from '@/shared/constants';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceIdentityContext } from '../DeviceIdentityContext';
import useEventsColumns from '@/components/OneTools/Channels/Events/useEventsColumns';

export default function Events() {
  const { t } = useTranslation();
  const deviceIdentity = useContext(DeviceIdentityContext);
  const eventsColumns = useEventsColumns(deviceIdentity?.events);

  return (
    <EventsTable
      title={t(ROUTES.ONETOOL_CHANNELS_EVENTS.fragment)}
      data={deviceIdentity?.events ?? []}
      columns={eventsColumns}
      isLoading={false}
      isError={false}
      resetStateButtonVisible={false}
      exportData={false}
      selection={false}
    />
  );
}
