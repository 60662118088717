import { Button, Card, CardActions, CardContent, CircularProgress, Link, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLoginMutation } from '../../../redux/api/authApiSlice';
import { login as doLogin, selectAccessToken } from '../../../redux/slices/authSlice';
import useInputValidations from '@/hooks/useFormValidations';
import authErrorHandler from './authErrorHandler';
import PasswordInput from '@/components/Shared/PasswordInput';
import APP_CONFIG from '@/appConfig';
import { Role } from '@typings';
import { AppState } from '@/redux/store';
import { clearOperations } from '@/redux/slices/operationSlice';
import { getRequestedLocation, removeRequestLocation } from '@/redux/utils';
import { getPath } from '@/shared/utils';

const REQUESTED_LOCATION_BLACKLIST = [getPath('FLEET_OVERVIEW'), '/login'];

const handleLoginRedirect = (accessToken: string | null) => {
  const requestedLocation = getRequestedLocation();
  if (
    accessToken &&
    requestedLocation &&
    !REQUESTED_LOCATION_BLACKLIST.some((blacklisted) => requestedLocation.includes(blacklisted))
  ) {
    removeRequestLocation();

    return `/${requestedLocation.split('/').slice(3).join('/')}`;
  }

  return '/';
};

const Login = () => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = methods;

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { email: emailValidations } = useInputValidations();
  const previousUser = useSelector((state: AppState) => state.auth.user);

  useEffect(() => watch((v) => clearErrors()).unsubscribe, [watch, clearErrors]);

  // Preventing logged in users from accessing the login page
  const accessToken = useSelector(selectAccessToken);

  useEffect(() => {
    if (accessToken) {
      const path = handleLoginRedirect(accessToken);
      navigate(path);
    }
  }, [accessToken, navigate]);

  const onSubmit = (formData: { email: string; password: string }) => {
    login(formData)
      .unwrap()
      .then((loginData) => {
        const { data } = loginData;
        if (
          !data.roles.some((r: string) => APP_CONFIG.dashboardAllowedAccessRoles.includes(r as Exclude<Role, 'user'>))
        ) {
          setError('root', { message: t('forbiddenError') as string });
          return;
        }
        if (previousUser !== data.userId && previousUser) {
          dispatch(clearOperations());
        }
        dispatch(
          doLogin({
            user: data.userId,
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            roles: data.roles,
          })
        );
      })
      .catch((err) => authErrorHandler(err.status, setError, t));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Card sx={{ minWidth: 400, py: 2 }}>
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">Culligan One</Typography>
            <FormProvider {...methods}>
              <TextField
                variant="outlined"
                label="Email"
                sx={{ mt: 2 }}
                {...register('email', emailValidations)}
                {...(!!errors.email && { error: true, helperText: errors.email.message })}
              />
              <PasswordInput sx={{ mt: 2 }} fullValidation={false} />
            </FormProvider>
          </Box>
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: 'center' }}>
          <Box display="flex" flexDirection="column">
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              startIcon={isLoading ? <CircularProgress size={20} sx={{ color: 'gray' }} /> : null}
              disabled={isLoading || !!errors.root}
            >
              {t('login')}
            </Button>
            {!!errors.root && (
              <Typography variant="caption" color="red" whiteSpace="pre" textAlign="center" sx={{ my: 1 }}>
                {errors.root.message}
              </Typography>
            )}
          </Box>
        </CardActions>
        <Link href="/reset-password" variant="body2" style={{ marginLeft: '1rem' }}>
          {t('resetPassword')}
        </Link>
      </Card>
    </form>
  );
};

export default Login;
