import { useGetLoyaltyProgramsQuery } from '@/redux/api/system/loyaltyProgramsApiSlice';
import { useTranslation } from 'react-i18next';
import { LoyaltyProgramRes } from './typings';
import useLoyaltyProgramsColumns from './useLoyaltyProgramsColumns';
import withErrorLoadingManagement from '@/components/Shared/withErrorLoadingManagement';
import GenericExportTable from '@/components/Shared/Tables/GenericExportTable';
import withResetNavigationState from '@/components/Shared/withResetNavigationState';
import { ROUTES } from '@/shared/constants';

export const LoyaltyProgramsTable = withErrorLoadingManagement(
  withResetNavigationState(GenericExportTable<LoyaltyProgramRes>)
);

export default function LoyaltyProgramsPanel() {
  const { t } = useTranslation();

  // Queries
  const { data, isLoading, isError } = useGetLoyaltyProgramsQuery();
  const columns = useLoyaltyProgramsColumns(/*openDialog, confirm*/);

  if (data?._tag === 'Left' || !data || data.right.success === false) {
    return <></>;
  }

  return (
    <LoyaltyProgramsTable
      title={t(ROUTES.ONETOOL_CHANNELS_LOYALTY_PROGRAMS.fragment)}
      data={data?.right.data.items}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      resetStateButtonVisible={false}
      exportData={true}
      selection={true}
    />
  );
}
