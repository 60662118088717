import React from 'react';
import { Decoded as BusinessAnalysisDecodedFilters } from './useBusinessAnalysisFilters';

export const FiltersContext = React.createContext<BusinessAnalysisDecodedFilters>({
  model: undefined,
  brand: undefined,
  customer: undefined,
  start: undefined,
  end: undefined,
} as unknown as BusinessAnalysisDecodedFilters);
