export const COMMAND_TYPES = {
  NO_PARAMS: 'noParams',
  PARAMS_WITHOUT_CONFIG: 'paramsWithoutConfig',
  PARAMS_WITH_CONFIG: 'paramsWithConfig',
} as const;

export const CONNECTIVITIES = {
  '2G': '2G',
  '4G': '4G',
  '5G': '5G',
  BLE: 'BLE',
  LTECAT14: 'LTE cat 1-4',
  LTECATM1: 'LTE cat M1',
  NBIOT2: 'Nb-IoT 2',
  NBIOT: 'NB-IoT',
  SIDEWALK: 'Sidewalk',
  WIFI: 'Wi-Fi',
} as const;

export const CONSUMABLE_KIND = {
  ENHANCEMENTS: 'enhancements',
  CO2: 'CO2',
  CHEMICALS: 'chemicals',
  FILTER: 'filter',
  MEMBRANES: 'membranes',
  OZONE: 'ozone',
  SANITIZATIONKIT: 'sanitizationKit',
  UVCLIGHT: 'uvcLight',
  WATERBOTTLES: 'waterBottles',
} as const;

export const DIRECTIONS = {
  C2D: 'C2D',
  D2C: 'D2C',
} as const;

export const ERROR_CAUSES = {
  UNAUTHORIZED_ROLE: 'UNAUTHORIZED_ROLE',
} as const;

export const EXPIRATION_METHOD = {
  EXPIRATION_DATE: 'expirationDate',
  REPLACE_AFTER: 'replaceAfter',
} as const;

export const ISSUE_STATUS = {
  ACTIVE: 'active',
  DISMISSED: 'dismissed',
} as const;

export const LOYALTY_PROGRAMS_CONFIGURATION_TYPE = {
  POINTS_PER_DRINK: 'pointsPerDrink',
  POINTS_PER_VOLUME: 'pointsPerVolume',
} as const;

export const MARKER_TYPE = {
  DEVICE: 'device',
} as const;

export const PRIMITIVES = {
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  STRING: 'string',
} as const;

export const REACTION_KIND = {
  EMAIL: 'email',
  PUSHNOTIFICATION: 'pushNotification',
  SMS: 'sms',
  WEBHOOK: 'webhook',
} as const;

export const ROLES = {
  ADMIN: 'admin',
  BRAND_MANAGER: 'brandManager',
  BRAND_SUPPORT_OPERATOR: 'brandSupportOperator',
  BRAND_TECH_OPERATOR: 'brandTechOperator',
  SYS_ADMIN: 'sysAdmin',
  USER: 'user',
} as const;

export const ROLES_PRIORITIES = [
  ROLES.SYS_ADMIN,
  ROLES.ADMIN,
  ROLES.BRAND_MANAGER,
  ROLES.BRAND_TECH_OPERATOR,
  ROLES.BRAND_SUPPORT_OPERATOR,
  ROLES.USER,
];

export const SENDING_RULE = {
  LESS: 'less',
  GREATER: 'greater',
} as const;

export const SENDING_STYLE = {
  IMMEDIATE: 'immediate',
  STORED: 'stored',
} as const;

export const SEVERITY = {
  CRITICAL: 'critical',
  NON_CRITICAL: 'non-critical',
} as const;

export const TELEMETRY_SENDING_STYLE = {
  ALWAYS: 'always',
  ON_CHANGE: 'onchange',
  ON_DELTA: 'ondelta',
  ON_THRESHOLD: 'onthreshold',
} as const;

export const TELEMETRY_TYPE = {
  INFERRED: 'inferred',
  NATIVE: 'native',
} as const;

export const TIERS = {
  '1': 1,
  '2': 2,
  '3': 3,
} as const;

const FLEET_ID = 'FLEET';
const FLEET_ALARMS_ERRORS_ID = 'FLEET_ALARMS_ERRORS';
const FLEET_ALARMS_ID = 'FLEET_ALARMS';
const FLEET_ERRORS_ID = 'FLEET_ERRORS';
const FLEET_OVERVIEW_ID = 'FLEET_OVERVIEW';
const FLEET_CONSUMABLES_ID = 'FLEET_CONSUMABLES';
const FLEET_CONSUMABLES_ENHANCEMENTS_ID = 'FLEET_CONSUMABLES_ENHANCEMENTS';
const FLEET_CONSUMABLES_CO2_ID = 'FLEET_CONSUMABLES_CO2';
const FLEET_CONSUMABLES_CHEMICALS_ID = 'FLEET_CONSUMABLES_CHEMICALS';
const FLEET_CONSUMABLES_FILTER_ID = 'FLEET_CONSUMABLES_FILTER';
const FLEET_CONSUMABLES_MEMBRANES_ID = 'FLEET_CONSUMABLES_MEMBRANES';
const FLEET_CONSUMABLES_OZONE_ID = 'FLEET_CONSUMABLES_OZONE';
const FLEET_CONSUMABLES_SANITIZATIONKIT_ID = 'FLEET_CONSUMABLES_SANITIZATIONKIT';
const FLEET_CONSUMABLES_UVCLIGHT_ID = 'FLEET_CONSUMABLES_UVCLIGHT';
const FLEET_CONSUMABLES_WATERBOTTLES_ID = 'FLEET_CONSUMABLES_WATERBOTTLES';
const FLEET_DEVICES_ID = 'FLEET_DEVICES';
const FLEET_DEVICES_LIST_ID = 'FLEET_DEVICES_LIST';
const FLEET_DEVICES_MAP_ID = 'FLEET_DEVICES_MAP';
const FLEET_DEVICE_ID = 'FLEET_DEVICE';
const FLEET_DEVICE_OVERVIEW_ID = 'FLEET_DEVICE_OVERVIEW';
const FLEET_DEVICE_ALARMS_ID = 'FLEET_DEVICE_ALARMS';
const FLEET_DEVICE_ERRORS_ID = 'FLEET_DEVICE_ERRORS';
const FLEET_DEVICE_EVENTS_ID = 'FLEET_DEVICE_EVENTS';
const FLEET_DEVICE_CONSUMABLES_ID = 'FLEET_DEVICE_CONSUMABLES';
const FLEET_DEVICE_TELEMETRY_ID = 'FLEET_DEVICE_TELEMETRY';
const FLEET_DEVICE_OTA_JOBS_ID = 'FLEET_DEVICE_OTA_JOBS';
const FLEET_DEVICE_LOYALTY_PROGRAMS_ID = 'FLEET_DEVICE_LOYALTY_PROGRAMS';
const ONETOOL_ID = 'ONETOOL';
const ONETOOL_ECOSYSTEM_ID = 'ONETOOL_ECOSYSTEM';
const ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ID = 'ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES';
const ONETOOL_ECOSYSTEM_BRANDS_ID = 'ONETOOL_ECOSYSTEM_BRANDS';
const ONETOOL_ECOSYSTEM_OTA_ID = 'ONETOOL_ECOSYSTEM_OTA';
const ONETOOL_CHANNELS_ID = 'ONETOOL_CHANNELS';
const ONETOOL_CHANNELS_TELEMETRY_ID = 'ONETOOL_CHANNELS_TELEMETRY';
const ONETOOL_CHANNELS_EVENTS_ID = 'ONETOOL_CHANNELS_EVENTS';
const ONETOOL_CHANNELS_ALARMS_ID = 'ONETOOL_CHANNELS_ALARMS';
const ONETOOL_CHANNELS_COMMANDS_ID = 'ONETOOL_CHANNELS_COMMANDS';
const ONETOOL_CHANNELS_CONSUMABLES_ID = 'ONETOOL_CHANNELS_CONSUMABLES';
const ONETOOL_CHANNELS_CONSUMABLE_SUBSETS_ID = 'ONETOOL_CHANNELS_CONSUMABLE_SUBSETS';
const ONETOOL_CHANNELS_REACTIONS_ID = 'ONETOOL_CHANNELS_REACTIONS';
const ONETOOL_CHANNELS_CONFIGS_ID = 'ONETOOL_CHANNELS_CONFIGS';
const ONETOOL_CHANNELS_LOYALTY_PROGRAMS_ID = 'ONETOOL_CHANNELS_LOYALTY_PROGRAMS';
const ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID = 'ONETOOL_ECOSYSTEM_DEVICE_IDENTITY';
const ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_TELEMETRY_ID = 'ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_TELEMETRY';
const ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_EVENTS_ID = 'ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_EVENTS';
const ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ALARMS_ID = 'ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ALARMS';
const ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_COMMANDS_ID = 'ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_COMMANDS';
const ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONFIGS_ID = 'ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONFIGS';
const ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONSUMABLES_ID = 'ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONSUMABLES';
const BUSINESSANALYSIS_ID = 'BUSINESSANALYSIS';
const BUSINESSANALYSIS_OVERVIEW_ID = 'BUSINESSANALYSIS_OVERVIEW';

export type APP_ROUTE_ID =
  | typeof FLEET_ID
  | typeof FLEET_OVERVIEW_ID
  | typeof FLEET_ALARMS_ERRORS_ID
  | typeof FLEET_ALARMS_ID
  | typeof FLEET_ERRORS_ID
  | typeof FLEET_CONSUMABLES_ID
  | typeof FLEET_CONSUMABLES_ENHANCEMENTS_ID
  | typeof FLEET_CONSUMABLES_CO2_ID
  | typeof FLEET_CONSUMABLES_CHEMICALS_ID
  | typeof FLEET_CONSUMABLES_FILTER_ID
  | typeof FLEET_CONSUMABLES_MEMBRANES_ID
  | typeof FLEET_CONSUMABLES_OZONE_ID
  | typeof FLEET_CONSUMABLES_SANITIZATIONKIT_ID
  | typeof FLEET_CONSUMABLES_UVCLIGHT_ID
  | typeof FLEET_CONSUMABLES_WATERBOTTLES_ID
  | typeof FLEET_DEVICES_ID
  | typeof FLEET_DEVICES_LIST_ID
  | typeof FLEET_DEVICES_MAP_ID
  | typeof FLEET_DEVICE_ID
  | typeof FLEET_DEVICE_OVERVIEW_ID
  | typeof FLEET_DEVICE_ALARMS_ID
  | typeof FLEET_DEVICE_ERRORS_ID
  | typeof FLEET_DEVICE_EVENTS_ID
  | typeof FLEET_DEVICE_CONSUMABLES_ID
  | typeof FLEET_DEVICE_TELEMETRY_ID
  | typeof FLEET_DEVICE_OTA_JOBS_ID
  | typeof FLEET_DEVICE_LOYALTY_PROGRAMS_ID
  | typeof ONETOOL_ID
  | typeof ONETOOL_ECOSYSTEM_ID
  | typeof ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ID
  | typeof ONETOOL_ECOSYSTEM_BRANDS_ID
  | typeof ONETOOL_ECOSYSTEM_OTA_ID
  | typeof ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID
  | typeof ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_TELEMETRY_ID
  | typeof ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_EVENTS_ID
  | typeof ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ALARMS_ID
  | typeof ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_COMMANDS_ID
  | typeof ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONFIGS_ID
  | typeof ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONSUMABLES_ID
  | typeof ONETOOL_CHANNELS_ID
  | typeof ONETOOL_CHANNELS_TELEMETRY_ID
  | typeof ONETOOL_CHANNELS_EVENTS_ID
  | typeof ONETOOL_CHANNELS_ALARMS_ID
  | typeof ONETOOL_CHANNELS_COMMANDS_ID
  | typeof ONETOOL_CHANNELS_CONSUMABLES_ID
  | typeof ONETOOL_CHANNELS_CONSUMABLE_SUBSETS_ID
  | typeof ONETOOL_CHANNELS_REACTIONS_ID
  | typeof ONETOOL_CHANNELS_CONFIGS_ID
  | typeof ONETOOL_CHANNELS_LOYALTY_PROGRAMS_ID
  | typeof BUSINESSANALYSIS_ID
  | typeof BUSINESSANALYSIS_OVERVIEW_ID;

export const FRAGMENTS = {
  FLEET: 'fleet',
  ALARMS_ERRORS: 'alarms-errors',
  OVERVIEW: 'overview',
  CONSUMABLES: 'consumables',
  ENHANCEMENTS: 'enhancements',
  CO2: 'CO2',
  CHEMICALS: 'chemicals',
  FILTER: 'filter',
  MEMBRANES: 'membranes',
  OZONE: 'ozone',
  SANITIZATIONKIT: 'sanitizationKit',
  UVCLIGHT: 'uvcLight',
  WATERBOTTLES: 'waterBottles',

  DEVICES: 'devices',
  DEVICE: ':deviceId',
  LIST: 'list',
  MAP: 'map',
  ERRORS: 'errors',
  OTA_JOBS: 'ota_jobs',

  ONETOOL: 'one-tool',
  ECOSYSTEM: 'ecosystem',
  DEVICE_IDENTITIES: 'device_identities',
  DEVICE_IDENTITY: ':deviceIdentityId',
  BRANDS: 'brands',
  BUSINESSANALYSIS: 'business-analysis',
  OTA: 'ota',
  CHANNELS: 'channels',
  TELEMETRY: 'telemetry',
  EVENTS: 'events',
  ALARMS: 'alarms',
  COMMANDS: 'commands',
  CONSUMABLE_SUBSETS: 'consumable_subsets',
  REACTIONS: 'reactions',
  CONFIGS: 'configs',
  LOYALTY_PROGRAMS: 'loyalty_programs',
} as const;

export type Route = {
  fragment: (typeof FRAGMENTS)[keyof typeof FRAGMENTS];
  parent?: APP_ROUTE_ID;
};

export const ROUTES = {
  [FLEET_ID]: {
    fragment: FRAGMENTS.FLEET,
  },
  [FLEET_OVERVIEW_ID]: {
    fragment: FRAGMENTS.OVERVIEW,
    parent: FLEET_ID,
  },
  [FLEET_ALARMS_ERRORS_ID]: {
    fragment: FRAGMENTS.ALARMS_ERRORS,
    parent: FLEET_ID,
  },
  [FLEET_ALARMS_ID]: {
    fragment: FRAGMENTS.ALARMS,
    parent: FLEET_ALARMS_ERRORS_ID,
  },
  [FLEET_ERRORS_ID]: {
    fragment: FRAGMENTS.ERRORS,
    parent: FLEET_ALARMS_ERRORS_ID,
  },
  [FLEET_CONSUMABLES_ID]: {
    fragment: FRAGMENTS.CONSUMABLES,
    parent: FLEET_ID,
  },
  [FLEET_CONSUMABLES_ENHANCEMENTS_ID]: {
    fragment: FRAGMENTS.ENHANCEMENTS,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_CONSUMABLES_CO2_ID]: {
    fragment: FRAGMENTS.CO2,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_CONSUMABLES_CHEMICALS_ID]: {
    fragment: FRAGMENTS.CHEMICALS,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_CONSUMABLES_FILTER_ID]: {
    fragment: FRAGMENTS.FILTER,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_CONSUMABLES_MEMBRANES_ID]: {
    fragment: FRAGMENTS.MEMBRANES,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_CONSUMABLES_OZONE_ID]: {
    fragment: FRAGMENTS.OZONE,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_CONSUMABLES_SANITIZATIONKIT_ID]: {
    fragment: FRAGMENTS.SANITIZATIONKIT,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_CONSUMABLES_UVCLIGHT_ID]: {
    fragment: FRAGMENTS.UVCLIGHT,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_CONSUMABLES_WATERBOTTLES_ID]: {
    fragment: FRAGMENTS.WATERBOTTLES,
    parent: FLEET_CONSUMABLES_ID,
  },
  [FLEET_DEVICES_ID]: {
    fragment: FRAGMENTS.DEVICES,
    parent: FLEET_ID,
  },
  [FLEET_DEVICES_LIST_ID]: {
    fragment: FRAGMENTS.LIST,
    parent: FLEET_DEVICES_ID,
  },
  [FLEET_DEVICES_MAP_ID]: {
    fragment: FRAGMENTS.MAP,
    parent: FLEET_DEVICES_ID,
  },
  [FLEET_DEVICE_ID]: {
    fragment: FRAGMENTS.DEVICE,
    parent: FLEET_DEVICES_ID,
  },
  [FLEET_DEVICE_OVERVIEW_ID]: {
    fragment: FRAGMENTS.OVERVIEW,
    parent: FLEET_DEVICE_ID,
  },
  [FLEET_DEVICE_ALARMS_ID]: {
    fragment: FRAGMENTS.ALARMS,
    parent: FLEET_DEVICE_ID,
  },
  [FLEET_DEVICE_ERRORS_ID]: {
    fragment: FRAGMENTS.ERRORS,
    parent: FLEET_DEVICE_ID,
  },
  [FLEET_DEVICE_EVENTS_ID]: {
    fragment: FRAGMENTS.EVENTS,
    parent: FLEET_DEVICE_ID,
  },
  [FLEET_DEVICE_CONSUMABLES_ID]: {
    fragment: FRAGMENTS.CONSUMABLES,
    parent: FLEET_DEVICE_ID,
  },
  [FLEET_DEVICE_TELEMETRY_ID]: {
    fragment: FRAGMENTS.TELEMETRY,
    parent: FLEET_DEVICE_ID,
  },
  [FLEET_DEVICE_OTA_JOBS_ID]: {
    fragment: FRAGMENTS.OTA_JOBS,
    parent: FLEET_DEVICE_ID,
  },
  [FLEET_DEVICE_LOYALTY_PROGRAMS_ID]: {
    fragment: FRAGMENTS.LOYALTY_PROGRAMS,
    parent: FLEET_DEVICE_ID,
  },
  [ONETOOL_ID]: {
    fragment: FRAGMENTS.ONETOOL,
  },
  [ONETOOL_ECOSYSTEM_ID]: {
    fragment: FRAGMENTS.ECOSYSTEM,
    parent: ONETOOL_ID,
  },
  [ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ID]: {
    fragment: FRAGMENTS.DEVICE_IDENTITIES,
    parent: ONETOOL_ECOSYSTEM_ID,
  },
  [ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID]: {
    fragment: FRAGMENTS.DEVICE_IDENTITY,
    parent: ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ID,
  },
  [ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_TELEMETRY_ID]: {
    fragment: FRAGMENTS.TELEMETRY,
    parent: ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID,
  },
  [ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_EVENTS_ID]: {
    fragment: FRAGMENTS.EVENTS,
    parent: ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID,
  },
  [ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_ALARMS_ID]: {
    fragment: FRAGMENTS.ALARMS,
    parent: ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID,
  },
  [ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_COMMANDS_ID]: {
    fragment: FRAGMENTS.COMMANDS,
    parent: ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID,
  },
  [ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONFIGS_ID]: {
    fragment: FRAGMENTS.CONFIGS,
    parent: ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID,
  },
  [ONETOOL_ECOSYSTEM_DEVICE_IDENTITIES_CONSUMABLES_ID]: {
    fragment: FRAGMENTS.CONSUMABLES,
    parent: ONETOOL_ECOSYSTEM_DEVICE_IDENTITY_ID,
  },
  [ONETOOL_ECOSYSTEM_BRANDS_ID]: {
    fragment: FRAGMENTS.BRANDS,
    parent: ONETOOL_ECOSYSTEM_ID,
  },
  [ONETOOL_ECOSYSTEM_OTA_ID]: {
    fragment: FRAGMENTS.OTA,
    parent: ONETOOL_ECOSYSTEM_ID,
  },
  [ONETOOL_CHANNELS_ID]: {
    fragment: FRAGMENTS.CHANNELS,
    parent: ONETOOL_ID,
  },
  [ONETOOL_CHANNELS_TELEMETRY_ID]: {
    fragment: FRAGMENTS.TELEMETRY,
    parent: ONETOOL_CHANNELS_ID,
  },
  [ONETOOL_CHANNELS_EVENTS_ID]: {
    fragment: FRAGMENTS.EVENTS,
    parent: ONETOOL_CHANNELS_ID,
  },
  [ONETOOL_CHANNELS_ALARMS_ID]: {
    fragment: FRAGMENTS.ALARMS,
    parent: ONETOOL_CHANNELS_ID,
  },
  [ONETOOL_CHANNELS_COMMANDS_ID]: {
    fragment: FRAGMENTS.COMMANDS,
    parent: ONETOOL_CHANNELS_ID,
  },
  [ONETOOL_CHANNELS_CONSUMABLES_ID]: {
    fragment: FRAGMENTS.CONSUMABLES,
    parent: ONETOOL_CHANNELS_ID,
  },
  [ONETOOL_CHANNELS_CONSUMABLE_SUBSETS_ID]: {
    fragment: FRAGMENTS.CONSUMABLE_SUBSETS,
    parent: ONETOOL_CHANNELS_ID,
  },
  [ONETOOL_CHANNELS_REACTIONS_ID]: {
    fragment: FRAGMENTS.REACTIONS,
    parent: ONETOOL_CHANNELS_ID,
  },
  [ONETOOL_CHANNELS_CONFIGS_ID]: {
    fragment: FRAGMENTS.CONFIGS,
    parent: ONETOOL_CHANNELS_ID,
  },
  [ONETOOL_CHANNELS_LOYALTY_PROGRAMS_ID]: {
    fragment: FRAGMENTS.LOYALTY_PROGRAMS,
    parent: ONETOOL_CHANNELS_ID,
  },
  [BUSINESSANALYSIS_ID]: {
    fragment: FRAGMENTS.BUSINESSANALYSIS,
  },
  [BUSINESSANALYSIS_OVERVIEW_ID]: {
    fragment: FRAGMENTS.OVERVIEW,
    parent: BUSINESSANALYSIS_ID,
  },
} satisfies Record<APP_ROUTE_ID, Route>;

export const OPERATION_STATUS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  FULFILLED: 'fulfilled',
} as const;

export const WATER_TYPES = {
  0: 'Fresh Water',
  1: 'Sparkling Water',
  2: 'Flat Water',
  3: 'Hot Water',
  4: 'Tasty Water',
} as const;

export const JOB_STATUS = {
  LOADING: 'loading',
  QUEUED: 'queued',
  RUNNING: 'running',
  COOL_DOWN: 'coolDown',
  WAITING: 'waiting',
  PAUSED: 'paused',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELED: 'canceled',
} as const;

export const COMMANDS = {
  DISABLE: 'disable',
  ENABLE: 'enable',
  REBOOT: 'reboot',
  SLEEP: 'power.profile.sleep',
  STANDARD: 'power.profile.standard',
  LOG_SET: 'log.set',
  OTA_UPDATE: 'ota.update',
  TELEMETRY_GET: 'telemetry.get',
} as const;

export const REQUESTED_LOCATION = 'requestedLocation';
