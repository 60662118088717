import { GroupTelemetryState, TelemetryFilters } from '../../../../typings';
import { HistoryTelemetry } from '../../../typings';
import { getFiltersArray } from '../../helpers/getFiltersArray';
import { GroupDatum, Multiline, Datum } from '../typings';
import { isValidNumber } from '@/shared/utils';

const processDatum = (data: HistoryTelemetry[], key: string): Datum[] =>
  data
    .map((telemetry) => ({ x: telemetry.lastUpdate, y: telemetry[key] as number }))
    .filter((point) => isValidNumber(point.y));

const processSingleTelemetryWithData = (data: HistoryTelemetry[], key: string): Datum[] | null => {
  const plotData = processDatum(data, key);
  if (plotData.length > 0) {
    return plotData;
  }
  return null;
};

const processGroupDatum = (data: HistoryTelemetry[], key: GroupTelemetryState['key']): GroupDatum =>
  key.reduce((acc, telemetryId) => {
    const groupData = processDatum(data, telemetryId);
    if (groupData.length > 0) {
      acc[telemetryId] = groupData;
    }
    return acc;
  }, {} as GroupDatum);

const processGroupTelemetryWithData = (
  data: HistoryTelemetry[],
  groupTelemetryFilters: GroupTelemetryState
): GroupDatum => {
  if (!groupTelemetryFilters?.key?.length) {
    return [] as unknown as GroupDatum;
  }
  const plotGroupData = processGroupDatum(data, groupTelemetryFilters.key!);
  if (Object.keys(plotGroupData).length > 0) {
    return plotGroupData;
  }
  return [] as unknown as GroupDatum;
};

export const getPlottableData = (
  telemetryFilters: TelemetryFilters | undefined,
  data: HistoryTelemetry[]
): Multiline => {
  if (!telemetryFilters) {
    return {};
  }

  const keys = getFiltersArray(telemetryFilters);

  return keys.reduce((acc, telemetryFilter) => {
    if (telemetryFilter && typeof telemetryFilter === 'string') {
      const processedSingleTelemetry = processSingleTelemetryWithData(data, telemetryFilter);
      if (processedSingleTelemetry) {
        acc[telemetryFilter] = processedSingleTelemetry;
      }
    }

    if (typeof telemetryFilter !== 'string' && telemetryFilter?.tagName && telemetryFilter?.key?.length) {
      const processedGroupTelemetry = processGroupTelemetryWithData(data, telemetryFilter);
      if (!Object.entries(processedGroupTelemetry).length) {
        return acc;
      }
      acc[telemetryFilter.tagName] = processedGroupTelemetry;
    }

    return acc;
  }, {} as Multiline);
};
