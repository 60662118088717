import { Fragment, useContext, useMemo, useState } from 'react';
import StatisticsCard from '../Shared/StatisticsCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import LineChartCard from './LineChartCard';
import withMissingDataManagement from './withMissingDataManagement';
import dayjs from 'dayjs';
import WaterDispensedPrintResults from './WaterDispensedPrintResults';
import { FiltersContext } from './FiltersContext';
import { useGetOverviewQuery } from '@/redux/api/businessAnalysis/dispensingStatisticsApiSlice';

const SafeLineChart = withMissingDataManagement(LineChartCard);
const GALLONS_PER_LITER = 0.264172;

export default function WaterDispensedCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const filters = useContext(FiltersContext);
  const { data } = useGetOverviewQuery(filters);
  const [isGallons, setIsGallons] = useState<boolean>(false);

  const filteredData = data?.data.items;
  const totalLiters = filteredData?.waterDispensed?.value.reduce((acc, data) => acc + data.totalVolume, 0) || 0;
  const displayTotal = isGallons ? totalLiters * GALLONS_PER_LITER : totalLiters;
  const bigNumberLabel = ['Liters', 'Gallons'];
  const hasFilteredData = filteredData && filteredData.waterDispensed && filteredData.waterDispensed.value.length > 1;
  const datum = useMemo(
    () =>
      filteredData?.waterDispensed?.value?.map((pouring) => ({
        x: dayjs(pouring.day),
        y: pouring.totalVolume,
      })) || [],
    [filteredData]
  );
  const handleChangeSwitchToggle = () => {
    setIsGallons((prev) => !prev);
  };

  return (
    <Fragment>
      <StatisticsCard
        variant="boxed"
        title={t('waterDispensed')}
        action={{
          label: t('details'),
          fn: () => navigate('/'),
        }}
        tooltipText={t('waterDispensedTooltip') || ''}
      >
        <SafeLineChart
          hasData={!!(filteredData && filteredData.waterDispensed && filteredData.waterDispensed.value.length > 1)}
          data={datum}
          id={'pourings'}
          isPercentage={false}
          isValueTogglable={true}
          smallLabel={'Liters / Gallons'}
          bigNumberLabel={bigNumberLabel}
          handleToggleClick={handleChangeSwitchToggle}
          bigNumber={displayTotal}
        />
      </StatisticsCard>
      {hasFilteredData ? (
        <WaterDispensedPrintResults waterDispensed={filteredData?.waterDispensed} isGallons={isGallons} />
      ) : null}
    </Fragment>
  );
}
