import { Box, Button, Card, CardActions, CardContent, Tooltip, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIsTablet from '@/hooks/useIsTablet';
import { Trans } from 'react-i18next';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import CONFIG from '@/appConfig';
import useCulliganDialog from '@/hooks/useCulliganDialog';
import { FleetConsumables } from './typings';
import { wrapInRange } from '@/shared/utils';

const ConsumablesShoppingDialog = ({ devices, onClose, consumableType }: FleetConsumables.ShoppingDialogProps) => {
  const { t } = useTranslation();
  const isTablet = useIsTablet();
  const theme = useTheme();

  const body = useMemo(() => {
    return devices.map((device: FleetConsumables.Device) => {
      const deviceInfo = device.serialNumber;
      const consumableUnder20percent = device.consumables.filter(
        (consumable: any) =>
          wrapInRange(consumable.current, consumable.subset?.rangeExhaust || 0, consumable.subset?.rangeFullCapacity) <=
          20
      );

      return {
        deviceInfo,
        consumableUnder20percent,
      };
    });
  }, [devices]);

  const csvBody = useMemo(() => {
    const _buildCsvBodyItem = (item: any) => {
      return item.consumableUnder20percent.map((consumable: any) => [
        item.deviceInfo,
        consumable.consumable.kind,
        consumable.consumable.id,
      ]);
    };

    const rows = body.flatMap((item) => _buildCsvBodyItem(item));
    rows.unshift([t('device'), t('type'), t('id')]);
    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    return encodeURI(csvContent);
  }, [body, t]);

  const emailBody = csvBody.replace(/data:text\/csv;charset=utf-8,/g, '');

  const items = [
    {
      title: t('orderList'),
      description: t(`consumablesShoppingDialogOrderListDescription${consumableType ? 'FromSelection' : ''}`),
      btnLabel: t('consumablesShoppingDialogOrderListBtnLabel'),
      btnAction: () => {
        const link = document.createElement('a');
        link.setAttribute('href', csvBody);
        link.setAttribute('download', `ConsumablesOrderList_${dayjs().format('YYYY-MM-DD')}.csv`);
        document.body.appendChild(link);
        link.click();
      },
    },
    {
      title: t('email'),
      description: t(`consumablesShoppingDialogEmailDescription${consumableType ? 'FromSelection' : ''}`),
      btnLabel: t('consumablesShoppingDialogEmailBtnLabel'),
      btnAction: () => {
        window.location.href =
          `mailto:${CONFIG.defaultShoppingEmail}` +
          '?subject=Consumables Order List' +
          '&body=' +
          emailBody.replaceAll('%0A00', '%0A').replaceAll('%0A0', '%0A');
      },
    },
    {
      title: t('shopping'),
      description: t(`consumablesShoppingDialogGoToShoptDescription${consumableType ? 'FromSelection' : ''}`),
      btnLabel: t('consumablesShoppingDialogGoToShopBtnLabel'),
      btnDisabled: true,
      btnAction: () => {},
    },
  ];

  const CardButton = ({
    btnLabel,
    btnAction,
    btnDisabled,
  }: {
    btnLabel: string;
    btnAction: Function;
    btnDisabled?: boolean;
  }) => (
    <Button
      size="small"
      variant="outlined"
      onClick={() => btnAction()}
      sx={{ whiteSpace: 'nowrap' }}
      disabled={btnDisabled}
      fullWidth
    >
      {btnLabel}
    </Button>
  );

  const CardBody = ({
    title,
    description,
    btnLabel,
    btnAction,
    btnDisabled,
  }: {
    title: string;
    description: string;
    btnLabel: string;
    btnAction: Function;
    btnDisabled?: boolean;
  }) => (
    <>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions sx={{ mb: 1, px: 2 }}>
        {btnDisabled && (
          <Tooltip title={t('availableSoon')} placement="top" arrow>
            <Box width={'100%'}>{CardButton({ btnLabel, btnAction, btnDisabled })}</Box>
          </Tooltip>
        )}
        {!btnDisabled && CardButton({ btnLabel, btnAction, btnDisabled })}
      </CardActions>
    </>
  );

  const dialogBody = (
    <Box>
      <Box p={1} pb={0}>
        <Trans
          i18nKey={`consumablesShoppingDialogDescription${consumableType ? 'FromSelection' : ''}`}
          values={{ num: devices.length }}
        />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap={isTablet ? 'wrap' : 'nowrap'} p={1} gap={1}>
        {items.map((item) => (
          <Card
            key={item.title}
            sx={{
              flex: isTablet ? '1 1 100%' : '1 1 33%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.background.grayShades[0],
              boxShadow: 'none',
            }}
          >
            <CardBody
              title={item.title}
              description={item.description}
              btnLabel={item.btnLabel}
              btnAction={item.btnAction}
              btnDisabled={item.btnDisabled}
            />
          </Card>
        ))}
      </Box>
    </Box>
  );

  const { dialog } = useCulliganDialog({
    open: !!devices.length,
    handleClose: (_: any, _reason: any) => onClose(),
    tabs: [
      {
        id: 'shoppingDialog',
        label: t(`consumablesShoppingDialogTitle${consumableType ? 'FromSelection' : ''}`),

        body: dialogBody,
      },
    ],
    styles: {
      dialogContentContainer: {
        p: 0,
      },
    },
  });

  return dialog;
};

export default ConsumablesShoppingDialog;
