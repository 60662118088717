import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NavigationTabsLayout from '@/components/Shared/TabsLayout/NavigationTabsLayout';
import { TabConfig } from '@/components/Shared/TabsLayout/typings';
import { ROUTES } from '@/shared/constants';
import { Outlet, useNavigate } from 'react-router';

import { getPath } from '@/shared/utils';
import useCurrentTab from '@/hooks/useCurrentTab';

const AlarmsAndErrorsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tabsConfig = [
    {
      id: ROUTES.FLEET_ALARMS.fragment,
      label: t(ROUTES.FLEET_ALARMS.fragment),
      ariaControls: true,
      useHash: false,
      onNavigate: () => {
        navigate(getPath('FLEET_ALARMS'));
      },
    },
    {
      id: ROUTES.FLEET_ERRORS.fragment,
      label: t('errors'),
      ariaControls: true,
      useHash: false,
      onNavigate: () => {
        navigate(getPath('FLEET_ERRORS'));
      },
    },
  ] satisfies TabConfig[];

  const { index } = useCurrentTab(tabsConfig);

  return (
    <Box>
      {<NavigationTabsLayout config={tabsConfig} defaultIndex={index - 1} />}
      <Outlet />
    </Box>
  );
};

export default AlarmsAndErrorsPage;
